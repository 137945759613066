import classNames from 'classnames';
import { createRef, PureComponent, RefObject } from 'react';
import { List } from 'react-bootstrap/lib/Media';
import { Box, COLORS, Combobox, Icon, IconButton } from 'singlewire-components';
import styled from 'styled-components';
import { LayoutType, SidebarType } from '../../core/layout/LayoutActions';
import { ElementMenu } from '../../core/utils/side-bar';
import { CancelIconButton } from '../shared/components/Button';
import { ForceReloadLinkContainer } from '../shared/ForceReloadLinkContainer';
import { SinglewireIcon } from '../shared/SinglewireIcon';
import { layout_t } from './LayoutLocale';
import { SideBarNavMenuProps } from './SideBarNavMenuContainer';
import { SideBarNavMenuElementContainer } from './SideBarNavMenuElementContainer';

const SideBarNavMenuStyle = styled.aside`
  li > a > .zdmi {
    display: inline-block;
  }
`;

const SearchIcon = styled(Icon.Search)`
  color: ${COLORS.GRAY_80};
  margin-top: 0.3125rem;
  padding: 0.3125rem 0.625rem 0.3125rem 0;
`;

const StyledInput = styled.input`
  display: flex;
  &::placeholder {
    color: ${COLORS.GRAY_60};
  }
`;

const StyledIcon = styled.span`
  padding-left: 0.3125rem;
  padding-right: 0.46875rem;
`;

const StyledLink = styled.a`
  &:active,
  &:hover {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  }
  &:focus-visible {
    outline: 2px solid white;
    box-shadow:
      rgb(255, 255, 255) 0px 0px 0px 2px,
      rgb(0, 90, 138) 0px 0px 0px 4px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  }
`;
StyledLink.displayName = 'StyledLink';

const StyledForceReloadLink = styled(ForceReloadLinkContainer)`
  &:focus-visible {
    outline: 2px solid white;
    box-shadow:
      rgb(255, 255, 255) 0px 0px 0px 2px,
      rgb(0, 90, 138) 0px 0px 0px 4px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  }
`;

export const ImageIcon = ({ src, progress }: { src: string; progress: string }) => (
  <StyledIcon>
    <img id={progress} alt={progress} src={src} width="12px" height="12px" />
  </StyledIcon>
);

export const flattenMenuElements = (menuElements: any[]): any[] => {
  return menuElements.reduce((acc, element) => {
    if (element.children) {
      return [...acc, ...flattenMenuElements(element.children)];
    } else {
      return [...acc, element];
    }
  }, []);
};

export class SideBarNavMenu extends PureComponent<SideBarNavMenuProps> {
  private readonly searchInputRef: RefObject<HTMLInputElement> = createRef();

  state: { providerType: string } = {
    providerType: '',
  };

  searchChanged = ($event: any) => this.props.updateSearch($event.target.value);
  expandElement = (element: ElementMenu) => this.props.expandElement(element);
  toggleLayout = () =>
    this.props.setLayout(
      this.props.layoutType === LayoutType.StretchedLayout
        ? LayoutType.BoxedLayout
        : LayoutType.StretchedLayout
    );

  focusSearchField = () => this.searchInputRef.current && this.searchInputRef.current.focus();

  componentDidMount() {
    this.props.refreshSideBar();

    // as componentDidMount is only called once when the page is initially rendered,
    // use this as a hook to fetch the user's provider type.
    this.props.makeReq(
      ['users', [this.props.signedInUserId], 'GET', { params: { includeIdp: true } }],
      {
        successActions: response => {
          this.setState({ providerType: response.data.providerIdp.type });
          return [];
        },
      }
    );
  }

  componentDidUpdate(prevProps: Readonly<SideBarNavMenuProps>) {
    if (
      prevProps.layoutType === LayoutType.BoxedLayout &&
      this.props.leftSidebarOpened &&
      !prevProps.leftSidebarOpened
    ) {
      this.focusSearchField();
    }
  }

  // We use keypress for this because the a tags with role button don't handle hitting enter key correctly
  handleKeyPress = (element: any) => (event: any) => {
    if (event.key === 'Enter') {
      this.expandElement(element);
    }
  };

  render() {
    const {
      leftSidebarOpened,
      search,
      menuElements,
      layoutType,
      closeSidebar,
      navigateTo,
      useSpotlightSearch,
    } = this.props;

    return (
      <SideBarNavMenuStyle
        id="sidebar"
        className={classNames('sidebar c-overflow', { toggled: leftSidebarOpened })}>
        {useSpotlightSearch ? (
          <Box m="md" mt="lg">
            <Combobox
              showSelectedState={false}
              searchPlaceholder="Search..."
              dialog={true}
              dialogTriggerProps={{
                label: 'Search...',
                key: 'k',
                flex: true,
                variant: 'default',
                startIcon: <Icon.Search size="sm" />,
              }}
              options={flattenMenuElements(menuElements).map((element, index) => ({
                value: element.title + index,
                onSelect: () => navigateTo(element.routerLink),
                label: element.spotlight?.title || element.title,
                subtitle: element.spotlight?.subtitle,
              }))}
            />
          </Box>
        ) : (
          <Box mx="md" my="sm" alignItems="center">
            <label htmlFor="nav-search-bar">
              <SearchIcon size="md" title={layout_t(['sideBar', 'search', 'label'])} />
            </label>
            <StyledInput
              id="nav-search-bar"
              onChange={this.searchChanged}
              maxLength={100}
              type="text"
              className="form-control material-input"
              placeholder={layout_t(['sideBar', 'search', 'placeholder'])}
              ref={this.searchInputRef}
              value={this.props.search}
            />

            <CancelIconButton
              size="sm"
              id="delete-serach-field"
              label={layout_t(['sideBar', 'search', 'clear'])}
              onClick={() => {
                this.searchChanged({ target: { value: '' } });
              }}
              disableRadixTooltip
            />
          </Box>
        )}

        <ul className="main-menu m-t-0">
          {menuElements.length === 0 && search && (
            <div className="main-menu-li">
              {/* eslint-disable jsx-a11y/anchor-is-valid */}
              <a>{layout_t(['sideBar', 'search', 'noResults'])}</a>
            </div>
          )}
          {menuElements.map(element =>
            element.children ? (
              <li
                key={element.id}
                className={classNames('main-menu-li sub-menu', { toggled: element.isExpanded })}>
                {/* eslint-disable jsx-a11y/anchor-is-valid */}
                <StyledLink
                  role="button"
                  id={element.id}
                  onClick={() => this.expandElement(element)}
                  tabIndex={0}
                  aria-expanded={element.isExpanded}
                  onKeyPress={this.handleKeyPress(element)}>
                  <SinglewireIcon icon={element.icon} /> {element.title}
                  <div className="pull-right">
                    {!element.isExpanded ? (
                      <Icon.Add size="sm" color="inherit" />
                    ) : (
                      <Icon.Remove size="sm" color="inherit" />
                    )}
                  </div>
                </StyledLink>
                {element.isExpanded && (
                  <List>
                    {element.children.map((child: any) => (
                      <SideBarNavMenuElementContainer
                        key={child.id || child.routerLink}
                        element={child}
                      />
                    ))}
                  </List>
                )}
              </li>
            ) : (
              <li
                key={element.routerLink}
                className={classNames('main-menu-li', { active: element.isActive })}>
                <StyledForceReloadLink
                  to={element.routerLink}
                  onClick={() => closeSidebar(SidebarType.Left)}
                  tabIndex={0}>
                  {element.icon && <SinglewireIcon icon={element.icon} />}
                  {element.imageSrc && (
                    <ImageIcon src={element.imageSrc} progress={element.title} />
                  )}{' '}
                  {element.title}
                </StyledForceReloadLink>
              </li>
            )
          )}
        </ul>
        <Box align="center">
          <IconButton
            id="toggle-width"
            label={layoutType === LayoutType.StretchedLayout ? 'Unlock Sidebar' : 'Lock Sidebar'}
            onClick={this.toggleLayout}
            icon={
              layoutType === LayoutType.StretchedLayout ? <Icon.LockClosed /> : <Icon.LockOpen />
            }
          />
        </Box>
      </SideBarNavMenuStyle>
    );
  }
}
