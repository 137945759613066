import classNames from 'classnames';
import moment from 'moment';
import { interpolate } from 'nucleus-js';
import { PureComponent } from 'react';
import {
  Box,
  COLORS,
  Dropdown,
  DropdownMenuItem,
  Icon,
  IconButton,
  Typography,
} from 'singlewire-components';
import styled from 'styled-components';
import { common_t } from '../../CommonLocale';
import { ApiRequest } from '../../common-types';
import { RightSidebarType } from '../../core/layout/LayoutActions';
import { ModalState } from '../../core/modal/ModalActions';
import Routes from '../../core/route/Routes';
import { getNotificationState } from '../../core/utils/notification-status';
import { MobileApiNotification } from '../../mobile-api-types';
import { CustomRegistryModalContainer } from '../shared/CustomRegistryModalContainer';
import { ForceReloadLinkContainer } from '../shared/ForceReloadLinkContainer';
import { Link } from '../shared/components/Link';
import { CancelOrExpireFormContainer, FormState } from './CancelOrExpireFormContainer';
import { layout_t } from './LayoutLocale';
import { QuickCancelProps } from './QuickCancelContainer';

export const PAGER_ID = 'list-notifications-quick-cancel';
export const SIDEBAR_EXPIRE_MODAL_ID = 'sidebar-expire-modal';
export const SIDEBAR_CANCEL_MODAL_ID = 'sidebar-cancel-modal';
export const CREATE_NOTIFICATIONS: ApiRequest = ['notifications', [], 'POST'];

interface ModalStateProps {
  notification: MobileApiNotification;
}

export interface QuickCancelListItemProps {
  notification: MobileApiNotification;
  showModal: (state: ModalState) => void;
  isActive: boolean;
  canCreateNotifications: boolean;
}

const Header = styled.div`
  height: 2.5rem;
  padding: 0 0.5rem;
`;

const QuickCancelClose = styled(IconButton)`
  margin-right: -0.5rem !important;
  margin-top: -0.5rem !important;
`;

const Subtitle = styled.div`
  color: ${COLORS.GRAY_80};
  font-size: 0.875rem;
  height: 2.5rem;
  background-color: ${COLORS.GRAY_5};
  padding: 0.5rem;
`;

const Notification = styled(Box)`
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  font-size: 0.875rem;
  height: 3rem;
  padding: 0rem 0.5rem;
  border-bottom: 1px solid ${COLORS.GRAY_10};
  color: ${COLORS.BLACK};
`;

const Time = styled.span`
  font-size: 0.75rem;
  color: ${COLORS.GRAY_60};
`;

const Footer = styled.div`
  font-size: 0.875rem;
  padding: 0.5rem;
  text-align: center;
`;

const Title = styled.span`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const QuickCancelListItem = ({
  notification,
  showModal,
  isActive,
  canCreateNotifications,
}: QuickCancelListItemProps) => {
  const createdAt = new Date(notification.createdAt);
  const subject = interpolate(notification.subject, createdAt) || undefined;

  const notificationState = getNotificationState(notification);

  return (
    <Notification align="between" alignItems="center">
      <Title>{subject}</Title>
      <Box ml="md" alignItems="center" spacing={1}>
        <Time title={moment(notification.createdAt).format('h:mm A, M/DD/YY')}>
          {isActive && moment(moment.now()).diff(notification.createdAt, 'days') < 1
            ? moment(notification.createdAt).format('h:mm A')
            : moment(notification.createdAt).format('M/DD/YY')}
        </Time>
        <Dropdown
          id={`${notification.id}-quick-cancel-more-options`}
          trigger={() => (
            <IconButton
              id={`${notification.id}-quick-cancel-more-options-button`}
              label={layout_t(['header', 'quickCancel'])}
              icon={<Icon.More color="black" />}
            />
          )}>
          <ForceReloadLinkContainer to={Routes.Notifications.Details(notification.id)}>
            <DropdownMenuItem
              variant="action"
              icon={<Icon.View />}
              label={common_t(['button', 'view'])}
              key={`view-${notification.id}-link`}
              id={`view-${notification.id}-link`}
            />
          </ForceReloadLinkContainer>
          {canCreateNotifications && notificationState === 'COMPLETE' && (
            <DropdownMenuItem
              variant="action"
              key={`expire-${notification.id}-link`}
              id={`expire-${notification.id}-link`}
              onClick={() => {
                showModal({
                  id: SIDEBAR_EXPIRE_MODAL_ID,
                  componentProps: { notification: notification },
                });
              }}
              icon={<Icon.Cancel />}
              label={layout_t(['quickCancel', 'expire'])}
            />
          )}
          {canCreateNotifications &&
            (notificationState === 'INITIALIZING' ||
              notificationState === 'IN_PROGRESS' ||
              notificationState === 'EXPIRED') && (
              <DropdownMenuItem
                variant="action"
                key={`cancel-${notification.id}-link`}
                id={`cancel-${notification.id}-link`}
                onClick={() => {
                  showModal({
                    id: SIDEBAR_CANCEL_MODAL_ID,
                    componentProps: { notification: notification },
                  });
                }}
                icon={<Icon.Cancel />}
                label={common_t(['button', 'cancel'])}
              />
            )}
        </Dropdown>
      </Box>
    </Notification>
  );
};

export class QuickCancel extends PureComponent<QuickCancelProps> {
  componentDidUpdate(prevProps: Readonly<QuickCancelProps>) {
    if (
      this.props.rightSidebarOpened &&
      this.props.rightSidebarType === RightSidebarType.CANCEL &&
      prevProps.rightSidebarType !== RightSidebarType.CANCEL
    ) {
      this.props.searchPagerRefresh(PAGER_ID);
    }
  }

  componentDidMount() {
    this.props.checkPermission(CREATE_NOTIFICATIONS);
    this.props.startActiveNotificationsPolling();
  }

  onClose = () => {
    this.props.toggleRightSidebarType(RightSidebarType.NONE);
    this.props.stopNotificationsPolling();
    setTimeout(() => {
      document.getElementById('quick-cancel-trigger')?.focus();
    }, 0);
  };

  render() {
    const {
      rightSidebarOpened,
      rightSidebarType,
      toggleRightSidebarType,
      cancelOrExpireNotification,
      showModal,
      canCreateNotifications,
      notifications,
    } = this.props;

    const notificationsWithStatus =
      notifications &&
      notifications.map(notification => ({
        ...notification,
        status: getNotificationState(notification),
      }));
    const activeNotifications =
      notificationsWithStatus &&
      notificationsWithStatus.filter(
        notification =>
          notification.status === 'IN_PROGRESS' || notification.status === 'INITIALIZING'
      );
    const pastNotifications =
      notificationsWithStatus &&
      notificationsWithStatus.filter(
        notification =>
          notification.status !== 'IN_PROGRESS' && notification.status !== 'INITIALIZING'
      );

    return (
      <>
        {rightSidebarType === RightSidebarType.CANCEL && (
          <aside
            id="quick-cancel"
            className={classNames('sidebar c-overflow', { toggled: rightSidebarOpened })}>
            <div className="listview" id="quickCancelItemList">
              <Header id="quick-cancel-header">
                <Box align="between">
                  <Typography variant="h3">{common_t(['resource', 'notifications'])}</Typography>
                  <QuickCancelClose
                    id="close-quick-cancel"
                    label={layout_t(['quickCancel', 'closeNotifications'])}
                    color="gray"
                    icon={<Icon.Cancel />}
                    onClick={this.onClose}
                  />
                </Box>
              </Header>
              {activeNotifications && activeNotifications.length > 0 && (
                <>
                  <Subtitle id="quick-cancel-subtitle">
                    {layout_t(['quickCancel', 'activeNotifications'])}
                  </Subtitle>
                  {activeNotifications.map(notification => (
                    <QuickCancelListItem
                      key={notification.id}
                      notification={notification}
                      showModal={showModal}
                      isActive={true}
                      canCreateNotifications={canCreateNotifications}
                    />
                  ))}
                </>
              )}
              {pastNotifications && pastNotifications.length > 0 && (
                <>
                  <Subtitle id="quick-cancel-subtitle">
                    {layout_t(['quickCancel', 'pastNotifications'])}
                  </Subtitle>
                  {pastNotifications.map(notification => (
                    <QuickCancelListItem
                      key={notification.id}
                      notification={notification}
                      showModal={showModal}
                      isActive={false}
                      canCreateNotifications={canCreateNotifications}
                    />
                  ))}
                </>
              )}
              <Footer onClick={() => toggleRightSidebarType(RightSidebarType.NONE)}>
                <Link to={Routes.Notifications.List}>{layout_t(['quickCancel', 'viewAll'])}</Link>
              </Footer>
            </div>
          </aside>
        )}

        <CustomRegistryModalContainer
          id={SIDEBAR_EXPIRE_MODAL_ID}
          title={layout_t(['cancelOrExpire', 'expire', 'title'])}
          hidePrimaryButton
          hideSecondaryButton
          render={({ notification }: ModalStateProps) => (
            <CancelOrExpireFormContainer
              type="expire"
              onSubmit={(formId: string, formData: FormState) =>
                cancelOrExpireNotification(formId, formData, notification, 'expire')
              }
            />
          )}
        />

        <CustomRegistryModalContainer
          id={SIDEBAR_CANCEL_MODAL_ID}
          title={layout_t(['cancelOrExpire', 'abort', 'title'])}
          hidePrimaryButton
          hideSecondaryButton
          render={({ notification }: ModalStateProps) => (
            <CancelOrExpireFormContainer
              type="abort"
              onSubmit={(formId: string, formData: FormState) =>
                cancelOrExpireNotification(formId, formData, notification, 'abort')
              }
            />
          )}
        />
      </>
    );
  }
}
