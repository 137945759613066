import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  HeaderGroup,
  PaginationState,
  Row,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table';
import { AxiosError, AxiosResponse } from 'axios';
import isArray from 'lodash/isArray';
import moment from 'moment';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { initialize } from 'redux-form';
import {
  Box,
  COLORS,
  Combobox,
  Dropdown,
  DropdownMenuCheckboxItem,
  DropdownMenuItem,
  EmptyState,
  Hidden,
  Icon,
  IconButton,
  Input,
  Loading,
  TableBase,
  TableCell,
  TableHeader,
  TableRow,
  TriggerButton,
  Typography,
  useBreakpoints,
} from 'singlewire-components';
import styled from 'styled-components';
import { common_t } from '../../../CommonLocale';
import { ApiRequest } from '../../../common-types';
import { loadPagerLimit, savePagerLimit } from '../../../core/search-pager/SearchPagerLocalStorage';
import { checkPagerLimits } from '../../../core/search-pager/SearchPagerReducer';
import { MobileApiPageEnvelope, MobileApiValidationErrorResponse } from '../../../mobile-api-types';
import { Alert } from '../../shared/components/Alert';
import { Button, CancelButton } from '../../shared/components/Button';
import { shared_t } from '../SharedLocale';
import { useLiveRegion } from '../hooks/useLiveRegion';
import { useSession } from '../hooks/useSession';
import { DateFilterForm } from './TablePagerDateFilter';
import {
  FORM_ID as LOCATION_FORM_ID,
  LocationFilterResolveWrapper,
} from './TablePagerLocationFilter';
import {
  FilterOption,
  updateLocalStorageColumns,
  updateLocalStorageFilters,
  updateLocalStorageSearch,
  UseDateFilterOptions,
  usePagerColumns,
  usePagerFilters,
  usePagerSearch,
} from './table/utils';
import { TablePagerQueryOptions, useTableDataQuery } from './table/Hooks';

const ClearButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: 0.5rem;
`;

const TextContainer = styled.div`
  display: flex;
  margin-top: 6px;
`;

const Overflowcontainer = styled.div`
  overflow-x: auto;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
  background-color: ${COLORS.GRAY_5};
  overflow-x: auto;
  flex-shrink: 0;

  & > * {
    flex-shrink: 0;
  }
`;

const FilterButtonContainer = styled(Box)`
  flex: 2 1 auto;
`;

const SearchContainer = styled(Box)`
  flex: 1 1 auto;
`;

interface Filter {
  label: string;
  attribute: string;
  options: FilterOption[];
  /** Allow multiple values to be selected for this filter */
  multiselect?: boolean;
}

interface TableState<T> {
  isSelectingAll: boolean;
  selectedRows: string[];
  setSelectedRows?: (rows: Record<string, any>) => void;
  filters?: Record<string, Array<FilterOption> | FilterOption | undefined>;
  q?: Record<string, any> | string;
  data?: T[];
}

interface TablePagerProps<T> {
  pagerId: string;
  request: ApiRequest;
  columns?: (state: TableState<T>) => ColumnDef<T, any>[];
  actions?: (state: TableState<T>) => React.ReactNode;
  bulkActions?: (state: TableState<T>) => React.ReactNode;
  tableConfigOverrides?: Partial<TableOptions<T>>;
  requestFilters?: Filter[];
  children?: (state: TableState<T>) => React.ReactNode;
  emptyStateProps: { id: string; title: string; icon?: React.ReactNode; subtitle?: string };
  customLimits?: [number, number, number] | null;
  /** Pass options to enable the date filter */
  useDateFilterOptions?: UseDateFilterOptions;
  defaultFilterOptions?: Record<string, Array<FilterOption> | FilterOption | undefined>;
  /** Pass options to enable the location filter */
  useLocationFilterOptions?: {
    allowNoLocation: boolean;
    label?: string;
    depth?: 'site' | 'building' | 'floor' | 'zone';
  };
  /**
   * An object of column id to boolean that describes the initial state of column visibility
   */
  defaultColumnVisibility?: Record<string, boolean>;
  /**
   * Show filter buttons when table renders
   * @default true
   * */
  defaultShowFilters?: boolean;
  searchPlaceholder?: string;
  includeSearchBar?: boolean;
  /**
   * When set to false, this hides the pagination buttons at the bottom of the table
   */
  includePagination?: boolean;
  /**
   * Accessible caption for the table component.
   * This should describe the content of the table.
   */
  caption: string;

  /**
   * Disables radix tooltips on pagination buttons. This was causing issues with focus states not
   * working in more complex components like modals and sheets.
   */
  disableRadixTooltips?: boolean;
  /**
   * Hides the select all matching resources button from the blue banner.
   */
  disableSelectAll?: boolean;
  /**
   * When true, this component is in a loading state.
   */
  isLoading?: boolean;
  /**
   * Element to show when this component is in a loading state.
   */
  customLoadingElement?: ReactElement;
  /**
   * Additional alerts to display in the alert section of TablePager
   */
  customAlertSectionElement?: ReactElement;
  isRowSelected?: (row: T) => boolean;
  queryOptions?: TablePagerQueryOptions<T>;
  paginationType?: 'normal' | 'compact';
}

const pagerLevel = (userId: string) => loadPagerLimit(userId);

const TableInner = <T = unknown,>({
  pagerId,
  caption,
  headerGroups,
  rows,
  isRowSelected,
}: {
  pagerId: string;
  caption: string;
  headerGroups: HeaderGroup<T>[];
  rows: Row<T>[];
  isRowSelected?: (row: T) => boolean;
}) => {
  return (
    <TableBase id={`table-${pagerId}`} caption={caption}>
      <thead>
        {headerGroups.map(headerGroup => (
          <TableRow key={headerGroup.id} hover={false}>
            {headerGroup.headers.map(header => (
              <TableHeader key={header.id}>
                {flexRender(header.column.columnDef.header, header.getContext())}
              </TableHeader>
            ))}
          </TableRow>
        ))}
      </thead>
      <tbody>
        {rows.map(row => (
          <TableRow key={row.id} selected={row.getIsSelected() || isRowSelected?.(row.original)}>
            {row.getVisibleCells().map(cell => (
              <TableCell key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </tbody>
    </TableBase>
  );
};

const Table = React.memo(TableInner) as typeof TableInner;

export const TablePager = <T = any,>({
  pagerId,
  request,
  columns,
  requestFilters,
  emptyStateProps,
  children,
  actions,
  bulkActions,
  customLimits = null,
  useLocationFilterOptions,
  useDateFilterOptions,
  defaultFilterOptions,
  defaultColumnVisibility = {},
  searchPlaceholder,
  tableConfigOverrides,
  includeSearchBar = true,
  includePagination = true,
  defaultShowFilters = true,
  caption,
  disableRadixTooltips,
  disableSelectAll,
  customLoadingElement,
  isLoading,
  customAlertSectionElement,
  isRowSelected,
  responseDecorator,
  queryOptions = { enabled: true },
  paginationType = 'normal',
}: TablePagerProps<T> & {
  responseDecorator?: (
    response: AxiosResponse<MobileApiPageEnvelope<T>, any>
  ) => Promise<AxiosResponse<MobileApiPageEnvelope<T>, any>>;
}) => {
  const breakpoint = useBreakpoints();
  const dispatch = useDispatch();
  const session = useSession();

  const { updateLiveRegion: updateFilteredLiveRegion, Component: FilteredLiveRegion } =
    useLiveRegion();

  const [isSelectingAll, setIsSelectingAll] = useState(false);
  const [rowSelection, setRowSelection] = React.useState({});

  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: checkPagerLimits(10, pagerLevel(session?.userId!), customLimits),
  });

  const { debouncedColumnVisibility, setColumnVisibility } = usePagerColumns({
    request,
    pagerId,
    defaultColumnVisibility,
    userId: session?.userId!,
  });

  const { debouncedSearch, setSearch, initialSearchValue, search } = usePagerSearch({
    request,
    pagerId,
    userId: session?.userId!,
    updateFilteredLiveRegion,
  });

  const [filtersOpen, setFiltersOpen] = useState(defaultShowFilters);
  const {
    debouncedFilters,
    setFilters,
    initialFiltersValue,
    dateFormValues,
    setDateFormValues,
    initialLocation,
    setInitialLocation,
  } = usePagerFilters({
    request,
    pagerId,
    useDateFilterOptions,
    defaultFilterOptions,
    userId: session?.userId!,
  });

  // Had an issue between the speakers and idns pages because they use the same request and component.
  // The row selection would persist between the two pages because we share IPSpeakerList
  // for the two features. I'm doing this to make sure the selection clears when we use a
  // different request. This _should_ be future proof as the requests we pass into this component
  // more or less stay static. If that changes in the future we'll want to queue off of something
  // different for this clearing behavior.
  useEffect(() => {
    setRowSelection({});

    // These two are here to reset these to their initial values, this fixes a race condition if users
    // go from the IP Speakers list page directly to the IDNs list page. Because these features share the
    // same table component the local state wouldn't update correctly without some sort of reset.
    setFilters(initialFiltersValue);
    setSearch(initialSearchValue);
  }, [request, initialFiltersValue, initialSearchValue, setFilters, setSearch]);

  const qValue = React.useMemo(() => {
    const [, , , options] = request;
    const baseQuery = options?.params?.q;

    return baseQuery ? { and: [baseQuery, debouncedSearch] } : debouncedSearch;
  }, [request, debouncedSearch]);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const dataQuery = useTableDataQuery<T>(
    pagerId,
    request,
    pagination,
    debouncedSearch,
    debouncedFilters,
    queryOptions,
    responseDecorator
  );

  const rows = useMemo(() => dataQuery.data?.rows || [], [dataQuery.data?.rows]);
  const reactTableColumns = useMemo(
    () => columns?.({ isSelectingAll, selectedRows: Object.keys(rowSelection) }) || [],
    [columns, rowSelection, isSelectingAll]
  );

  const table = useReactTable<T>({
    data: rows,
    columns: reactTableColumns,
    pageCount: dataQuery.data?.pageCount ?? -1,
    state: {
      columnVisibility: debouncedColumnVisibility,
      rowSelection,
      pagination,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onPaginationChange: setPagination,
    manualPagination: true,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    ...tableConfigOverrides,
  });

  const onChangeSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value),
    [setSearch]
  );

  useEffect(() => {
    // Reset pagination values and selected columns if search changes
    setPagination({ pageSize, pageIndex: 0 });
    setIsSelectingAll(false);
    setRowSelection({});

    // We may want to clear the key out of local storage if search is empty
    updateLocalStorageSearch(pagerId, session?.userId!, debouncedSearch);

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [debouncedSearch, setPagination, session?.userId]);

  useEffect(() => {
    // Reset pagination values and selected columns if filters change
    setPagination({ pageSize, pageIndex: 0 });
    setIsSelectingAll(false);
    setRowSelection({});

    // We may want to clear the key out of local storage if there are no filters
    updateLocalStorageFilters(pagerId, session?.userId!, debouncedFilters);

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [debouncedFilters, setPagination, session?.userId]);

  useEffect(() => {
    // We may want to clear the key out of local storage if there are no filters
    updateLocalStorageColumns(pagerId, session?.userId!, debouncedColumnVisibility);

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [debouncedColumnVisibility, session?.userId]);

  const onChangeFilter = useCallback(
    (key: string, obj?: FilterOption, multiselect?: boolean) => () => {
      if (multiselect) {
        const filterValue = (debouncedFilters?.[key] as FilterOption[]) || [];

        const found = filterValue.find(fv => fv.value === obj?.value);
        const removed = filterValue.filter(fv => fv.value !== obj?.value);

        if (found) {
          // Clicked the same button in the filter to remove
          if (removed.length === 0) {
            setFilters({ ...debouncedFilters, [key]: undefined });
            updateFilteredLiveRegion(shared_t(['table', 'filterRemoved']));
          } else {
            setFilters({ ...debouncedFilters, [key]: removed });
            updateFilteredLiveRegion(shared_t(['table', 'filterRemoved']));
          }
        } else if (!obj) {
          // Passed undefined to remove the filter (clear button)
          setFilters({ ...debouncedFilters, [key]: undefined });
          updateFilteredLiveRegion(shared_t(['table', 'filterRemoved']));
        } else {
          // Adding the filter value
          setFilters({ ...debouncedFilters, [key]: [...filterValue, obj] });
          updateFilteredLiveRegion(shared_t(['table', 'filterAdded']));
        }
      } else {
        if (debouncedFilters[key] === obj) {
          // Clicked the same button in the filter to remove
          setFilters({ ...debouncedFilters, [key]: undefined });
          updateFilteredLiveRegion(shared_t(['table', 'filterRemoved']));
        } else if (!obj) {
          // Passed undefined to remove the filter (clear button)
          setFilters({ ...debouncedFilters, [key]: undefined });
          updateFilteredLiveRegion(shared_t(['table', 'filterRemoved']));
        } else {
          // Adding the filter value
          setFilters({ ...debouncedFilters, [key]: obj });
          updateFilteredLiveRegion(shared_t(['table', 'filterAdded']));
        }
      }
    },
    [debouncedFilters, setFilters, updateFilteredLiveRegion]
  );

  const onClear = useCallback(() => {
    setSearch('');
    setFilters({});
    if (!!useLocationFilterOptions) {
      setInitialLocation(undefined);
      dispatch(initialize(LOCATION_FORM_ID, {}));
    }
    if (!!useDateFilterOptions) {
      setDateFormValues({ start: undefined, end: undefined });
    }
  }, [
    setFilters,
    setSearch,
    setInitialLocation,
    dispatch,
    useLocationFilterOptions,
    useDateFilterOptions,
    setDateFormValues,
  ]);

  const onChangeLocationFilter = useCallback(
    (key: string, value: string | null | undefined, formState: any) => {
      const clearedLocationFilters = {
        siteId: undefined,
        buildingId: undefined,
        floorId: undefined,
        zoneId: undefined,
      };

      const { siteId, buildingId, floorId, zoneId } = formState || {};

      if (value || value === null) {
        setFilters({
          ...debouncedFilters,
          ...clearedLocationFilters,
          siteId: siteId ? ({ label: undefined, value: siteId } as any) : undefined,
          buildingId: buildingId ? ({ label: undefined, value: buildingId } as any) : undefined,
          floorId: floorId ? ({ label: undefined, value: floorId } as any) : undefined,
          zoneId: zoneId ? ({ label: undefined, value: zoneId } as any) : undefined,
          [key]: { label: undefined, value },
        });
      } else {
        setFilters({ ...debouncedFilters, ...clearedLocationFilters });
      }
    },
    [debouncedFilters, setFilters]
  );

  const onChangeDateFilter = useCallback(
    (start?: Date, end?: Date) => {
      const { attribute, range } = useDateFilterOptions || { attribute: 'created-at' };
      const clearedRangeDateFilters = {
        [`${attribute}__gt`]: undefined,
        [`${attribute}__lte`]: undefined,
      };

      const clearedDateFilter = {
        [attribute]: undefined,
      };

      if (range) {
        if (start) {
          const startMomentBeginning = moment(start.toISOString()).startOf('day');
          const startMomentEnding = moment(start.toISOString()).endOf('day');
          const endMoment = moment(end?.toISOString()).endOf('day');

          setFilters({
            ...debouncedFilters,
            ...clearedRangeDateFilters,
            [`${attribute}__gt` as any]: {
              value: startMomentBeginning.toISOString(),
              label: undefined,
            },
            [`${attribute}__lte` as any]: {
              value: !!end ? endMoment.toISOString() : startMomentEnding.toISOString(),
              label: undefined,
            },
          });
          setDateFormValues({
            start: startMomentBeginning.toDate(),
            end: !!end ? endMoment.toDate() : startMomentEnding.toDate(),
          });
        } else {
          setFilters({ ...debouncedFilters, ...clearedRangeDateFilters });
          setDateFormValues({ start: undefined, end: undefined });
        }
      } else {
        if (start) {
          const startMoment = moment(start.toISOString()).startOf('day');

          setFilters({
            ...debouncedFilters,
            ...clearedDateFilter,
            [attribute as any]: {
              value: startMoment.toISOString(),
              label: undefined,
            },
          });
          setDateFormValues({ start });
        } else {
          setFilters({ ...debouncedFilters, ...clearedDateFilter });
          setDateFormValues({ start: undefined, end: undefined });
        }
      }
    },
    [debouncedFilters, setFilters, useDateFilterOptions, setDateFormValues]
  );

  const onChangePagerLimit = useCallback(
    (limit: number) => () => {
      table.setPageSize(limit);
      savePagerLimit(limit, session?.userId!, customLimits);
    },
    [table, session?.userId, customLimits]
  );

  const internalIsLoading =
    React.useMemo(
      () => dataQuery.isLoading || dataQuery.isFetching || dataQuery.isRefetching,
      [dataQuery.isLoading, dataQuery.isFetching, dataQuery.isRefetching]
    ) || isLoading;
  const hasError = React.useMemo(
    () => dataQuery.error && !internalIsLoading,
    [dataQuery.error, internalIsLoading]
  );
  // Important for this one to use debounce so the empty state doesn't flash
  const hasSearchOrFilter = React.useMemo(
    () => !!debouncedSearch || Object.values(debouncedFilters).filter(f => !!f).length > 0,
    [debouncedSearch, debouncedFilters]
  );
  const isEmptyAfterSearch = React.useMemo(
    () => rows.length === 0 && hasSearchOrFilter && !internalIsLoading,
    [rows, hasSearchOrFilter, internalIsLoading]
  );
  const isEmptyWithoutSearch = React.useMemo(
    () => rows.length === 0 && !hasSearchOrFilter && !internalIsLoading,
    [rows, hasSearchOrFilter, internalIsLoading]
  );

  const hidableColumns = table
    .getAllColumns()
    .filter(column => typeof column.accessorFn !== 'undefined' && column.getCanHide());

  const getFilterLabel = useCallback(
    (reqFilter: Filter) => {
      const filterValue = debouncedFilters?.[reqFilter.attribute];

      if (isArray(filterValue)) {
        const labels = filterValue.map(v => v.label);
        if (labels.length === 0) {
          return undefined;
        } else {
          return labels;
        }
      } else {
        return filterValue?.label;
      }
    },
    [debouncedFilters]
  );

  const isFilterSelected = useCallback(
    (reqFilter: Filter, option: FilterOption) => {
      const filterValue = debouncedFilters?.[reqFilter.attribute];

      if (isArray(filterValue)) {
        return !!filterValue.find(v => v.value === option.value && v.operator === option.operator);
      } else {
        return filterValue?.value === option.value && filterValue.operator === option.operator;
      }
    },
    [debouncedFilters]
  );

  const refetch = useCallback(() => {
    dataQuery.refetch();
  }, [dataQuery]);

  const secondClassFilters = React.useMemo(() => {
    const { attribute } = useDateFilterOptions || { attribute: 'created-at' };

    const {
      siteId,
      buildingId,
      floorId,
      zoneId,
      [attribute]: dateFilterAttribute,
      [`${attribute}__gt`]: dateFilterAttributeGT,
      [`${attribute}__lte`]: dateFilterAttributeLTE,
      ...filters
    } = debouncedFilters;

    const valueCount = Object.values(filters).filter(v => !!v).length;

    if (valueCount > 0) {
      return {
        valueCount,
        filters,
        hasValues: valueCount && valueCount > 0,
      };
    } else {
      return { valueCount: undefined, filters, hasValues: false };
    }
  }, [debouncedFilters, useDateFilterOptions]);

  // if there is a refetch interval, do not show loading component
  // to avoid a remount of the table after each refetch request
  const showLoadingIndicator = useMemo(
    () => internalIsLoading && !queryOptions.refetchInterval,
    [internalIsLoading, queryOptions.refetchInterval]
  );

  return (
    <>
      <Box
        direction={
          paginationType === 'compact' || ['xs', 'sm'].includes(breakpoint) ? 'column' : 'row'
        }
        spacing={2}
        mb="md">
        {includeSearchBar && (
          <SearchContainer>
            <Input
              id="search-field"
              aria-label={shared_t(['label', 'search'])}
              placeholder={searchPlaceholder || shared_t(['label', 'search'])}
              value={search}
              onChange={onChangeSearch}
              startIcon={<Icon.Search size="sm" />}
            />
            {paginationType === 'compact' && !!actions && (
              <Box>
                {actions({ isSelectingAll, selectedRows: Object.keys(rowSelection), data: rows })}
              </Box>
            )}
          </SearchContainer>
        )}
        <FilterButtonContainer align="between">
          <Box direction="row">
            {(requestFilters || []).length > 0 && (
              <TriggerButton
                id={pagerId + '-filter-button'}
                label="Filters"
                startIcon={<Icon.Filter size="sm" />}
                value={
                  secondClassFilters.hasValues ? `${secondClassFilters.valueCount}` : undefined
                }
                disabled={isSelectingAll}
                active={filtersOpen}
                variant="default"
                onClick={() => setFiltersOpen(open => !open)}
                dynamicSizing
                aria-expanded={filtersOpen}
              />
            )}

            <Hidden lgDown>
              {(secondClassFilters.hasValues || !!debouncedSearch) && (
                <Button
                  id="clear-button"
                  startIcon={<Icon.Cancel />}
                  label={common_t(['button', 'clear'])}
                  onClick={onClear}
                />
              )}
            </Hidden>
          </Box>

          <Box spacing={2}>
            {!!useLocationFilterOptions && (
              <LocationFilterResolveWrapper
                isSelectingAll={isSelectingAll}
                onChangeLocationFilter={onChangeLocationFilter}
                initialLocation={initialLocation}
                options={useLocationFilterOptions}
              />
            )}

            {!!useDateFilterOptions && (
              <DateFilterForm
                isSelectingAll={isSelectingAll}
                onChangeDateFilter={onChangeDateFilter}
                options={useDateFilterOptions}
                formValues={dateFormValues}
              />
            )}
            {paginationType !== 'compact' && actions && (
              <Box>
                {actions({ isSelectingAll, selectedRows: Object.keys(rowSelection), data: rows })}
              </Box>
            )}
            {hidableColumns.length > 0 && (
              <Dropdown
                id="select-size"
                trigger={() => (
                  <TriggerButton
                    id="select-columns-button"
                    size="sm"
                    label={common_t(['label', 'columns'])}
                    variant="default"
                    color="black"
                    startIcon={<Icon.TableColumns size="sm" />}
                    dynamicSizing
                  />
                )}>
                {hidableColumns.map(column => {
                  return (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      checked={column.getIsVisible()}
                      onCheckedChange={value => column.toggleVisibility(!!value)}>
                      {column.id}
                    </DropdownMenuCheckboxItem>
                  );
                })}
              </Dropdown>
            )}
          </Box>
        </FilterButtonContainer>
      </Box>

      {filtersOpen && (requestFilters || []).length > 0 && (
        <FilterContainer>
          {requestFilters?.map(reqFilter => (
            <Box key={reqFilter.label}>
              <Combobox
                trigger={() => (
                  <TriggerButton
                    id={reqFilter.attribute + '-filter-button'}
                    label={reqFilter.label}
                    value={getFilterLabel(reqFilter)}
                    startIcon={<Icon.Filter size="sm" />}
                    disabled={isSelectingAll}
                    variant="default"
                  />
                )}
                options={reqFilter.options.map(option => ({
                  ...option,
                  onSelect: onChangeFilter(reqFilter.attribute, option, reqFilter.multiselect),
                  isSelected: isFilterSelected(reqFilter, option),
                }))}
                showSelectedState={!!reqFilter.multiselect}
                onClearSelection={
                  !!debouncedFilters?.[reqFilter.attribute]
                    ? onChangeFilter(reqFilter.attribute, undefined)
                    : undefined
                }
              />
            </Box>
          ))}
        </FilterContainer>
      )}

      {Object.keys(rowSelection).length > 0 && !isSelectingAll && (
        <Box mb="md">
          <Alert slim title={shared_t(['table', 'selection'])}>
            <Box>
              <TextContainer>
                <Typography variant="body">
                  {shared_t(['table', 'allSelectedOnPage'], {
                    count: Object.keys(rowSelection).length,
                  })}
                </Typography>
              </TextContainer>

              {!disableSelectAll && (
                <Button
                  id="select-all-button"
                  label={shared_t(['table', 'selectAll'], { count: dataQuery.data?.total })}
                  onClick={() => {
                    setIsSelectingAll(true);
                  }}
                />
              )}
              <ClearButtonContainer>
                {!!bulkActions && (
                  <Box>
                    {bulkActions({ isSelectingAll, selectedRows: Object.keys(rowSelection) })}
                  </Box>
                )}
                <CancelButton
                  id="clear-all-selection-button"
                  variant="outlined"
                  label={shared_t(['table', 'clearSelection'])}
                  onClick={() => {
                    setIsSelectingAll(false);
                    setRowSelection({});
                  }}
                />
              </ClearButtonContainer>
            </Box>
          </Alert>
        </Box>
      )}

      <div aria-live="assertive">
        {isSelectingAll && (
          <Box mb="md">
            <Alert slim title={shared_t(['table', 'selection'])}>
              <Box>
                <TextContainer>
                  <Typography variant="body">
                    {shared_t(['table', 'allSelected'], { count: dataQuery.data?.total })}
                  </Typography>
                </TextContainer>

                <ClearButtonContainer>
                  {!!bulkActions && (
                    <Box>
                      {bulkActions({ isSelectingAll, selectedRows: Object.keys(rowSelection) })}
                    </Box>
                  )}
                  <CancelButton
                    id="clear-all-selection-button"
                    variant="outlined"
                    label={shared_t(['table', 'clearSelection'])}
                    onClick={() => {
                      setIsSelectingAll(false);
                      setRowSelection({});
                    }}
                  />
                </ClearButtonContainer>
              </Box>
            </Alert>
          </Box>
        )}

        {isEmptyAfterSearch ? <Alert slim>{shared_t(['table', 'noResults'])}</Alert> : null}

        {hasError ? (
          <Alert slim color="error">
            <Box align="between">
              {shared_t(['table', 'error'], {
                error: dataQuery.error
                  ? (
                      (dataQuery.error as AxiosError).response
                        ?.data as MobileApiValidationErrorResponse
                    )?.reason?.[0]?.message
                  : '',
              })}
              <Button
                id="refesh-table"
                label={common_t(['label', 'refresh'])}
                variant="outlined"
                startIcon={<Icon.Refresh />}
                onClick={refetch}
              />
            </Box>
          </Alert>
        ) : null}

        {customAlertSectionElement}
      </div>

      <div aria-live="polite" aria-atomic={true}>
        <Typography variant="body" srOnly>
          {internalIsLoading
            ? shared_t(['table', 'loading'])
            : shared_t(['table', 'itemCount'], {
                count: dataQuery.data?.rows?.length || 0,
                total: dataQuery.data?.total || 0,
              })}
        </Typography>
      </div>
      {FilteredLiveRegion}
      {showLoadingIndicator ? (
        <Box align="center">{customLoadingElement || <Loading />}</Box>
      ) : isEmptyWithoutSearch && !hasError ? (
        <EmptyState {...emptyStateProps} />
      ) : (
        !isEmptyAfterSearch &&
        !hasError && (
          <>
            {!!columns && (
              <Overflowcontainer>
                <Table
                  rows={table.getRowModel().rows}
                  pagerId={pagerId}
                  caption={caption}
                  headerGroups={table.getHeaderGroups()}
                  isRowSelected={isRowSelected}
                />
              </Overflowcontainer>
            )}

            {children?.({
              isSelectingAll,
              selectedRows: Object.keys(rowSelection),
              setSelectedRows: setRowSelection,
              filters: debouncedFilters,
              q: qValue,
              data: rows,
            })}

            <Box align={paginationType === 'compact' ? 'center' : 'between'}>
              <Box mt="md">
                {Object.keys(rowSelection).length > 0 && (
                  <Typography variant="subtitle">
                    {shared_t(['table', 'numSelected'], {
                      count: isSelectingAll
                        ? dataQuery.data?.total
                        : Object.keys(rowSelection).length,
                    })}
                  </Typography>
                )}
              </Box>

              {includePagination && (
                <Box
                  align={paginationType === 'compact' ? 'center' : 'normal'}
                  mt="sm"
                  flexWrap="wrap">
                  {paginationType === 'normal' && (
                    <Box m="xs" alignItems="center">
                      <Typography variant="helptext">{shared_t(['table', 'show'])}</Typography>
                      <Dropdown
                        id="select-size"
                        trigger={() => (
                          <Button
                            id="select-size-button"
                            size="sm"
                            label={`${table.getState().pagination.pageSize}`}
                            aria-label={shared_t(['table', 'showMoreResults'])}
                            endIcon={<Icon.Expand size="sm" />}
                            color="black"
                          />
                        )}>
                        {(customLimits || [10, 20, 50]).map(pageSize => (
                          <DropdownMenuItem
                            id={`size-option-${pageSize}`}
                            variant="action"
                            key={pageSize}
                            label={pageSize}
                            onClick={onChangePagerLimit(Number(pageSize))}
                          />
                        ))}
                      </Dropdown>
                    </Box>
                  )}

                  {paginationType === 'normal' && (
                    <Box m="sm">
                      <Typography variant="helptext">
                        {shared_t(['table', 'pageSize'], {
                          start: pageIndex * pageSize + 1,
                          end: Math.min(pageSize * (pageIndex + 1), dataQuery.data?.total || 0),
                          total: dataQuery.data?.total,
                        })}
                      </Typography>
                    </Box>
                  )}

                  <Box>
                    <IconButton
                      className="first-page-button"
                      onClick={() => table.setPageIndex(0)}
                      disabled={!table.getCanPreviousPage()}
                      color="gray"
                      label={shared_t(['table', 'firstPage'])}
                      disabledTooltip={shared_t(['table', 'firstPage'])}
                      aria-label={shared_t(['table', 'firstPage'])}
                      icon={<Icon.FirstPage />}
                      disableRadixTooltip={disableRadixTooltips}
                    />
                    <IconButton
                      className="previous-page-button"
                      onClick={() => table.previousPage()}
                      disabled={!table.getCanPreviousPage()}
                      color="gray"
                      label={shared_t(['table', 'previousPage'])}
                      disabledTooltip={shared_t(['table', 'previousPage'])}
                      aria-label={shared_t(['table', 'previousPage'])}
                      icon={<Icon.PreviousPage />}
                      disableRadixTooltip={disableRadixTooltips}
                    />
                    <IconButton
                      className="next-page-button"
                      onClick={() => table.nextPage()}
                      disabled={!table.getCanNextPage()}
                      color="gray"
                      label={shared_t(['table', 'nextPage'])}
                      disabledTooltip={shared_t(['table', 'nextPage'])}
                      aria-label={shared_t(['table', 'nextPage'])}
                      icon={<Icon.NextPage />}
                      disableRadixTooltip={disableRadixTooltips}
                    />
                    <IconButton
                      className="last-page-button"
                      onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                      disabled={!table.getCanNextPage()}
                      color="gray"
                      label={shared_t(['table', 'lastPage'])}
                      disabledTooltip={shared_t(['table', 'lastPage'])}
                      aria-label={shared_t(['table', 'lastPage'])}
                      icon={<Icon.LastPage />}
                      disableRadixTooltip={disableRadixTooltips}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </>
        )
      )}
    </>
  );
};
