import truncate from 'lodash/truncate';
import { PureComponent } from 'react';
import { Combobox, Icon, IconButton } from 'singlewire-components';
import { BrandingContext } from '../../core/branding/BrandingContext';
import { formatDomainName } from '../../core/domains/DomainsUtils';
import Routes from '../../core/route/Routes';
import { getContrastYIQ } from '../../core/utils/color-representation';
import { shared_t } from '../shared/SharedLocale';
import { Button } from '../shared/components/Button';
import { DomainSelectProps } from './DomainSelectContainer';
import { layout_t } from './LayoutLocale';

export const PAGER_ID = 'domain-select-pager';

export class DomainSelect extends PureComponent<DomainSelectProps> {
  render() {
    const {
      domainsAvailable,
      actingDomain,
      askUserToChangeActingDomain,
      setDomainSelectValidity,
      validData,
      protection,
      xSmallScreen,
      navigateTo,
      allDomains,
    } = this.props;
    const actingDomainName = (actingDomain && formatDomainName(actingDomain)) || '';
    const { primaryColor } = this.context as any;

    const ContrastColor = getContrastYIQ(primaryColor);

    return allDomains && domainsAvailable && actingDomain ? (
      <div id="domain-select-menu-div" onClick={() => setDomainSelectValidity(!validData)}>
        <Combobox
          trigger={isOpen =>
            xSmallScreen ? (
              <IconButton
                id="domain-select-menu-button-xs"
                icon={<Icon.DomainSelect color={ContrastColor === '#FFFFFF' ? 'white' : 'black'} />}
                label={actingDomainName}
                color={ContrastColor === '#FFFFFF' ? 'white' : 'black'}
                size="lg"
              />
            ) : (
              <Button
                id="domain-select-menu-button"
                startIcon={
                  <Icon.DomainSelect color={ContrastColor === '#FFFFFF' ? 'white' : 'black'} />
                }
                label={actingDomainName}
                variant="text"
                size="lg"
                color={ContrastColor === '#FFFFFF' ? 'white' : 'black'}
                endIcon={
                  isOpen ? (
                    <Icon.Collapse size="sm" color="inherit" />
                  ) : (
                    <Icon.Expand size="sm" color="inherit" />
                  )
                }
              />
            )
          }
          options={allDomains.map(domain => ({
            label: domain.name,
            value: domain.id,
            subtitle: truncate(domain.namePath),
            onSelect: () => askUserToChangeActingDomain(domain, false, protection),
            icon: <Icon.FacilityAccess />,
          }))}
          searchPlaceholder={shared_t(['label', 'search'])}
          customLabel={layout_t(['facilities', 'label', 'manage'])}
          onCustomSelection={() => navigateTo(Routes.Domains.List)}
          showSelectedState={false}
        />
      </div>
    ) : (
      <></>
    );
  }
}

DomainSelect.contextType = BrandingContext;
