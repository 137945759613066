import {
  AllDeviceTypes,
  MobileApiScenarioSource,
  MobileApiScenarioType,
} from '../../mobile-api-types';

interface CrudRoutes {
  List: string;
  Edit: (id: string) => string;
  Create: string;
}

interface DefaultRoutes {
  Index: string;
}

// Our standard index and crud route structure so it's always the same
const indexRoute = (root: string) => root;
const listRoute = (root: string) => root;
const editRoute = (root: string) => (id: string) => `${root}/edit/${id}`;
const createRoute = (root: string) => `${root}/create`;

// Builds routes based off the root path given. This can be as deeply nested as you want.
// Example Usage:
// routes('/foo', path => ({Bar: `${path}/i-like-bars`}))
// {
//   Index: '/foo',
//   Bar: '/foo/i-like-bars'
// }
const routes = <T extends object>(root: string, builder: (root: string) => T): DefaultRoutes & T =>
  ({ Index: indexRoute(root), ...(builder(root) as object) }) as DefaultRoutes & T;

// Constructs a set our routes that includes the standard List, Edit, and Create routes.
// Example Usage:
// crudRoutes('/foo')
// {
//   Index: '/foo', List: '/foo', Edit: (id) => `/foo/edit/${id}`, Create: /foo/create
// }
const crudRoutes = <T extends object>(
  root: string,
  additions?: (root: string) => T
): DefaultRoutes & CrudRoutes & T =>
  routes(root, subPath => ({
    List: listRoute(subPath),
    Edit: editRoute(subPath),
    Create: createRoute(subPath),
    ...(additions ? additions(subPath) : {}),
  })) as DefaultRoutes & CrudRoutes & T;

const Routes = {
  Index: '/',
  Home: '/home',
  AccessDenied: '/access-denied',
  ActivationGroups: crudRoutes('/activation-groups'),
  Exception: '/exception',
  Login: '/login',
  NotFound: '/not-found',
  Bells: {
    Index: '/bells',
    Exceptions: '/bells/exceptions',
    Overview: '/bells/overview',
    RingLists: crudRoutes('/bells/ring-lists', ringListPath => ({
      DomainsTab: (ringListId: string) => `${ringListPath}/edit/${ringListId}/domains`,
      ManageDomains: (ringListId: string) => `${ringListPath}/manage/${ringListId}/domains`,
      CopyFrom: (ringListId: string, facilityId: string) =>
        `${ringListPath}/copyFrom/${ringListId}/site/${facilityId}`,
    })),
    Schedules: crudRoutes('/bells/schedules', bellSchedulePath => ({
      DomainsTab: (bellScheduleId: string) => `${bellSchedulePath}/edit/${bellScheduleId}/domains`,
      ManageDomains: (bellScheduleId: string) =>
        `${bellSchedulePath}/manage/${bellScheduleId}/domains`,
      CopyFrom: (bellScheduleId: string, facilityId: string) =>
        `${bellSchedulePath}/copyFrom/${bellScheduleId}/site/${facilityId}`,
    })),
  },
  DistributionLists: crudRoutes('/distribution-lists', distributionListPath => ({
    UserLoaderGroupsTab: (distributionListId: string) =>
      `${distributionListPath}/edit/${distributionListId}/uploaded-groups`,
    ServiceAccountsTab: (distributionListId: string) =>
      `${distributionListPath}/edit/${distributionListId}/service-accounts`,
    UsersTab: (distributionListId: string) =>
      `${distributionListPath}/edit/${distributionListId}/users`,
    DomainsTab: (distributionListId: string) =>
      `${distributionListPath}/edit/${distributionListId}/domains`,
    ManageUserLoaderGroups: (distributionListId: string) =>
      `${distributionListPath}/manage/${distributionListId}/uploaded-groups`,
    ManageServiceAccounts: (distributionListId: string) =>
      `${distributionListPath}/manage/${distributionListId}/service-accounts`,
    ManageDomains: (distributionListId: string) =>
      `${distributionListPath}/manage/${distributionListId}/domains`,
    ManageUsers: (distributionListId: string) =>
      `${distributionListPath}/manage/${distributionListId}/users`,
  })),
  DomainsConfig: '/config-domains',
  Domains: crudRoutes('/domains', domainPath => ({
    ManageAnonymousUsers: (domainId: string) => `${domainPath}/manage/${domainId}/anonymous-users`,
    ManageServiceAccounts: (domainId: string) =>
      `${domainPath}/manage/${domainId}/service-accounts`,
    ManageBellSchedules: (domainId: string) => `${domainPath}/manage/${domainId}/bell-schedules`,
    ManageConferenceCall: (domainId: string) => `${domainPath}/manage/${domainId}/conference-call`,
    ManageConfirmationRequests: (domainId: string) =>
      `${domainPath}/manage/${domainId}/confirmation-requests`,
    ManageDeviceGroups: (domainId: string) => `${domainPath}/manage/${domainId}/device-groups`,
    ManageDistributionLists: (domainId: string) =>
      `${domainPath}/manage/${domainId}/distribution-lists`,
    ManageIncidentPlans: (domainId: string) => `${domainPath}/manage/${domainId}/incident-plans`,
    ManageMessageTemplates: (domainId: string) =>
      `${domainPath}/manage/${domainId}/message-templates`,
    ManageMSTeamsChannels: (domainId: string) =>
      `${domainPath}/manage/${domainId}/msteams-channels`,
    ManageNotificationProfiles: (domainId: string) =>
      `${domainPath}/manage/${domainId}/notification-profiles`,
    ManageOutboundCap: (domainId: string) => `${domainPath}/manage/${domainId}/outbound-cap`,
    ManageOutboundRss: (domainId: string) => `${domainPath}/manage/${domainId}/outbound-rss`,
    ManageCiscoWebexTeams: (domainId: string) =>
      `${domainPath}/manage/${domainId}/cisco-webex-teams`,
    ManageRosteredUsers: (domainId: string) => `${domainPath}/manage/${domainId}/rostered-users`,
    ManageRingLists: (domainId: string) => `${domainPath}/manage/${domainId}/ring-list`,
    ManageScenarios: (domainId: string) => `${domainPath}/manage/${domainId}/scenario`,
    ManageSecurityGroups: (domainId: string) => `${domainPath}/manage/${domainId}/security-groups`,
    ManageSiteRoles: (domainId: string) => `${domainPath}/manage/${domainId}/site-roles`,
    ManageSites: (domainId: string) => `${domainPath}/manage/${domainId}/sites`,
    ManageTwitter: (domainId: string) => `${domainPath}/manage/${domainId}/twitter`,
    ManageUsers: (domainId: string) => `${domainPath}/manage/${domainId}/users`,
    ManageUserLoaders: (domainId: string) => `${domainPath}/manage/${domainId}/user-loaders`,
    AnonymousUsersTab: (domainId: string) => `${domainPath}/edit/${domainId}`,
    ServiceAccountsTab: (domainId: string) => `${domainPath}/edit/${domainId}/service-accounts`,
    BellSchedulesTab: (domainId: string) => `${domainPath}/edit/${domainId}/bell-schedules`,
    ConferenceCallTab: (domainId: string) => `${domainPath}/edit/${domainId}/conference-call`,
    ConfirmationRequestsTab: (domainId: string) =>
      `${domainPath}/edit/${domainId}/confirmation-requests`,
    DeviceGroupsTab: (domainId: string) => `${domainPath}/edit/${domainId}/device-groups`,
    DistributionListsTab: (domainId: string) => `${domainPath}/edit/${domainId}/distribution-lists`,
    IncidentPlansTab: (domainId: string) => `${domainPath}/edit/${domainId}/incident-plans`,
    IncidentsTab: (domainId: string) => `${domainPath}/edit/${domainId}/incidents`,
    MessageTemplatesTab: (domainId: string) => `${domainPath}/edit/${domainId}/message-templates`,
    MSTeamsChannelsTab: (domainId: string) => `${domainPath}/edit/${domainId}/msteams-channels`,
    NotificationProfilesTab: (domainId: string) =>
      `${domainPath}/edit/${domainId}/notification-profiles`,
    NotificationsTab: (domainId: string) => `${domainPath}/edit/${domainId}/notifications`,
    OutboundCapTab: (domainId: string) => `${domainPath}/edit/${domainId}/outbound-cap`,
    OutboundRssTab: (domainId: string) => `${domainPath}/edit/${domainId}/outbound-rss`,
    CiscoWebexTeamsTab: (domainId: string) => `${domainPath}/edit/${domainId}/cisco-webex-teams`,
    RosteredUsersTab: (domainId: string) => `${domainPath}/edit/${domainId}/rostered-users`,
    RingListsTab: (domainId: string) => `${domainPath}/edit/${domainId}/ring-lists`,
    ScenariosTab: (domainId: string) => `${domainPath}/edit/${domainId}/scenarios`,
    SecurityGroupsTab: (domainId: string) => `${domainPath}/edit/${domainId}/security-groups`,
    SiteRolesTab: (domainId: string) => `${domainPath}/edit/${domainId}/site-roles`,
    SitesTab: (domainId: string) => `${domainPath}/edit/${domainId}/sites`,
    TwitterTab: (domainId: string) => `${domainPath}/edit/${domainId}/twitter`,
    UsersTab: (domainId: string) => `${domainPath}/edit/${domainId}/users`,
    UserLoadersTab: (domainId: string) => `${domainPath}/edit/${domainId}/user-loaders`,
  })),
  DeviceGroups: crudRoutes('/device-groups', deviceGroupPath => ({
    DomainsTab: (deviceGroupId: string) => `${deviceGroupPath}/edit/${deviceGroupId}/domains`,
    ManageDomains: (deviceGroupId: string) => `${deviceGroupPath}/manage/${deviceGroupId}/domains`,
  })),
  InformaCastApps: '/informacast-apps',
  IPAWSAlerts: crudRoutes('/ipaws-alerts', ipawsAlertPath => ({
    details: (alertId: string, params: string) => `${ipawsAlertPath}/details/${alertId}?${params}`,
    cancelAlert: (alertId: string) => `${ipawsAlertPath}/cancel/${alertId}`,
    updateAlert: (alertId: string) => `${ipawsAlertPath}/update/${alertId}`,
  })),
  IPAWSAreas: crudRoutes('/ipaws-custom-areas', ipawsAreasPath => ({
    editCircle: (circleId: string) => `${ipawsAreasPath}/edit-circle/${circleId}`,
    editPolygon: (polygonId: string) => `${ipawsAreasPath}/edit-polygon/${polygonId}`,
  })),
  IPAWSConfig: {
    CreateAndStatus: '/config-ipaws',
    Index: '/config-ipaws',
    Renew: '/config-ipaws/renew',
  },
  FollowUpMessageTemplate: {
    Index: '/follow-up-message-template',
    Edit: (followUpMessageTemplateId: string) =>
      `/follow-up-message-template/${followUpMessageTemplateId}`,
  },
  MessageTemplates: crudRoutes('/message-templates', messageTemplatePath => ({
    DomainsTab: (messageTemplateId: string) =>
      `${messageTemplatePath}/edit/${messageTemplateId}/domains`,
    ManageDomains: (messageTemplateId: string) =>
      `${messageTemplatePath}/manage/${messageTemplateId}/domains`,
    CreateMessageTemplateIncidentPlan: crudRoutes(`${messageTemplatePath}/create/incident-plans`),
    EditMessageTemplateIncidentPlan: (messageTemplateId: string) =>
      crudRoutes(`${messageTemplatePath}/edit/${messageTemplateId}/incident-plans`),
    CopyFrom: (messageTemplateId: string, facilityId: string) =>
      `${messageTemplatePath}/copyFrom/${messageTemplateId}/site/${facilityId}`,
  })),
  FusionFailover: crudRoutes('/fusion-failover'),
  Notifications: {
    List: '/notifications',
    ScheduledList: '/notifications/scheduled',
    Details: (notificationId: string) => `/notifications/details/${notificationId}`,
    UserSentNotificationDetails: (notificationId: string, userId: string) =>
      `/notifications/details/${notificationId}?userId=${userId}&isSentNotifications=${true}`,
    UserNotificationDetails: (notificationId: string, userId: string) =>
      `/notifications/details/${notificationId}?userId=${userId}`,
    ActivitiesTab: (notificationId: string) =>
      `/notifications/details/${notificationId}/activities`,
    ActionsTab: (notificationId: string) => `/notifications/details/${notificationId}/actions`,
    DomainsTab: (notificationId: string) => `/notifications/details/${notificationId}/domains`,
    Send: `/notifications/send`,
    SendFollowUp: (params: string) => `/notifications/send?${params}`,
    Summary: `/notifications/summary`,
  },
  NotificationProfiles: crudRoutes('/notification-profiles', notificationProfilePath => ({
    DomainsTab: (notificationProfileId: string) =>
      `${notificationProfilePath}/edit/${notificationProfileId}/domains`,
    ManageDomains: (notificationProfileId: string) =>
      `${notificationProfilePath}/manage/${notificationProfileId}/domains`,
  })),
  Policies: crudRoutes('/policies', policiesPath => ({
    SecurityGroupsTab: (policyId: string) => `${policiesPath}/edit/${policyId}/security-groups`,
  })),
  SecurityGroups: crudRoutes('/security-groups', securityGroupPath => ({
    UsersTab: (securityGroupId: string) => `${securityGroupPath}/edit/${securityGroupId}/users`,
    ServiceAccountsTab: (securityGroupId: string) =>
      `${securityGroupPath}/edit/${securityGroupId}/service-accounts`,
    DomainsTab: (securityGroupId: string) => `${securityGroupPath}/edit/${securityGroupId}/domains`,
    AllowedResourcesTab: (securityGroupId: string) =>
      `${securityGroupPath}/edit/${securityGroupId}/allowed-resources`,
    PermissionsTab: (securityGroupId: string) =>
      `${securityGroupPath}/edit/${securityGroupId}/permissions`,
    UserLoaderGroupsTab: (securityGroupId: string) =>
      `${securityGroupPath}/edit/${securityGroupId}/uploaded-groups`,
    ManageServiceAccounts: (securityGroupId: string) =>
      `${securityGroupPath}/manage/${securityGroupId}/service-accounts`,
    ManageDomains: (securityGroupId: string) =>
      `${securityGroupPath}/manage/${securityGroupId}/domains`,
    ManageUsers: (securityGroupId: string) =>
      `${securityGroupPath}/manage/${securityGroupId}/users`,
    ManageAllowedScenarios: (securityGroupId: string) =>
      `${securityGroupPath}/manage/${securityGroupId}/allowed-scenarios`,
    ManageAllowedMessageTemplates: (securityGroupId: string) =>
      `${securityGroupPath}/manage/${securityGroupId}/allowed-message-templates`,
    ManageUserLoaderGroups: (securityGroupId: string) =>
      `${securityGroupPath}/manage/${securityGroupId}/uploaded-groups`,
    AddDistListPermissions: (securityGroupId: string) =>
      `${securityGroupPath}/add-distribution-list-permissions/${securityGroupId}`,
    AddMessageTemplatePermissions: (securityGroupId: string) =>
      `${securityGroupPath}/add-message-template-permissions/${securityGroupId}`,
    AddConfirmRequestPermissions: (securityGroupId: string) =>
      `${securityGroupPath}/add-confirmation-request-permissions/${securityGroupId}`,
    AddAoiPermissions: (securityGroupId: string) =>
      `${securityGroupPath}/add-area-of-interest-permissions/${securityGroupId}`,
    AddOutboundSystemsPermissions: (securityGroupId: string) =>
      `${securityGroupPath}/add-outbound-systems-permissions/${securityGroupId}`,
    AddDeviceGroupPermissions: (securityGroupId: string) =>
      `${securityGroupPath}/add-device-group-permissions/${securityGroupId}`,
    AddBellSchedulePermissions: (securityGroupId: string) =>
      `${securityGroupPath}/add-bell-schedule-permissions/${securityGroupId}`,
    AddRingListPermissions: (securityGroupId: string) =>
      `${securityGroupPath}/add-ring-list-permissions/${securityGroupId}`,
    AddSitePermissions: (securityGroupId: string) =>
      `${securityGroupPath}/add-site-permissions/${securityGroupId}`,
    AddScenarioPermissions: (securityGroupId: string) =>
      `${securityGroupPath}/add-scenario-permissions/${securityGroupId}`,
    AddIncidentPlanPermissions: (securityGroupId: string) =>
      `${securityGroupPath}/add-incident-plan-permissions/${securityGroupId}`,
  })),
  SystemHealth: {
    Index: '/system-health',
    Dashboard: '/system-health/dashboard',
    AlarmAdd: (alarmId: string) => `/system-health/${alarmId}/add`,
    AlarmEdit: (alarmId: string, actionId: string) => `/system-health/${alarmId}/edit/${actionId}`,
  },
  Triggers: crudRoutes('/triggers'),
  UserLoaders: crudRoutes('/user-loaders', userLoaderPath => ({
    CommunicationTab: (userLoaderId: string) =>
      `${userLoaderPath}/edit/${userLoaderId}/communication`,
    DistributionListTagsUserTab: (userLoaderId: string) =>
      `${userLoaderPath}/edit/${userLoaderId}/distribution-list-tags`,
    SecurityGroupListTagsUserTab: (userLoaderId: string) =>
      `${userLoaderPath}/edit/${userLoaderId}/security-group-tags`,
    UserTypeTagsUserTab: (userLoaderId: string) =>
      `${userLoaderPath}/edit/${userLoaderId}/user-type-tags`,
    DomainsTagsUserTab: (userLoaderId: string) =>
      `${userLoaderPath}/edit/${userLoaderId}/domain-tags`,
    FacilitiesTagsUserTab: (userLoaderId: string) =>
      `${userLoaderPath}/edit/${userLoaderId}/site-tags`,
    DistributionListTagCreate: (userLoaderId: string) =>
      `${userLoaderPath}/${userLoaderId}/distribution-list-tags/create`,
    UserTypeTagsCreate: (userLoaderId: string) =>
      `${userLoaderPath}/${userLoaderId}/user-type-tags/create`,
    DomainsTagsCreate: (userLoaderId: string) =>
      `${userLoaderPath}/${userLoaderId}/domain-tags/create`,
    FacilitiesTagsCreate: (userLoaderId: string) =>
      `${userLoaderPath}/${userLoaderId}/site-tags/create`,
    SecurityGroupListTagsCreate: (userLoaderId: string) =>
      `${userLoaderPath}/${userLoaderId}/security-group-tags/create`,
    ScimUserTab: (userLoaderId: string) => `${userLoaderPath}/edit/${userLoaderId}/scim-users`,
    DomainsTab: (userLoaderId: string) => `${userLoaderPath}/edit/${userLoaderId}/domains`,
    ManageDomains: (userLoaderId: string) => `${userLoaderPath}/manage/${userLoaderId}/domains`,
    LoadRequestTab: (userLoaderId: string) =>
      `${userLoaderPath}/edit/${userLoaderId}/load-requests`,
    GroupTab: (userLoaderId: string) => `${userLoaderPath}/edit/${userLoaderId}/scim-groups`,
    Groups: (userLoaderId: string, groupId: string) =>
      `${userLoaderPath}/edit/${userLoaderId}/manage-group/${groupId}`,
    GroupsFacilities: (userLoaderId: string, groupId: string) =>
      `${userLoaderPath}/edit/${userLoaderId}/manage-group-facilities/${groupId}`,
  })),
  SISLoaders: crudRoutes('/sis-loaders', sisLoaderPath => ({
    Groups: (sisLoaderId: string, groupId: string) =>
      `${sisLoaderPath}/edit/${sisLoaderId}/manage-group/${groupId}`,
    GroupsFacilites: (sisLoaderId: string, groupId: string) =>
      `${sisLoaderPath}/edit/${sisLoaderId}/manage-group-facilities/${groupId}`,
    GroupTab: (sisLoaderId: string) => `${sisLoaderPath}/edit/${sisLoaderId}/groups`,
  })),
  ServiceAccounts: crudRoutes('/service-accounts', serviceAccountPath => ({
    PermissionsTab: (serviceAccountId: string) =>
      `${serviceAccountPath}/edit/${serviceAccountId}/security`,
    DomainsTab: (serviceAccountId: string) =>
      `${serviceAccountPath}/edit/${serviceAccountId}/domains`,
    ManageDomains: (serviceAccountId: string) =>
      `${serviceAccountPath}/manage/${serviceAccountId}/domains`,
  })),
  Users: crudRoutes('/users', userPath => ({
    DomainsTab: (userId: string) => `${userPath}/edit/${userId}/domains`,
    Notifications: (userId: string, notificationId: string) =>
      `${userPath}/${userId}/notifications/${notificationId}`,
    NotificationsTab: (userId: string) => `${userPath}/edit/${userId}/notifications`,
    SentNotifications: (userId: string, notificationId: string) =>
      `${userPath}/${userId}/sent-notifications/${notificationId}`,
    SentNotificationsTab: (userId: string) => `${userPath}/edit/${userId}/sent-notifications`,
    ManageDomains: (userId: string) => `${userPath}/manage/${userId}/domains`,
    ManageSubscriptions: (userId: string) => `${userPath}/manage/${userId}/subscriptions`,
    SitesTab: (userId: string) => `${userPath}/edit/${userId}/sites`,
    PermissionsTab: (userId: string) => `${userPath}/edit/${userId}/security`,
    AddDistListPermissions: (userId: string) =>
      `${userPath}/add-distribution-list-permissions/${userId}`,
    AddMessageTemplatePermissions: (userId: string) =>
      `${userPath}/add-message-template-permissions/${userId}`,
    AddConfirmRequestPermissions: (userId: string) =>
      `${userPath}/add-confirmation-request-permissions/${userId}`,
    AddAoiPermissions: (userId: string) => `${userPath}/add-area-of-interest-permissions/${userId}`,
    AddOutboundSystemsPermissions: (userId: string) =>
      `${userPath}/add-outbound-systems-permissions/${userId}`,
    AddDeviceGroupPermissions: (userId: string) =>
      `${userPath}/add-device-group-permissions/${userId}`,
    AddBellSchedulePermissions: (userId: string) =>
      `${userPath}/add-bell-schedule-permissions/${userId}`,
    AddRingListPermissions: (userId: string) => `${userPath}/add-ring-list-permissions/${userId}`,
    AddSitePermissions: (userId: string) => `${userPath}/add-site-permissions/${userId}`,
    AddScenarioPermissions: (userId: string) => `${userPath}/add-scenario-permissions/${userId}`,
    AddIncidentPlanPermissions: (userId: string) =>
      `${userPath}/add-incident-plan-permissions/${userId}`,
  })),
  AnonymousUsers: crudRoutes('/users', userPath => ({
    DomainsTab: (userId: string) => `${userPath}/edit/${userId}/domains`,
    ManageDomains: (userId: string) => `${userPath}/manage/${userId}/domains`,
  })),
  RosteredUsers: crudRoutes('/users', userPath => ({
    DomainsTab: (userId: string) => `${userPath}/edit/${userId}/domains`,
    ManageDomains: (userId: string) => `${userPath}/manage/${userId}/domains`,
  })),
  OutboundRss: crudRoutes('/outbound-rss', outboundRssPath => ({
    DomainsTab: (outboundRssId: string) => `${outboundRssPath}/edit/${outboundRssId}/domains`,
    ManageDomains: (outboundRssId: string) => `${outboundRssPath}/manage/${outboundRssId}/domains`,
  })),
  ConferenceCall: crudRoutes('/conference-call', conferenceCallPath => ({
    DomainsTab: (conferenceCallId: string) =>
      `${conferenceCallPath}/edit/${conferenceCallId}/domains`,
    ManageDomains: (conferenceCallId: string) =>
      `${conferenceCallPath}/manage/${conferenceCallId}/domains`,
  })),
  TeamsGroup: {
    Index: '/msteams-outbound',
    ChannelsTab: crudRoutes('/msteams-outbound/channels', channelsPath => ({
      DomainsTab: (channelId: string) => `${channelsPath}/edit/${channelId}/domains`,
      ManageDomains: (channelId: string) => `${channelsPath}/manage/${channelId}/domains`,
    })),
  },
  Twitter: crudRoutes('/twitter', twitterPath => ({
    DomainsTab: (twitterId: string) => `${twitterPath}/edit/${twitterId}/domains`,
    ManageDomains: (twitterId: string) => `${twitterPath}/manage/${twitterId}/domains`,
  })),
  TtsConfig: '/tts-config',
  Appearance: '/appearance',
  Help: '/help',
  SelfRegistration: '/self-registration',
  CiscoWebexTeams: crudRoutes('/cisco-webex-teams', ciscoWebexTeamsPath => ({
    DomainsTab: (ciscoWebexTeamsId: string) =>
      `${ciscoWebexTeamsPath}/edit/${ciscoWebexTeamsId}/domains`,
    ManageDomains: (ciscoWebexTeamsId: string) =>
      `${ciscoWebexTeamsPath}/manage/${ciscoWebexTeamsId}/domains`,
  })),
  OutboundCap: crudRoutes('/outbound-cap', outboundCapPath => ({
    DomainsTab: (outboundCapId: string) => `${outboundCapPath}/edit/${outboundCapId}/domains`,
    ManageDomains: (outboundCapId: string) => `${outboundCapPath}/manage/${outboundCapId}/domains`,
  })),
  GenericMulticastStreaming: crudRoutes('/generic-multicast-streaming'),
  AreasOfInterest: crudRoutes('/areas-of-interest', aoiPath => ({
    BoundaryTriggers: (aoiId: string) => crudRoutes(`${aoiPath}/edit/${aoiId}/boundary-triggers`),
  })),
  Devices: {
    ...crudRoutes(`/devices`),
    IndexFiltered: (params: string) => `/devices?${params}`,
  },
  IpCameras: crudRoutes(`/ip-cameras`, ipCamerasPath => ({
    VendorConfig: crudRoutes(`${ipCamerasPath}/vendor-configuration`),
    Integration: `${ipCamerasPath}/integration`,
    Import: `${ipCamerasPath}/import`,
  })),
  Admin: routes('/admin', adminPath => ({
    Settings: routes(`${adminPath}/settings`, settingsPath => ({
      PolyPhones: `${settingsPath}/poly-phones`,
    })),
    Licenses: {
      Index: `${adminPath}/licenses`,
      UserCount: `${adminPath}/licenses/user-count`,
      Phones: `${adminPath}/licenses/phones`,
      IPSpeakers: `${adminPath}/licenses/ip-speakers`,
      DesktopNotifiers: `${adminPath}/licenses/desktop-notifiers`,
    },
    SIP: routes(`${adminPath}/sip`, sipPath => ({
      Access: `${sipPath}/access/edit`,
      AccessCreate: `${sipPath}/access/directives/create`,
      AccessEdit: (directiveId: string) => `${sipPath}/access/directives/edit/${directiveId}`,
      Accounts: crudRoutes(`${sipPath}/accounts`),
      Security: `${sipPath}/call-security`,
      Certificates: `${sipPath}/certificates`,
      CertificateDetails: (certificateId: string) =>
        `${sipPath}/certificates/${certificateId}/details`,
      Credentials: crudRoutes(`${sipPath}/credentials`),
      Stack: `${sipPath}/stack`,
      Restart: `${sipPath}/restart`,
      DnsSrv: `${sipPath}/dns-srv-records`,
      ServerGroups: crudRoutes(`${sipPath}/server-groups`),
      Registrations: crudRoutes(`${sipPath}/registrations`),
      SpeakerParameters: {
        Index: `${sipPath}/sip-speaker-parameters`,
        NextSteps: `${sipPath}/sip-speaker-parameters/next-steps`,
      },
      SpeakerParametersV2: crudRoutes(`${sipPath}/sip-speaker-parameters-v2`, path => ({
        NextSteps: `${path}/next-steps`,
      })),
    })),
    System: routes(`${adminPath}/system`, systemPath => ({
      Certificates: `${systemPath}/certificates`,
      ClearDevices: `${systemPath}/clear-devices`,
      ClearDevicesScheduledTab: `${systemPath}/clear-devices/scheduled`,
      ClearDevicesSchedulesCreate: `${systemPath}/clear-devices-schedules/create`,
      ClearDevicesSchedulesEdit: (scheduleId: string) =>
        `${systemPath}/clear-devices-schedules/edit/${scheduleId}`,
      ClientCertificateView: (alias: string) => `${systemPath}/client-certificates/view/${alias}`,
      SSLParams: `${systemPath}/certificates/ssl-parameters`,
    })),
    Security: routes(`${adminPath}/security`, securityPath => ({
      EncryptionKeyManagement: `${securityPath}/encryptionKeyManagement`,
    })),
    NetworkConfig: `${adminPath}/networkConfig/timeouts/edit`,
  })),
  InboundCap: crudRoutes('/inbound-cap', icapPath => ({
    Test: `${icapPath}/test`,
  })),
  InboundEmail: crudRoutes('/inbound-email', emailPath => ({
    OutboundRule: (emailId: string) => crudRoutes(`${emailPath}/edit/${emailId}/outbound-rules`),
  })),
  ConfirmationRequests: crudRoutes('/confirmation-requests', crPath => ({
    ManageDomains: (confirmationRequestId: string) =>
      `${crPath}/manage/${confirmationRequestId}/domains`,
    DomainsTab: (confirmationRequestId: string) =>
      `${crPath}/edit/${confirmationRequestId}/domains`,
    EscalationRulesTab: (confirmationRequestId: string) =>
      `${crPath}/edit/${confirmationRequestId}/escalation-rules`,
    EscalationRules: (crId: string) => {
      const erRoot = `${crPath}/edit/${crId}/escalation-rules`;
      return {
        Edit: editRoute(erRoot),
        Create: createRoute(erRoot),
        List: (erId: string) => `${erRoot}/list/${erId}`,
      };
    },
    DynamicRepliesTab: (confirmationRequestId: string) =>
      `${crPath}/edit/${confirmationRequestId}/dynamic-replies`,
    DynamicRepliesTabSuffix: '/dynamic-replies',
    DynamicReplies: (crId: string) => {
      const erRoot = `${crPath}/edit/${crId}/dynamic-replies`;
      return {
        Edit: editRoute(erRoot),
        Create: createRoute(erRoot),
        Index: indexRoute(erRoot),
      };
    },
  })),
  CommandCenter: {
    Index: '/command-center',
    SiteRoles: crudRoutes('/command-center/site-roles', siteRolesPath => ({
      ManageDomains: (siteRoleId: string) => `${siteRolesPath}/manage/${siteRoleId}/domains`,
      DomainsTab: (siteRoleId: string) => `${siteRolesPath}/edit/${siteRoleId}/domains`,
      AssociatedSitesTab: (siteRoleId: string) =>
        `${siteRolesPath}/edit/${siteRoleId}/associated-sites`,
      AssociateSites: (siteRoleId: string) =>
        `${siteRolesPath}/edit/${siteRoleId}/associated-sites/add`,
    })),
    Sites: crudRoutes('/command-center/sites', sitesPath => ({
      ListPane: `${sitesPath}/pane`,
      CreatePane: `${sitesPath}/pane/create`,
      ManageDomains: (siteId: string) => `${sitesPath}/manage/${siteId}/domains`,
      DomainsTab: (siteId: string) => `${sitesPath}/edit/${siteId}/domains`,
      CreateSiteSiteRole: crudRoutes(`${sitesPath}/create/site-roles`),
      SingleSite: (siteId: string) =>
        routes(`${sitesPath}/pane/${siteId}`, singleSitePath => ({
          DetailsTab: `${singleSitePath}/`,
          BuildingsTab: `${singleSitePath}/buildings`,
          SiteRolesTab: `${singleSitePath}/site-roles`,
          SiteRoles: crudRoutes(`${singleSitePath}/site-roles`),
          SiteRoleCreateOverlay: crudRoutes(`${singleSitePath}/site-roles/create/site-recipients`),
          SiteRoleEditOverlay: (recipientId: string) =>
            crudRoutes(`${singleSitePath}/site-roles/edit/${recipientId}/site-recipients`),
          BuildingCreate: `${singleSitePath}/buildings/create`,
          Edit: `${singleSitePath}/edit`,
        })),
      SingleBuilding: (siteId: string, buildingId: string) =>
        routes(`${sitesPath}/pane/${siteId}/buildings/${buildingId}`, buildingPath => ({
          FloorCreate: `${buildingPath}/floors/create`,
          Edit: `${buildingPath}/edit`,
          FloorsTab: `${buildingPath}/floors`,
          DevicesTab: `${buildingPath}/devices`,
          DevicesTabWithDeviceHighlighted: (deviceId: string, deviceType: AllDeviceTypes) =>
            `${buildingPath}/devices?type=${deviceType}&id=${deviceId}`,
          ReorderFloors: `${buildingPath}/floors/reorder`,
        })),
      SingleFloor: (siteId: string, buildingId: string, floorId: string) =>
        routes(
          `${sitesPath}/pane/${siteId}/buildings/${buildingId}/floors/${floorId}`,
          floorPath => ({
            ZoneCreate: `${floorPath}/zones/create`,
            Edit: `${floorPath}/edit`,
            ZonesTab: `${floorPath}/zones`,
            DevicesTab: `${floorPath}/devices`,
            DevicesTabWithDeviceHighlighted: (deviceId: string, deviceType: AllDeviceTypes) =>
              `${floorPath}/devices?type=${deviceType}&id=${deviceId}`,
          })
        ),
      SingleZone: (siteId: string, buildingId: string, floorId: string, zoneId: string) =>
        routes(
          `${sitesPath}/pane/${siteId}/buildings/${buildingId}/floors/${floorId}/zones/${zoneId}`,
          zonePath => ({
            Edit: `${zonePath}/edit`,
            DevicesTab: `${zonePath}/devices`,
            DevicesTabWithDeviceHighlighted: (deviceId: string, deviceType: AllDeviceTypes) =>
              `${zonePath}/devices?type=${deviceType}&id=${deviceId}`,
          })
        ),
      EditSiteSiteRole: (siteId: string) =>
        crudRoutes(`${sitesPath}/edit/${siteId}/site-recipients/site-roles`),
      SiteBuildingsTab: (siteId: string) => `${sitesPath}/edit/${siteId}/buildings`,
      SiteBuildings: (siteId: string) =>
        crudRoutes(`${sitesPath}/edit/${siteId}/buildings`, siteBuildingsPath => ({
          SiteBuildingFloorsTab: (siteBuildingId: string) =>
            `${siteBuildingsPath}/edit/${siteBuildingId}/floors`,
          SiteBuildingFloors: (siteBuildingId: string) =>
            crudRoutes(
              `${siteBuildingsPath}/edit/${siteBuildingId}/floors`,
              siteBuildingFloorPath => ({
                SiteBuildingZonesTab: (siteBuildingFloorId: string) =>
                  `${siteBuildingFloorPath}/edit/${siteBuildingFloorId}/zones`,
                SiteBuildingZones: (siteBuildingFloorId: string) =>
                  crudRoutes(`${siteBuildingFloorPath}/edit/${siteBuildingFloorId}/zones`),
              })
            ),
        })),
    })),
    SitesV2: routes('/command-center/sites-v2', sitesPath => ({
      Sites: sitesPath,
      Site: (siteId: string) => `${sitesPath}/${siteId}`,
      SiteBuildings: (siteId: string) => `${sitesPath}/${siteId}/buildings`,
      SiteBuilding: (siteId: string, siteBuildingId: string) =>
        `${sitesPath}/${siteId}/buildings/${siteBuildingId}`,
      SiteBuildingFloors: (siteId: string, siteBuildingId: string) =>
        `${sitesPath}/${siteId}/buildings/${siteBuildingId}/floors`,
      SiteBuildingFloor: (siteId: string, siteBuildingId: string, siteBuildingFloorId: string) =>
        `${sitesPath}/${siteId}/buildings/${siteBuildingId}/floors/${siteBuildingFloorId}`,
      SiteBuildingFloorZones: (
        siteId: string,
        siteBuildingId: string,
        siteBuildingFloorId: string
      ) => `${sitesPath}/${siteId}/buildings/${siteBuildingId}/floors/${siteBuildingFloorId}/zones`,
      SiteBuildingFloorZone: (
        siteId: string,
        siteBuildingId: string,
        siteBuildingFloorId: string,
        siteBuildingFloorZoneId: string
      ) =>
        `${sitesPath}/${siteId}/buildings/${siteBuildingId}/floors/${siteBuildingFloorId}/zones/${siteBuildingFloorZoneId}`,
      ManageDomains: (siteId: string) => `${sitesPath}/manage/${siteId}/domains`,
      DomainsTab: (siteId: string) => `${sitesPath}/edit/${siteId}/domains`,
    })),
    Scenarios: crudRoutes('/command-center/scenarios', scenariosPath => ({
      CreateWithType: (type: MobileApiScenarioType, source: MobileApiScenarioSource) =>
        `${scenariosPath}/create?type=${type}&source=${source}`,
      Send: (scenarioId: string) => `${scenariosPath}/${scenarioId}/send`,
      ManageDomains: (scenarioId: string) => `${scenariosPath}/manage/${scenarioId}/domains`,
      DomainsTab: (scenarioId: string) => `${scenariosPath}/edit/${scenarioId}/domains`,
      CreateScenarioIncidentPlan: crudRoutes(`${scenariosPath}/create/incident-plans`),
      EditScenarioIncidentPlan: (scenarioId: string) =>
        crudRoutes(`${scenariosPath}/edit/${scenarioId}/incident-plans`),
      CopyFrom: (scenarioId: string, facilityId: string) =>
        `${scenariosPath}/copyFrom/${scenarioId}/site/${facilityId}`,
    })),
    IncidentPlans: crudRoutes('/command-center/incident-plans', incidentPlansPath => ({
      DomainsTab: (incidentPlanId: string) => `${incidentPlansPath}/edit/${incidentPlanId}/domains`,
      ManageDomains: (incidentPlanId: string) =>
        `${incidentPlansPath}/manage/${incidentPlanId}/domains`,
    })),
    Incidents: {
      Index: '/command-center/incidents',
      List: '/command-center/incidents',
      Details: (incidentId: string) => `/command-center/incidents/details/${incidentId}`,
    },
  },
  ApiConnectors: crudRoutes('/api-connectors'),
  DialCast: {
    Index: '/dialcast',
    DialingConfigurations: crudRoutes('/dialcast/dialing-configurations'),
    PhoneExceptions: crudRoutes('/dialcast/phone-exceptions'),
  },
  DialCastV2: {
    Index: '/dialcast-v2',
    DialingConfigurations: crudRoutes('/dialcast-v2/dialing-configurations'),
    PhoneExceptions: crudRoutes('/dialcast-v2/phone-exceptions'),
  },
  LegacyBells: {
    Index: '/icop/bells',
  },
  LegacyActivationGroups: {
    Index: '/distributedActivation',
  },
  IDNSettings: {
    Index: '/idn',
  },
  BroadcastParameters: {
    Index: '/broadcast-parameters',
  },
  NetworkParameters: {
    Index: '/network-parameters',
  },
  SlpParameters: {
    Index: '/slp-parameters',
  },
  SpeakerSettings: {
    Index: '/speaker-settings',
  },
  FusionConfiguration: {
    Index: '/icop/admin/overview',
  },
  CallAware: {
    Index: '/callaware',
    Diagnostics: '/callaware/diagnostics',
    Configuration: '/callaware/configuration',
    CallRedirects: crudRoutes('/callaware/call-redirects'),
    RoutingRequests: crudRoutes('/callaware/routing-requests'),
    CUCM: crudRoutes('/callaware/cucms'),
    History: {
      Index: '/callaware/call-history',
      Audio: {
        live: '/Plugins/CallAware/callHistory/audioStream',
        elapsed: '/Plugins/CallAware/callHistory/audio',
      },
    },
  },
  NightBell: {
    Index: '/nightbell',
    Diagnostics: '/nightbell/diagnostics',
    Configuration: '/nightbell/configuration',
    Triggers: crudRoutes('/nightbell/triggers'),
    CUCM: crudRoutes('/nightbell/cucms'),
  },
  CTICertificates: crudRoutes('/icop/admin/telephony/CTICertificates'),
  BulkUpload: crudRoutes('/bulk-uploads'),
  M2M: {
    Index: '/m2m',
    Schedules: crudRoutes('/m2m/schedules'),
    ContactClosures: crudRoutes('/m2m/contact-closures', closurePath => ({
      InputPorts: (closureId: string) => crudRoutes(`${closurePath}/edit/${closureId}/input-port`),
      OutputPorts: (closureId: string) =>
        crudRoutes(`${closurePath}/edit/${closureId}/output-port`),
    })),
  },
  InboundRSS: crudRoutes('/inbound-rss'),
  QuickURL: crudRoutes('/quick-url'),
  PagingGateway: crudRoutes('/paging-gateway'),
  IPSpeaker: crudRoutes('/ip-speakers', speakerPath => ({
    New: crudRoutes(`${speakerPath}/new`),
  })),
  IPSpeakerV2: crudRoutes('/ip-speakers-v2', speakerPath => ({
    EditRedirect: (id: string) => `${speakerPath}/redirect/${id}`,
    New: crudRoutes(`${speakerPath}/new`),
    BulkActions: (jobId: string) => `${speakerPath}/actions/${jobId}`,
  })),
  DesktopNotifier: crudRoutes('/desktop-notifiers', desktopNotifierPath => ({
    Test: (macAddress: string) => `${desktopNotifierPath}/test/${macAddress}`,
    New: crudRoutes(`${desktopNotifierPath}/new`, newPath => ({
      Test: (desktopNotifierId: string) => `${newPath}/test/${desktopNotifierId}`,
    })),
  })),
  DesktopNotifierV2: crudRoutes('/desktop-notifiers-v2', speakerPath => ({
    New: crudRoutes(`${speakerPath}/new`),
    BulkActions: (jobId: string) => `${speakerPath}/actions/${jobId}`,
  })),
  ScheduledNotifications: crudRoutes('/scheduled-notifications'),
  DesktopUsers: {
    Index: '/desktop-users',
    Edit: (userId: string) => `/desktop-users/edit/${userId}`,
  },
  LPI: {
    Index: '/lpi',
    Configuration: '/lpi/configuration',
    CurrentActivity: '/lpi/current-activity',
    ServerGroups: crudRoutes('/lpi/sip-server-groups'),
    PagingDevices: crudRoutes('/lpi/paging-devices'),
  },
  Reports: {
    Index: '/reports',
    User: {
      Result: '/reports/user-reports/result',
      Index: '/reports/user-reports',
    },
    AnonymousUser: {
      Result: '/reports/anonymous-user-reports/result',
      Index: '/reports/anonymous-user-reports',
    },
    NotificationHistory: {
      Result: '/reports/notification-history-reports/result',
      Index: '/reports/notification-history-reports',
    },
    Confirmation: {
      Result: '/reports/confirmation-reports/result',
      Index: '/reports/confirmation-reports',
    },
    AuditEvents: {
      Result: '/reports/audit-events/result',
      Index: '/reports/audit-events',
    },
  },
  Servers: {
    Index: '/fusion-servers',
    Edit: (icopServerId: string) => `/fusion-servers/edit/${icopServerId}`,
  },
  DND: '/do-not-disturb',
  SMS: '/sms',
  Applications: crudRoutes('/oauth-applications', applicationPath => ({
    CredentialsTab: (applicationId: string) => `${applicationPath}/edit/${applicationId}/secrets`,
  })),
  IdentityProviders: crudRoutes('/identity-providers'),
  PublicApplications: {
    Index: '/public-applications',
    Edit: (applicationId: string) => `/public-applications/edit/${applicationId}`,
  },
  KontaktioWearablePanicButton: {
    Index: '/kontaktio-wearable-panic-button',
    Import: {
      Index: '/kontaktio-wearable-panic-button/import',
      Overlay: '/kontaktio-wearable-panic-button/import/management',
    },
  },
  Settings: '/time-zone', // Renamed for automation tests. May need to re-rename when settings is expanded upon.
  Calling: '/calling',
  MSTeamsConfig: '/msteams-config',
  WebexCallingConfig: {
    Index: '/webex-calling-config',
    Devices: '/webex-calling-config/devices',
    Manage: {
      Integration: '/webex-calling-config/manage-integration',
    },
    Import: {
      Locations: '/webex-calling-config/import-locations',
    },
  },
  Email: '/email',
  DialToIntercom: '/dial-to-intercom',
  VoiceMenus: {
    Index: '/voice',
  },
  TrackingEvents: {
    Index: '/tracking-events',
    Event: (trackingEventId: string) => `/tracking-events/${trackingEventId}`,
    User: (trackingEventId: string, userId: string) =>
      `/tracking-events/${trackingEventId}/users/${userId}`,
  },
  TrackingEventNotifications: crudRoutes('/tracking-event-notifications'),
  CucmClusters: crudRoutes('/cucm-clusters', cucmPath => ({
    FusionTab: (cucmId: string) => `${cucmPath}/edit/${cucmId}/fusion-settings`,
    CucmFusionServers: (cucmId: string) =>
      crudRoutes(`${cucmPath}/edit/${cucmId}/cucm-fusion-servers`),
    /**
     * This route provides a way for us to fetch a ccfs (and cucm) record using only the ccfsId.
     * Prefer to use `CucmFusionServers.Edit` when you have the cucmId.
     * See {@link SyncErrorEditCucmFusionServer} for more details
     */
    EditCucmFusionServer: (ccfsId: string) => `${cucmPath}/cucm-fusion-server/edit/${ccfsId}`,
    FusionClusterSecurity: (cucmId: string, fusionServerId: string, id: string) =>
      `${cucmPath}/edit/${cucmId}/security/${fusionServerId}/fusion/${id}`,
    LegacyClusterSecurity: (cucmId: string, fusionServerId: string, id: string) =>
      `${cucmPath}/edit/${cucmId}/security/${fusionServerId}/${id}`,
    PhoneParameters: `${cucmPath}/phone-parameters`,
    PhoneRefreshOverview: `${cucmPath}/phone-refresh-overview`,
    ExternalCallControl: `${cucmPath}/external-call-control`,
  })),
  Zoom: {
    Index: '/zoom',
    Import: {
      Index: '/zoom/import',
      Management: '/zoom/import/management',
      NextSteps: '/zoom/import/next-steps',
    },
  },
};

export default Routes;
