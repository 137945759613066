import { AlertProps, Alert as RegistryAlert } from 'singlewire-components';
import styled from 'styled-components';
import { shared_t } from '../SharedLocale';

const AlertContainer = styled.div`
  flex: 1;
  color: #000000;
`;

const getTitleFromType = (title?: string, type?: string) => {
  switch (type) {
    case 'primary':
      return shared_t(['alert', 'infoDefaultTitle']);
    case 'warning':
      return shared_t(['alert', 'warningDefaultTitle']);
    case 'error':
      return shared_t(['alert', 'errorDefaultTitle']);
    case 'success':
      return shared_t(['alert', 'successDefaultTitle']);
    default:
      return title || shared_t(['alert', 'infoDefaultTitle']);
  }
};

export const Alert = ({
  useDefaultTitle = true,
  ...props
}: AlertProps & { useDefaultTitle?: boolean }) => {
  return (
    <AlertContainer>
      <RegistryAlert
        {...props}
        title={
          props.title
            ? props.title
            : useDefaultTitle
              ? getTitleFromType(props.title, props.color)
              : undefined
        }
        role={props.role || 'alert'}
      />
    </AlertContainer>
  );
};
