import { AxiosResponse } from 'axios';
import find from 'lodash/find';
import first from 'lodash/first';
import forEach from 'lodash/forEach';
import groupBy from 'lodash/groupBy';
import includes from 'lodash/includes';
import { Store } from 'redux';
import { BehaviorSubject, from, Observable, of as observableOf, ReplaySubject } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { ApiPermission, ApiRequest, ApiRequestMethod, ApiResource } from '../../common-types';
import { PROVIDER_KEY_ROTATION } from '../../constants';
import { addPermission } from '../permissions/PermissionsActions';
import { RootState } from '../RootReducer';
import apiClient from './api-client';
import { buildApiPathFromResource, getResourceAndValidateRequest } from './api-resources';
import { isFeatureSupported } from './icop-server';
import log from './log';

export interface PendingApiPermission {
  spec: string;
  specRegex: RegExp;
  // ReplaySubject(1) is the same as a BehaviorSubject, but saves the consumers
  // from having to deal with an initial null-state
  allowedVerbs$: ReplaySubject<ApiRequestMethod[]>;
}

// This is not serializable and so we won't store it in the store.
export const pendingPermissionChecks$ = new BehaviorSubject<PendingApiPermission[]>([]);

/**
 * Converts an api spec/url into a regular expression to make finding wildcard matches easier.
 * @param {string} spec the spec
 * @returns {RegExp} the matchable regular expression
 */
export function specToRegex(spec: string): RegExp {
  return new RegExp(
    '^' +
      spec
        .replace(/\/\*\*$/, '/.*') // Replace /** with any characters
        .replace(/\/\*/g, '/[^/]+') +
      '$', // Replace /* with any character up until the next / // Until the end of the string
    'i'
  ); // Case insensitive
}

export function clearPendingPermissionChecks() {
  forEach(pendingPermissionChecks$.getValue(), (permission: PendingApiPermission) =>
    permission.allowedVerbs$.complete()
  );
  pendingPermissionChecks$.next([]);
}

/**
 * Given an ApiRequest, check to see if we have permissions to access the resource. This will,
 * if not cached, issue an HTTP Options request to the server, analyze the Allow header, and
 * determine if it matches the desired ApiRequest. Returns an Observable that will resolve a
 * boolean indicating whether we have permissions. There is no need to manually unsubscribe as
 * the Observable is completed once the result has been determined.
 * @param {ApiRequest} request the request
 * @param {StateObservable<RootState>} store the current state
 * @returns {Observable<boolean>} the observable indicating whether we have permissions or not
 */
export function hasPermission(request: ApiRequest, store: Store<RootState>): Observable<boolean> {
  const state = store.getState();
  const session = state.session;
  const unlocked = state.permissions.unlocked;
  const currentPermissions = state.permissions.permissions;
  const selectedIcopServerId = state.icopServers.selectedICOPServerId;
  const [, pathParams, requestMethod] = request;
  const resource: ApiResource = getResourceAndValidateRequest(request);

  // Validate the request
  if (!session) {
    log.warn("Can't check permission as we are unauthorized.", request, resource);
    return observableOf(false);
  }

  // Build our api path/permission spec
  const apiPath = buildApiPathFromResource(resource, pathParams, selectedIcopServerId);
  log.debug('Fetching permission for path', apiPath);

  // Filter our existing permissions into exact and wildcard matches
  const matches = groupBy(currentPermissions, ({ spec, specRegex }: ApiPermission) => {
    if (spec === apiPath) {
      return 'exact';
    } else if (apiPath.match(specRegex)) {
      return 'wildcard';
    } else {
      return 'none';
    }
  });

  // If we have an exact match, just return a resolved Observable with the result
  const exactMatch: ApiPermission = first(matches.exact)!;
  if (exactMatch) {
    log.debug('Found exact permission match', exactMatch);
    return observableOf(includes(exactMatch.verbs, requestMethod));
  }

  // If there is an exact match but pending, return an observable that will resolve the permission
  const pendingExactMatch = pendingPermissionChecks$
    .getValue()
    .find(permission => permission.spec === apiPath);
  if (pendingExactMatch) {
    log.debug('Found pending exact permission match', pendingExactMatch);
    return pendingExactMatch.allowedVerbs$.pipe(
      map((verbs: ApiRequestMethod[]) => includes(verbs, requestMethod))
    );
  }

  // If we have a *resolved* and *true* wildcard match, return that a *true* resolved observable.
  // We do this because it's possible to fail a wildcard match, but pass an exact match, however, if the
  // wildcard match returns true, then we have permissions so we can skip the specific check.
  const resolvedWildcard = find(matches.wildcard, (permission: ApiPermission) =>
    includes(permission.verbs, requestMethod)
  ) as ApiPermission;
  if (resolvedWildcard) {
    log.debug('Found resolved wildcard permission match', resolvedWildcard);
    return observableOf(true);
  }

  if (unlocked) {
    // We haven't been able to find an existing match. Issue an API OPTIONS request to the server to
    // get permissions for the spec and return the appropriate Observable that will resolve once the
    // permission is resolved.
    const pendingApiPermission: PendingApiPermission = {
      spec: apiPath,
      specRegex: specToRegex(apiPath),
      allowedVerbs$: new ReplaySubject(1),
    };
    pendingPermissionChecks$.next(
      pendingPermissionChecks$.getValue().concat([pendingApiPermission])
    );

    from(apiClient.request<any>({ method: 'options', url: apiPath, timeout: 10000 }))
      .pipe(
        // we pass the result of the map, allowedVerbs, on to the subscribe
        map((res: AxiosResponse<any>) => res.headers.allow.split(',')),
        // finalize doesn't get called until the source terminates on complete or error
        finalize(() => pendingApiPermission.allowedVerbs$.complete())
      )
      .subscribe(
        // the list of allowed verbs for the request's apiPath
        (allowedVerbs: ApiRequestMethod[]) => {
          pendingApiPermission.allowedVerbs$.next(allowedVerbs);
          pendingPermissionChecks$.next(
            pendingPermissionChecks$.getValue().filter(x => x !== pendingApiPermission)
          );
          store.dispatch(
            addPermission({
              spec: pendingApiPermission.spec,
              specRegex: pendingApiPermission.specRegex,
              verbs: allowedVerbs,
            })
          );
        },
        (error: any) => {
          log.error('Permissions check failed', error);
          pendingApiPermission.allowedVerbs$.error(error);
          pendingPermissionChecks$.next(
            pendingPermissionChecks$.getValue().filter(x => x !== pendingApiPermission)
          );
        }
      );
    return pendingApiPermission.allowedVerbs$.pipe(
      map((verbs: ApiRequestMethod[]) => includes(verbs, requestMethod))
    );
  } else {
    return observableOf(false);
  }
}

export const isArrayOfPermissions = (
  perms: ApiRequest | ReadonlyArray<ApiRequest>
): perms is ReadonlyArray<ApiRequest> => Array.isArray(perms[0]);

export const providerKeyRotationFeatureEnabledForOneServer = (state: RootState) =>
  !!state.icopServers?.servers?.find((server: any) =>
    isFeatureSupported(PROVIDER_KEY_ROTATION, server)
  );
// TODO - add tests
