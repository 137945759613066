import { registerResource } from '../../core/utils/localization';

export const ARIALocale = {
  en: {
    button: {
      add: 'Add {{name}} {{resource}}',
      edit: 'Edit {{name}} {{resource}}',
      details: 'View {{name}} {{resource}}',
      clear: 'Clear {{name}} {{resource}}',
      copy: 'Copy {{name}} {{resource}}',
      delete: 'Delete {{name}} {{resource}}',
      remove: 'Remove {{name}} {{resource}}',
      initiate: 'Initiate {{name}} {{resource}}',
      actions: '{{name}} {{resource}} Actions',
      end: 'End {{name}} {{resource}}',
      manageSiteAccess: 'Manage site access for {{name}} {{resource}}',
      recipients: 'View Recipients',
      members: 'View {{name}} {{resource}} Members',
      toggle: 'Toggle {{name}} {{resource}}',
      reassign: 'Reassign {{fromResource}} to {{name}} {{toResource}}',
      assign: 'Assign {{name}} to {{toResource}}',
      learnMore: 'Learn More: {{label}}',
    },

    image: {
      thumbnail: 'Thumbnail preview of uploaded image',
    },

    notification: {
      send: 'Send {{name}} notification',
      details: 'View notification details for {{name}} sent {{time}}',
      followUp: 'Send follow-up for {{name}} {{resource}} sent {{time}}',
      viewResponses: 'View responses for {{name}} {{resource}} sent {{time}}',
      viewConferenceCall: 'View conference calls for {{name}} {{resource}} sent {{time}}',
      expire: 'Expire {{name}} notification sent at {{time}}',
      cancel: 'Cancel {{name}} notification sent at {{time}}',
    },
    recipient: {
      details: 'View details for recipient {{name}}',
      reviewDeviceDetails: 'Review Device Details for {{name}}',
      reviewOnPremDeviceDetails: 'View on-premises device details for {{name}}',
      followUp: 'Send follow-up to {{name}}',
    },
    field: {
      clear: '{{name}} cleared successfully.',
    },
  },
};

export default ARIALocale;
export const aria_t = registerResource<typeof ARIALocale.en>('aria', ARIALocale);
