import { Box, Icon, Loading } from 'singlewire-components';
import { SpacingSize } from 'singlewire-components/dist/components/Box/Box';
import { common_t } from '../../../CommonLocale';
import { StickyElementMode, StickyWrapper } from '../StickyWrapper';
import { CancelButton, LinkButton, SaveButton } from './Button';

export interface StickyFormButtonProps {
  mode?: StickyElementMode;
  cancelLink?: string;
  cancelButtonText?: string;
  submitting: boolean;
  submitButtonText?: string;
  disableSubmitButton?: boolean;
  cancelClickAction?: () => void;
  floatDirection?: 'right' | 'left';
  hideIcon?: boolean;
  mr?: SpacingSize;
  children?: React.ReactNode;
}

export const FormButtons: React.FC<StickyFormButtonProps> = ({
  cancelLink,
  cancelButtonText,
  submitButtonText,
  submitting,
  disableSubmitButton,
  cancelClickAction,
  floatDirection = 'right',
  children,
  mr,
}) => (
  <Box mr={mr || 'md'} align={floatDirection === 'right' ? 'end' : 'start'}>
    {cancelLink && (
      <Box mr="sm">
        <LinkButton
          label={cancelButtonText ? cancelButtonText : common_t(['button', 'cancel'])}
          id="cancel-button"
          onClick={cancelClickAction}
          variant="outlined"
          color="primary"
          startIcon={<Icon.Cancel />}
          to={cancelLink}
          role="button"
        />
      </Box>
    )}
    {cancelClickAction && !cancelLink && (
      <Box mr="sm">
        <CancelButton
          label={cancelButtonText ? cancelButtonText : common_t(['button', 'cancel'])}
          id="cancel-button"
          onClick={cancelClickAction}
          variant="outlined"
          color="primary"
        />
      </Box>
    )}
    {!!children && <Box mr="sm">{children}</Box>}

    <SaveButton
      startIcon={submitting && <Loading size="sm" color="inherit" />}
      label={submitButtonText ? submitButtonText : common_t(['button', 'save'])}
      id="save-button"
      type="submit"
      color="primary"
      variant="contained"
      loading={submitting}
      disabled={disableSubmitButton || submitting}
    />
  </Box>
);

export const StickyFormButtons: React.FC<StickyFormButtonProps> = ({ mode, ...props }) => (
  <StickyWrapper mode={mode || 'form-buttons'}>
    <FormButtons {...props} />
  </StickyWrapper>
);
