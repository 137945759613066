import map from 'lodash/map';
import { WrappedFieldProps } from 'redux-form';
import { shared_t } from '../../SharedLocale';
import { RefObject } from 'react';

interface ValidationError {
  [errorName: string]: any;
}

const NO_ERRORS = {};

export const getErrors = ({ meta }: Partial<WrappedFieldProps>): ValidationError => {
  if (meta?.error && meta?.submitFailed) {
    return meta?.error;
  } else {
    return NO_ERRORS;
  }
};

export const parseErrors = (errors: ValidationError): string[] => {
  return map(
    errors,
    (error: any, errorName: string) =>
      (error && error.message) || shared_t(['message', 'validator', 'invalid'])
  );
};

/**
 * Fixes an tab navigation w/ this component when embedded in a modal.
 *
 * See https://github.com/JedWatson/react-select/issues/5882#issuecomment-2040592347
 * This is a workaround for issue linked.
 * Mostly just a cut and paste except added some guardrails around disabled component.
 * Note: This requires a `formRef` to work properly.
 * `formRef` needs to be a ref of a component that wraps at least the
 *           next and previous tabbable elements for this to work.
 * @param event
 */
export const onKeyDownReactSelectWorkaround = (
  event: React.KeyboardEvent,
  formRef: RefObject<HTMLElement> | undefined
) => {
  if (event.key === 'Enter') {
    // Fix for CL-2499: Hitting Enter on un-expanded select fields should expand them, not submit the form
    // Note that this technically overrides the already-working Enter key press on an expanded select field to select an option, but Space does the same thing
    event.key = ' '; // event.key = "Space" does NOT work here, has to be the space character
  }

  if (event.key !== 'Tab' || !formRef) return;

  event.preventDefault();
  // Get all focusable elements within the modal
  const focusableElements: any = formRef.current?.querySelectorAll(
    // Only change from what was copied was adding `:not(:disabled)`
    'button:not(:disabled), [href], input:not(:disabled), select:not(:disabled), textarea:not(:disabled), [tabindex]:not([tabindex="-1"]):not(:disabled)'
  );
  const firstFocusableElement = focusableElements?.[0];
  const lastFocusableElement = focusableElements?.[focusableElements.length - 1];

  // If the shift key is pressed and the first element is focused, move focus to the last element
  if (event.shiftKey && document.activeElement === firstFocusableElement) {
    lastFocusableElement?.focus();
    return;
  }

  // If the shift key is not pressed and the last element is focused, move focus to the first element
  if (!event.shiftKey && document.activeElement === lastFocusableElement) {
    firstFocusableElement?.focus();
    return;
  }

  // Otherwise, move focus to the next element
  const direction = event.shiftKey ? -1 : 1;
  const index = Array.prototype.indexOf.call(focusableElements, document.activeElement);
  const nextElement = focusableElements?.[index + direction];
  if (nextElement) {
    nextElement?.focus();
  }
};
