import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import {
  GeoJSONPolygon,
  GeoJsonPoint,
  MobileApiSiteMappedDevice,
  MobileApiLocationObject,
  MobileApiSite,
  MobileApiSiteBuilding,
  MobileApiSiteBuildingFloor,
  MobileApiSiteBuildingFloorZone,
  TupleLngLat,
} from '../../mobile-api-types';
import { sites_t } from '../features/command-center/sites/SitesLocale';
import { LatLng } from '../features/command-center/sites/SiteUtils';
import makeCircle from '@turf/circle';
import {
  polygon as makePolygon,
  point as makePoint,
  Properties,
  Feature,
  Polygon,
} from '@turf/helpers';
import center from '@turf/center';
import explode from '@turf/explode';
import mask from '@turf/mask';
import nearestPoint from '@turf/nearest-point';

/** This file exists to not break lazy loading of views/features/command-center/sites for things that needs sites-y stuff but don't need to load ALL of sites */

/** Build the description string of a site like Site Name (Building: Building Name, Floor: Floor Name, Room: Room Name) */

export const buildDescriptionString = (
  site?: Pick<MobileApiSite, 'name' | 'id'>,
  building?: Pick<MobileApiSiteBuilding, 'name' | 'id'>,
  floor?: Pick<MobileApiSiteBuildingFloor, 'name' | 'id'>,
  zone?: Pick<MobileApiSiteBuildingFloorZone, 'name' | 'id'>
) => buildDescriptionStringFromNames(site?.name, building?.name, floor?.name, zone?.name);

export const buildDescriptionStringFromNames = (
  siteName?: string,
  buildingName?: string,
  floorName?: string,
  zoneName?: string
) => {
  let desc = '';
  if (siteName) {
    desc += siteName;
  }

  if (buildingName) {
    desc += ` (Building: ${buildingName}`;
    if (floorName) {
      desc += `, Floor: ${floorName}`;
      if (zoneName) {
        desc += `, Room: ${zoneName}`;
      }
    }
    desc += ')';
  }

  return desc;
};
/**
 * Use this with an API request that has "includeParentNames: true"
 * Given a possibly-undefined site, building, floor, or zone, try to get a location object with only names and IDs from the 'lowest level possible'
 * That means it tries to get the zone ID, name and all the parent names and IDs. If the zone is undefined, it tries to get the floor name, ID, and all parent IDs, and so on.
 */

export const getLocationObjectFromLowestLevelPossible = (
  site?: MobileApiSite,
  building?: MobileApiSiteBuilding,
  floor?: MobileApiSiteBuildingFloor,
  zone?: MobileApiSiteBuildingFloorZone
): MobileApiLocationObject | undefined => {
  const siteName = (zone ?? floor ?? building)?.siteName || site?.name;
  const buildingName = (zone ?? floor)?.buildingName || building?.name;
  const floorName = zone?.floorName || floor?.name;
  const zoneName = zone?.name;

  const siteId = (zone ?? floor ?? building)?.siteId || site?.id;
  const buildingId = (zone ?? floor)?.buildingId || building?.id;
  const floorId = zone?.floorId || floor?.id;
  const zoneId = zone?.id;

  type sbfzBasics = { name?: string; id: string };
  const location: {
    site?: sbfzBasics;
    building?: sbfzBasics;
    floor?: sbfzBasics;
    zone?: sbfzBasics;
  } = {};

  if (siteId) {
    location['site'] = { name: siteName, id: siteId };
  }

  if (buildingId) {
    location['building'] = { name: buildingName, id: buildingId };
  }

  if (floorId) {
    location['floor'] = { name: floorName, id: floorId };
  }

  if (zoneId) {
    location['zone'] = { name: zoneName, id: zoneId };
  }

  if (Object.keys(location).length === 0) {
    return undefined;
  }
  return location as MobileApiLocationObject;
};

export const detectDevicesChangingAssignmentWithBoundaryUpdate = ({
  devices,
  oldBoundary,
  newBoundary,
}: {
  devices: MobileApiSiteMappedDevice[];
  oldBoundary?: GeoJSONPolygon | null;
  newBoundary?: GeoJSONPolygon | null;
}) => {
  if (!(oldBoundary && newBoundary)) {
    return {
      devicesJoining: [],
      devicesLeaving: [],
    };
  }
  const pinnedDevices = devices.filter(d => !!d.pinnedLocation);
  return {
    devicesJoining: pinnedDevices
      .filter(d => !booleanPointInPolygon(d.pinnedLocation!.coordinates, oldBoundary!))
      .filter(d => booleanPointInPolygon(d.pinnedLocation!.coordinates, newBoundary!)),
    devicesLeaving: pinnedDevices
      .filter(d => booleanPointInPolygon(d.pinnedLocation!.coordinates, oldBoundary!))
      .filter(d => !booleanPointInPolygon(d.pinnedLocation!.coordinates, newBoundary!)),
  };
};

export const joinDeviceNames = (devices: MobileApiSiteMappedDevice[]) => {
  switch (devices.length) {
    case 0:
      return '';
    case 1:
      return devices[0].name;
    case 2:
      return devices[0].name.concat(' and ', devices[1].name);
    default:
      return devices
        .slice(0, -1)
        .map(d => d.name)
        .join(', ')
        .concat(', and ', devices.slice(-1)[0].name);
  }
};

export const composeZoneBoundaryConfirmation = ({
  zone,
  devicesJoining,
  devicesLeaving,
}: {
  zone: MobileApiSiteBuildingFloorZone;
  devicesJoining: MobileApiSiteMappedDevice[];
  devicesLeaving: MobileApiSiteMappedDevice[];
}) => {
  const message = !(devicesJoining.length + devicesLeaving.length)
    ? null
    : [
        sites_t(['request', 'siteZoneBoundaryBeingAdjusted'], {
          zoneName: zone.name,
        }),
        ...(devicesJoining.length
          ? [
              sites_t(
                [
                  'request',
                  devicesJoining.length > 1
                    ? 'siteZoneBoundaryNowIncludes'
                    : 'siteZoneBoundaryNowIncludesSingular',
                ],
                {
                  deviceNames: joinDeviceNames(devicesJoining),
                }
              ),
            ]
          : []),
        ...(devicesLeaving.length
          ? [
              sites_t(
                [
                  'request',
                  devicesLeaving.length > 1
                    ? 'siteZoneBoundaryNowExcludes'
                    : 'siteZoneBoundaryNowExcludesSingular',
                ],
                {
                  deviceNames: joinDeviceNames(devicesLeaving),
                }
              ),
            ]
          : []),
      ].join(' ');
  return !!message
    ? { message, title: sites_t(['request', 'siteZoneBoundaryChangeAffectsDevices']) }
    : undefined;
};

export const makeLatLng = (latLng: AnyLatLng): LatLng => {
  if (Array.isArray(latLng)) {
    // per GeoJSON RFC 7946 sec 3.1.1, a position includes lng before lat.
    // https://www.rfc-editor.org/rfc/rfc7946#section-3.1.1
    const [lng, lat] = latLng;
    return { lat, lng };
  }

  if (isGeoJsonPoint(latLng)) {
    return makeLatLng({ lat: latLng.coordinates[1], lng: latLng.coordinates[0] });
  } else if (typeof latLng?.lat === 'function' && typeof latLng?.lng === 'function') {
    return { lat: latLng.lat(), lng: latLng.lng() };
  } else if (typeof latLng?.lat === 'number' && typeof latLng?.lng === 'number') {
    return { lat: latLng.lat, lng: latLng.lng };
  } else {
    // ?!
    throw new Error(
      `Provided latLng is not typeof AnyLatLng. latLng type: ${JSON.stringify(latLng)}`
    );
  }
};

type AnyLatLng = LatLng | TupleLngLat | google.maps.LatLng | GeoJsonPoint;

export const isGeoJsonPoint = (latLng: AnyLatLng): latLng is GeoJsonPoint =>
  typeof latLng === 'object' &&
  (latLng as GeoJsonPoint).type === 'Point' &&
  (latLng as GeoJsonPoint).coordinates?.length === 2;

export const makeTupleLngLat = (latLng: AnyLatLng): TupleLngLat => {
  const { lng, lat } = makeLatLng(latLng);
  return [lng, lat];
};

export const getCenterOfBoundary = (boundary: GeoJSONPolygon | undefined) => {
  if (!boundary) {
    return undefined;
  }

  return center(boundary).geometry as GeoJsonPoint;
};

export const getClosestVertex = (boundary: GeoJSONPolygon, point: GeoJsonPoint) => {
  const pointsInBoundary = explode(boundary);
  return nearestPoint(point, pointsInBoundary).geometry as GeoJsonPoint;
};

export const getCenterOfBoundaryOrFallbackToClosestVertex = (boundary: GeoJSONPolygon) => {
  let center = getCenterOfBoundary(boundary);
  if (center && boundary) {
    if (!booleanPointInPolygon(center, boundary)) {
      // Weird floor boundary shapes can make the center of the boundary not actual within it because math
      // This sets the start point to the vertex closest to the center we calculated. Should be good enough.
      center = getClosestVertex(boundary, center);
    }
  }

  return center ? makeLatLng(center) : center;
};

export const makeGeoJSONPolygonFromMapsPolygonPath = (boundaryPath: google.maps.LatLng[]) =>
  boundaryPath?.length
    ? {
        type: 'Polygon' as const,
        // because Google Maps Polygons are not closed paths but GeoJSON
        // Polygons are, repeat the first coordinate to close the path
        coordinates: boundaryPath?.length
          ? [[...boundaryPath.map(makeTupleLngLat), makeTupleLngLat(boundaryPath[0])]]
          : [[]],
      }
    : undefined;

/**
 * Given a Google Maps reference, attempts to find the first focusable HTMLElement
 * that "contains" ONLY the map and no other control.
 *
 * This is needed so we can register keyboard event listeners on the correct element.
 * Registering keyboard handlers on `map.getDiv()` means our event listeners fire
 * even when focus is on the links inside of Google Maps, such as "About Us"
 * and "Report a Map error". The latter is undesirable, and this is our away of
 * preventing that.
 *
 * This function is prone to breaking on Google Maps version upgrades, so make
 * sure to regression test this.
 */
export const getGoogleMapsKeyboardHandler = (map: google.maps.Map) => {
  const mapDiv = map.getDiv();
  if (mapDiv.getAttribute('tabindex') !== '0') {
    mapDiv.setAttribute('tabindex', '0');
    removeDefaultTabbableWrapperFromGoogleMap(map);
  }
  return map.getDiv();
};

export const removeDefaultTabbableWrapperFromGoogleMap = (map: google.maps.Map) => {
  let attempts = 0;

  const attemptRemoval = () => {
    const div = map.getDiv().querySelector('[tabindex="0"]');
    if (div) {
      div.setAttribute('tabindex', '');
    } else if (attempts < 20) {
      attempts++;
      setTimeout(attemptRemoval, 0);
    } else {
      console.warn('Max number of retries attempted to improve google maps keyboard accessbility.');
    }
  };

  attemptRemoval();
};

export const pointIsOutsideCircle = (
  circle:
    | {
        center: google.maps.LatLngLiteral;
        radius: number;
      }
    | undefined,
  point: google.maps.LatLng
) => {
  if (point && circle) {
    const { lng: circleLng, lat: circleLat } = circle.center;
    const geoJsonCircle = makeCircle([circleLng, circleLat], circle.radius, {
      units: 'meters',
    });

    return !booleanPointInPolygon(makeTurfPoint(point), geoJsonCircle);
  }

  return false;
};

export const pointIsOutsidePolygon = (
  polygon: Feature<Polygon, Properties>,
  point: google.maps.LatLng
) => {
  return !booleanPointInPolygon(makeTurfPoint(point), polygon);
};

/**
 * Given a Google Maps polygon, constructs a polygon that can be consumed by
 * Turf APIs.
 */
export const makeTurfPolygon = (polygon: google.maps.LatLngLiteral[]) =>
  makePolygon([polygon.map(makeTupleLngLat)]);

/**
 * Given a Google Maps point, constructs a point that can be consumed by
 * Turf APIs.
 */
export const makeTurfPoint = (point: google.maps.LatLng) => makePoint([point.lng(), point.lat()]);

/**
 * Given an initial polygon and a collection of polygons defining
 * desired holes, constructs a Turf polygon that has those holes punched out.
 *
 * More precisely, suppose that polygon A is our starting polygon, and
 * polygon B is the complement of the union of all the holes. The return
 * value will be the intersection of polygons A and B.
 */
export const makeHolePunchedTurfPolygon = (
  polygon: google.maps.LatLngLiteral[],
  holes: google.maps.LatLngLiteral[][]
) => {
  let polygonToPunch = makeTurfPolygon(polygon);
  for (const hole of holes) {
    polygonToPunch = mask(makeTurfPolygon(hole), polygonToPunch, {
      mutate: true,
    });
  }
  return polygonToPunch;
};
