import moment from 'moment';
import { useCallback, useMemo } from 'react';
import {
  Box,
  Divider,
  Icon,
  Popover,
  StaticDatePicker,
  StaticDateRangePicker,
  TriggerButton,
} from 'singlewire-components';
import styled from 'styled-components';
import { common_t } from '../../../CommonLocale';
import { formatDateString } from '../../../core/utils/formatters';
import { useLiveRegion } from '../hooks/useLiveRegion';
import { shared_t } from '../SharedLocale';
import { Button } from './Button';

export const FORM_ID = 'date-filter-form';

const FormContainer = styled.div`
  min-width: 300px;
  padding: 0.25rem;
`;

interface ExternalProps {
  isSelectingAll: boolean;
  onChangeDateFilter: (start?: Date, end?: Date) => void;
  options?: { attribute: string; range?: boolean; disableLastYear?: boolean; label?: string };
  formValues?: { start?: Date; end?: Date };
}

type LocationFilterFormProps = ExternalProps;

export const DateFilterForm = ({
  isSelectingAll,
  onChangeDateFilter,
  options,
  formValues,
}: LocationFilterFormProps) => {
  const { updateLiveRegion, Component } = useLiveRegion();

  const clearDateFilter = useCallback(() => {
    onChangeDateFilter(undefined, undefined);
  }, [onChangeDateFilter]);

  const onChangeDateRange = useCallback(
    (value: any, selected?: Date) => {
      // Once the end date is selected
      if (options?.range) {
        const start = moment(value?.from).startOf('day').toDate();
        const end = moment(value?.to).endOf('day').toDate();

        if (!value) {
          const selectedStart = moment(selected).startOf('day').toDate();
          const selectedEnd = moment(selected).endOf('day').toDate();
          onChangeDateFilter(selectedStart, selectedEnd);

          updateLiveRegion(
            shared_t(['table', 'dateRange'], {
              start: formatDateString(selectedStart, 'medium'),
              end: formatDateString(selectedEnd, 'medium'),
            })
          );
        }

        if (value?.from) {
          onChangeDateFilter(start, undefined);

          updateLiveRegion(
            shared_t(['table', 'date'], {
              date: formatDateString(start, 'medium'),
            })
          );
        }

        if (value?.to) {
          onChangeDateFilter(start, end);
          updateLiveRegion(
            shared_t(['table', 'dateRange'], {
              start: formatDateString(start, 'medium'),
              end: formatDateString(end, 'medium'),
            })
          );
        }
      } else {
        const start = moment(value).startOf('day').toDate();

        if (start) {
          onChangeDateFilter(start, undefined);
          updateLiveRegion(
            shared_t(['table', 'date'], {
              date: formatDateString(start, 'medium'),
            })
          );
        }
      }
    },
    [onChangeDateFilter, updateLiveRegion, options]
  );

  const getTriggerLabel = useMemo(() => {
    if (formValues?.start && formValues?.end && options?.range) {
      return `${moment(formValues?.start).format('MM/DD/YY')} - ${moment(formValues?.end).format(
        'MM/DD/YY'
      )}`;
    } else if (formValues?.start && !options?.range) {
      return moment(formValues?.start).format('MM/DD/YY');
    }

    return undefined;
  }, [formValues?.start, formValues?.end, options]);

  const onClickPreset = useCallback(
    (preset: 'day' | 'month' | 'year') => () => {
      let start = moment().startOf('day').toDate();
      const end = moment().endOf('day').toDate();

      if (preset === 'month') {
        start = moment().subtract('month', 1).toDate();
      }

      if (preset === 'year') {
        start = moment().subtract('year', 1).toDate();
      }

      onChangeDateFilter(start, end);
    },
    [onChangeDateFilter]
  );

  return (
    <Box>
      {Component}
      <Popover
        id="date-filter-popover"
        fixedMaxHeight={false}
        trigger={() => (
          <TriggerButton
            id="date-filter-button"
            label={options?.label || common_t(['label', 'date'])}
            value={getTriggerLabel}
            startIcon={<Icon.Calendar size="sm" />}
            disabled={isSelectingAll}
            variant="default"
            dynamicSizing
          />
        )}
        align="start"
        actionButtonProps={{
          label: common_t(['button', 'clearSelection']),
          onClick: clearDateFilter,
        }}>
        <FormContainer>
          <Box m="sm" spacing={2}>
            <Button id="date-today" label="Today" size="sm" onClick={onClickPreset('day')} />
            <Button id="date-month" label="Past Month" size="sm" onClick={onClickPreset('month')} />
            <Button id="date-year" label="Past Year" size="sm" onClick={onClickPreset('year')} />
          </Box>
        </FormContainer>
        <Divider my="none" />
        <FormContainer>
          {options?.range ? (
            <StaticDateRangePicker
              value={{ from: formValues?.start, to: formValues?.end }}
              onChange={onChangeDateRange}
              disablePast={false}
              disableFuture={true}
              disableMatcher={
                options.disableLastYear
                  ? { before: moment().subtract('year', 1).toDate() }
                  : undefined
              }
            />
          ) : (
            <StaticDatePicker
              value={formValues?.start}
              onChange={onChangeDateRange}
              disablePast={false}
              disableFuture={true}
            />
          )}
        </FormContainer>
      </Popover>
    </Box>
  );
};
