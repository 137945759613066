// This container manages the display of everything in the topbar.

import truncate from 'lodash/truncate';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getAllDomains } from '../../core/domains/DomainsActions';
import { getAllFacilities } from '../../core/facilities/FacilitiesActions';
import {
  closeSidebar,
  startNotificationsPolling,
  stopNotificationsPolling,
  toggleResourceCenter,
  toggleRightSidebarType,
  toggleSidebar,
} from '../../core/layout/LayoutActions';
import { makeReq } from '../../core/network/NetworkActions';
import { checkPermission } from '../../core/permissions/PermissionsActions';
import { hasCachedPermission } from '../../core/permissions/PermissionsSelectors';
import { RootState } from '../../core/RootReducer';
import { askUserToLogOut, sendUserToIdp } from '../../core/session/SessionActions';
import { isFeatureEnabled } from '../../core/utils/session';
import { getLink } from '../shared/HelpIcon';
import { CREATE_NOTIFICATIONS, Header, VIEW_MESSAGE_TEMPLATES, VIEW_NOTIFICATIONS } from './Header';

interface ExternalProps {
  isOverlay?: boolean;
}

export type HeaderProps = ExternalProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const mapStateToProps = (state: RootState) => ({
  helpLink: getLink(state.session),
  supportCommunityLink: isFeatureEnabled('fusion', state.session)
    ? 'https://support.singlewire.com/s/informacast-fusion'
    : 'https://support.singlewire.com/s/informacast-mobile',
  hireExpertLink:
    'https://www.singlewire.com/professional-services-request?utm_source=ic&utm_medium=other_campaigns&utm_campaign=20241014_prof-services-request_form%20_cust_sw_ic_prof-serv',
  signedInUserId: (state.session && state.session.userId) || '',
  signedInUserName: truncate((state.session && state.session.name) || ''),
  sidebarToggleLeft: state.layout.leftSidebarOpened,
  sidebarToggleRight: state.layout.rightSidebarOpened,
  sidebarRightType: state.layout.rightSidebarType,
  viewMessageTemplates: hasCachedPermission(state, VIEW_MESSAGE_TEMPLATES),
  createNotifications: hasCachedPermission(state, CREATE_NOTIFICATIONS),
  viewNotifications: hasCachedPermission(state, VIEW_NOTIFICATIONS),
  brandingLoaded: !state.branding.fetchingBranding,
  hasActiveNotification: state.layout.hasActiveNotification,
  isResourceCenterEnabled: isFeatureEnabled('resourceCenter', state.session),
  showResourceCenter: state.layout.showResourceCenter,
  isDomainsEnabled: isFeatureEnabled('domains', state.session),
  isFacilitiesEnabled:
    isFeatureEnabled('facilitiesEnabled', state.session) &&
    !isFeatureEnabled('domains', state.session),
});

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      startNotificationsPolling,
      stopNotificationsPolling,
      askUserToLogOut,
      closeSidebar,
      toggleSidebar,
      toggleRightSidebarType,
      checkPermission,
      toggleResourceCenter,
      makeReq,
      sendUserToIdp,
      getAllFacilities,
      getAllDomains,
    },
    dispatch
  );

export const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(Header);
