import { connect } from 'react-redux';
import { RootState } from '../core/RootReducer';
import { initialize, initializePendo } from '../core/initialized/InitializedActions';
import { closeSidebar } from '../core/layout/LayoutActions';
import { isFeatureEnabled } from '../core/utils/session';
import { App } from './App';

export type AppProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

export const mapStateToProps = (state: RootState) => ({
  isLoggedIn: !!state?.session,
  initialized: state.initialized,
  leftSidebarOpened: state.layout.leftSidebarOpened,
  useComponentRegistryUI: isFeatureEnabled('uiComponentRegistry', state.session),
  webexCallingEnabled: isFeatureEnabled('webexCalling', state.session),
  ...state.branding,
});

export const mapDispatchToProps = { initialize, initializePendo, closeSidebar };

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);
