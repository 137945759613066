import classNames from 'classnames';
import { PureComponent, RefObject } from 'react';
import { Link } from 'react-router-dom';
import {
  Badge,
  Box,
  COLORS,
  Icon,
  IconButton,
  TableRow,
  Tooltip,
  Typography,
} from 'singlewire-components';
import styled from 'styled-components';
import { common_t } from '../../CommonLocale';
import { GLOBAL_FACILITY_ID } from '../../constants';
import { RightSidebarType } from '../../core/layout/LayoutActions';
import Routes from '../../core/route/Routes';
import { MobileApiMessageTemplate } from '../../mobile-api-types';
import { TablePager } from '../shared/components/TablePager';
import { FlexPageControls } from '../shared/SearchPager/FlexPageControls';
import { SearchPaginationControlsProps } from '../shared/SearchPager/SearchPaginationControls';
import { layout_t } from './LayoutLocale';
import { QuickSendProps } from './QuickSendContainer';

export const PAGER_ID = 'list-message-templates-quick-send';
export const GLOBAL_PAGER_ID = 'list-global-message-templates-quick-send';

interface QuickSendListItemProps {
  messageTemplate: MobileApiMessageTemplate;
  toggleRightSidebarType: () => void;
}

const H2 = styled.h2`
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin: 0px;
  white-space: pre-line;
`;

const QuickSendClose = styled(IconButton)`
  margin-top: -0.5rem !important;
  margin-right: -0.5rem !important;
`;

const ListContainer = styled.ul`
  height: calc(100vh - 300px);
  overflow-y: auto;
  min-height: 12rem;
`;

const ItemContainer = styled.li`
  border-radius: 0.25rem;
  padding-top: 2px;
  padding-bottom: 2px;

  &:hover {
    background-color: ${COLORS.GRAY_5};
  }

  &:focus-within {
    background-color: ${COLORS.GRAY_5};
    outline: 2px solid ${COLORS.WHITE};
    box-shadow: inset 0 0 0 2px ${COLORS.BLUE_60};
  }
`;

export const QuickSendListItem = ({
  messageTemplate,
  toggleRightSidebarType,
}: QuickSendListItemProps) => {
  const shouldShowAttachments =
    messageTemplate.image > 0 ||
    messageTemplate.audio > 0 ||
    !!messageTemplate.confirmationRequestId ||
    !!messageTemplate.incidentPlanId;

  return (
    <ItemContainer>
      <Link
        to={`${Routes.Notifications.Send}?messageTemplateId=${messageTemplate.id}`}
        onClick={toggleRightSidebarType}
        aria-describedby={`hover-card-${messageTemplate.id}`}>
        <Box flex={false} m="sm">
          <Typography variant="body">{messageTemplate.name}</Typography>
          {shouldShowAttachments && (
            <Box mt="xs">
              <Typography variant="body" srOnly>
                {common_t(['label', 'includes'])}
              </Typography>
              {messageTemplate.image > 0 && (
                <Box mr="xs">
                  <Tooltip title={common_t(['label', 'image'])}>
                    <Badge variant="outline">
                      <Icon.Image size="sm" />
                      <Typography variant="body" srOnly>
                        {common_t(['label', 'image'])}
                      </Typography>
                    </Badge>
                  </Tooltip>
                </Box>
              )}
              {messageTemplate.audio > 0 && (
                <Box mr="xs">
                  <Tooltip title={common_t(['label', 'audio'])}>
                    <Badge variant="outline">
                      <Icon.Microphone size="sm" />
                      <Typography variant="body" srOnly>
                        {common_t(['label', 'audio'])}
                      </Typography>
                    </Badge>
                  </Tooltip>
                </Box>
              )}
              {!!messageTemplate.confirmationRequestId && (
                <Box mr="xs">
                  <Tooltip title={common_t(['resource', 'confirmationRequest'])}>
                    <Badge variant="outline">
                      <Icon.ConfirmationRequest size="sm" />
                      <Typography variant="body" srOnly>
                        {common_t(['resource', 'confirmationRequest'])}
                      </Typography>
                    </Badge>
                  </Tooltip>
                </Box>
              )}
              {!!messageTemplate.incidentPlanId && (
                <Box mr="xs">
                  <Tooltip title={common_t(['resource', 'incidentPlan'])}>
                    <Badge variant="outline">
                      <Icon.IncidentPlan size="sm" />
                      <Typography variant="body" srOnly>
                        {common_t(['resource', 'incidentPlan'])}
                      </Typography>
                    </Badge>
                  </Tooltip>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Link>
    </ItemContainer>
  );
};

export class CustomControls extends PureComponent<SearchPaginationControlsProps> {
  componentDidMount() {
    this.props.onLimitChanged(10, { keepGlobalLimit: true });
  }

  render() {
    return (
      <TableRow border={false}>
        <Box id="quick-send-nav-row" flex={false}>
          <FlexPageControls {...this.props} />
        </Box>
      </TableRow>
    );
  }
}

export interface SearchBarComponentProps {
  onSearchQueryChanged: (q: string) => void;
  searchInputRef: RefObject<HTMLInputElement>;
  defaultValue?: string;
  maxSearchLength?: number;
}

export class QuickSend extends PureComponent<QuickSendProps> {
  onClose = () => {
    this.props.toggleRightSidebarType(RightSidebarType.NONE);
    setTimeout(() => {
      document.getElementById('quick-send-trigger')?.focus();
    }, 0);
  };

  render() {
    const {
      rightSidebarOpened,
      rightSidebarType,
      toggleRightSidebarType,
      facilitiesEnabled,
      actingFacilityId,
    } = this.props;

    const useGlobalPager = facilitiesEnabled && actingFacilityId === GLOBAL_FACILITY_ID;

    return (
      <>
        {rightSidebarType === RightSidebarType.SEND && (
          <aside
            id="quick-send"
            className={classNames('sidebar c-overflow', { toggled: rightSidebarOpened })}>
            <div className="listview" id="quickSendItemList">
              <Box align="between" alignItems="center" mx="md" mt="sm">
                <H2>{common_t(['tooltips', 'sendNotification'])}</H2>
                <QuickSendClose
                  id="close-quick-send"
                  label={layout_t(['quickSend', 'closeSend'])}
                  color="gray"
                  icon={<Icon.Cancel />}
                  onClick={this.onClose}
                  disableRadixTooltip
                />
              </Box>

              <Box m="md" flex={false}>
                <TablePager
                  pagerId={useGlobalPager ? GLOBAL_PAGER_ID : PAGER_ID}
                  request={[
                    'messageTemplates',
                    [],
                    'GET',
                    {
                      params: {
                        q: 'follow-up:false',
                        'include-notification-profile': true,
                        ...(useGlobalPager ? { facilityId: actingFacilityId } : {}),
                      },
                    },
                  ]}
                  caption={layout_t(['quickSend', 'caption'])}
                  paginationType="compact"
                  emptyStateProps={{
                    id: 'empty-message-templates-state',
                    title: layout_t(['quickSend', 'emptyTable']),
                  }}>
                  {({ data }) => (
                    <ListContainer>
                      {data?.map(template => (
                        <QuickSendListItem
                          key={template.id}
                          messageTemplate={template}
                          toggleRightSidebarType={() =>
                            toggleRightSidebarType(RightSidebarType.NONE)
                          }
                        />
                      ))}
                    </ListContainer>
                  )}
                </TablePager>
              </Box>
            </div>
          </aside>
        )}
      </>
    );
  }
}
