import { registerResource } from '../../../core/utils/localization';

export const NotificationsLocale = {
  en: {
    recipientLabel: {
      anonymousUser: 'Anonymous User {{name}}',
      desktopNotifier: 'Desktop Notifier {{deviceUser}} {{speaker}}',
      m2m: 'M2M {{name}}',
      pagingGateway: 'Paging Gateway {{name}}',
      quickUrl: 'Quick URL {{name}}',
    },
    activities: {
      caption: 'All recipient actions in relation to the notification you sent.',
      empty: 'No notification activities',
      apiConnectorResult: {
        success: 'Webhook Succeeded',
        failure: 'Webhook Failed',
        skipped: 'Webhook Skipped',
      },
      clickHere: 'Click here',
      queryNote:
        'Showing only activities of type "{{failureType}}". Listed activities may not match your delivery results ' +
        'statistics due to success/failure discrepancies, e.g. a notification is successfully sent, but fails at the device ' +
        "level as a bounced email, phone's busy signal, or a disconnected SMS number.",
      reason: {
        optedOut: 'User opted out on {{date}}',
        unknown: 'Unknown reason: "{{reason}}"',
        deviceDisabled: 'is disabled',
        deviceNotVerified: 'is not verified',
      },
      toShowAllActivities: 'to show all activities.',
      export: 'Export Activities',
      activityType: 'Activity Type',
      confirmationModalTitle: 'Download All Activities',
      confirmationModalBody: 'Click the Download button to download a CSV file of all activities.',
      loading: 'Gathering activities...',
      feedDisabled: ' feed is disabled.',
      type: {
        // This kebab case so there doesn't have to be a very repetitive case statement for user friendly text
        abort: 'Abort',
        'api-connector-result': 'API Connector Result',
        'call-answered': 'Call Answered',
        'call-completed': 'Call Completed',
        'call-failed': 'Call Failed',
        'calling-statistics': 'Calling Statistics',
        'call-placed': 'Call Placed',
        'call-queued': 'Call Queued',
        'call-skipped': 'Call Skipped',
        'callaware-recording': 'CallAware Call Recording',
        'calls-placed': 'Calls Placed',
        'calls-succeeded': 'Calls Succeeded',
        'calls-Total': 'Calls Total',
        'cisco-spark': 'Webex',
        'cisco-spark-failed': 'Webex Failed',
        'cisco-spark-skipped': 'Webex Skipped',
        'conference-call-failed': 'Conference Call Failed',
        'conference-call-joined': 'Conference Call Joined',
        'conference-call-left': 'Conference Call Left',
        'conference-call-skipped': 'Conference Call Skipped',
        confirmation: 'Confirmation',
        'device-skipped': 'Device Skipped',
        'email-failed': 'Email Failed',
        'email-queued': 'Email Queued',
        'email-sent': 'Email Sent',
        'email-succeeded': 'Email Succeeded',
        'email-statistics': 'Email Statistics',
        expire: 'Expire',
        'msteams-call-failed': 'Microsoft Teams Call Failed',
        'msteams-call-invite-failed': 'Microsoft Teams Invite Failed',
        'msteams-call-invite-succeeded': 'Microsoft Teams Invite Succeeded',
        'msteams-call-placed': 'Microsoft Teams Call Placed',
        'msteams-call-skipped': 'Microsoft Teams Call Skipped',
        'msteams-chat-failed': 'Microsoft Teams Chat Failed',
        'msteams-chat-sent': 'Microsoft Teams Chat Sent',
        'msteams-chat-skipped': 'Microsoft Teams Chat Skipped',
        'msteams-channel-failed': 'Microsoft Teams Channel Failed',
        'msteams-channel-sent': 'Microsoft Teams Channel Sent',
        'msteams-channel-skipped': 'Microsoft Teams Channel Skipped',
        'msteams-group-failed': 'Microsoft Teams Outbound System Failed',
        'msteams-group-disabled': 'Microsoft Teams Outbound System Disabled',
        'twitter-sent': 'Twitter Status Updated',
        'twitter-failed': 'Twitter Status Update Failed',
        'twitter-skipped': 'Twitter Skipped',
        'tracking-completed': 'Tracking Completed',
        'tracking-failed': 'Tracking Failed',
        'tracking-started': 'Tracking Started',
        'tracking-terminated': 'Tracking Terminated',
        'outbound-cap-failed-post': 'Outbound CAP Attempt Failed',
        'outbound-cap-skipped': 'Outbound CAP Skipped',
        'outbound-rss-failed': 'Outbound RSS Failed',
        'outbound-rss-feed-disabled': 'Outbound RSS Feed Disabled',
        'outbound-rss-feed-failed': 'Outbound RSS Feed Failed',
        'outbound-rss-feed-updated': 'Outbound RSS Feed Updated',
        'outbound-rss-skipped': 'Outbound RSS Skipped',
        'push-failed': 'Push Failed',
        'push-queued': 'Push Queued',
        'push-sent': 'Push Sent',
        'push-statistics': 'Push Statistics',
        unread: 'Unread',
        read: 'Read',
        'recipient-skipped': 'Recipient Skipped',
        'set-read-status': 'Set Read Status',
        'sms-failed': 'SMS Failed',
        'sms-queued': 'SMS Queued',
        'sms-received': 'SMS Received',
        'sms-response': 'SMS Response',
        'sms-responses': 'SMS Responses',
        'sms-sent': 'SMS Sent',
        'sms-indeterminate': 'SMS Indeterminate',
        'sms-statistics': 'SMS Statistics',
        'sms-succeeded': 'SMS Succeeded',
        'sms-total': 'SMS Total',
        'update-read-status': 'Update Read Status',
        'broadcast-summary': 'Broadcast Summary',
        'broadcast-finished': 'Broadcast Finished',
        'broadcast-started': 'Broadcast Started',
        'broadcast-failed': 'Broadcast Failed',
        'sending-finished': 'Sending Finished',
        'sending-initiated': 'Sending Initiated',
        'display-text': 'Display Text',
        'display-text-and-start-audio': 'Display Text and Start Audio',
        'stop-audio': 'Stop Audio',
        'escalation-rule-skipped': 'Escalation Rule Skip',
        info: 'Info',
        'inbound-cap-sent': 'Inbound CAP Sent',
        'inbound-cap-failed': 'Inbound CAP Failed',
        'inbound-email-sent': 'Inbound Email Sent',
        'inbound-email-failed': 'Inbound Email Failed',
        'inbound-rss-sent': 'Inbound RSS Sent',
        'inbound-rss-failed': 'Inbound RSS Failed',
        'init-services': 'Init Services',
        'start-audio': 'Start Audio',
        skip: 'Skip',
        'twitter-tweet-truncated': 'Twitter Tweet Truncated',
        'twitter-tweet-split': 'Twitter Tweet Split',
        'twitter-image-over-limit': 'Twitter Image Over Limit',
        'twitter-tweet-duplicate': 'Twitter Tweet Duplicated',
      },
    },
    actionResults: {
      statuses: {
        pending: 'Pending',
        failure: 'Failure',
        success: 'Success',
        skipped: 'Skipped',
      },
      showWebhookActivities: 'Show Activities',
      actionType: {
        webhook: 'Webhook',
      },
      tableColumns: {
        scenario: 'Scenario',
        action: 'API Connector',
        type: 'Type',
        status: 'Status',
        createdAt: 'Created At',
        response: 'Responses',
      },
      modal: {
        title: 'Webhook Responses',
        subtitle: 'Data',
        hide: 'Hide',
      },
    },
    allRecipientsNote:
      'A collection of all endpoints that received the notification sent to the recipient types listed above.',
    bodyDefault: '<message contains no body>',
    button: {
      expire: 'Expire',
      followUp: 'Follow-up',
      hideAttachments: 'Hide Attachments',
      showAttachments: 'Show Attachments',
      showLess: 'Show Less',
      showMore: 'Show More',
      downloadAudio: 'Download Audio',
      downloadImage: 'Download Image',
    },
    cancelOrExpire: {
      abort: {
        subtitle:
          'You have chosen to cancel this notification, which will attempt to stop any queued and/or active notifications, decline further confirmation responses from users, and prevent escalation rules from triggering other notifications. Please confirm your cancellation, and optionally include a comment on the reason for the cancellation.',
        title: 'Cancel Notification',
      },
      expire: {
        subtitle:
          'You have chosen to expire this notification, which will decline further confirmation responses from users and prevent escalation rules from triggering other notifications.  Please confirm the expiration, and optionally include a comment on the reason for the expiration.',
        title: 'Expire Notification',
      },
    },
    cancelOrUpdateSuccess: {
      canceled: 'canceled',
      expired: 'expired',
      message: 'Successfully {{type}} the notification.',
    },
    channels: {
      all: {
        title: 'All',
      },
      calling: {
        title: 'Calling',
      },
      email: {
        title: 'Email',
      },
      push: {
        title: 'App for Android/iOS',
      },
      desktopDevices: {
        title: 'App for Mac/Windows',
      },
      sms: {
        title: 'SMS',
      },
      msteams: {
        title: 'Microsoft Teams',
      },
      fusion: {
        title: 'On-Premises Devices',
      },
      idns: {
        title: 'Desktop Notifier',
      },
      speakers: {
        title: 'Speakers',
      },
      ipPhones: {
        title: 'Cisco IP Phones for Unified CM',
      },
      noChannelsSelectedAlertTitle: 'No Channels Selected',
      noChannelsSelectedAlertBody:
        "Notifications will only appear in a Users' InformaCast notifications area.",
    },
    conferenceCallInfo__HTML__:
      '{{name}} Conference Call: To join {{name}}, please dial <a href="tel:{{number}}" style="color:#1fe1ff">{{number}}</a> and when prompted, enter {{joinPin}}.',
    confirmationResponse: {
      loading: 'Gathering Confrimation Responses...',
      confirmationModalTitle: 'Download All Confirmation Responses',
      confirmationModalBody:
        'Click the Download button to download a CSV file of all confirmation responses.',
      groupedTitle: 'Confirmation Response by Option',
      individualTitle: 'Confirmation Response by User',
      allRecipientsExplanation:
        '*Count includes all notification recipients, even those incapable of responding to confirmation requests.',
      numberOfResponse: 'Number of Response',
      responseCounts: 'Response Counts',
      followUpWithAll: 'Follow Up with All',
      followUpWith: 'Follow Up with ',
      followUpWithNoResponse: 'Follow Up with those who have not responded',
      downloadResponses: 'Download All Responses',
      dynamicReply: {
        anyoneText__HTML__:
          'Anyone who responds with {{response}}, will receive a reply of {{reply}}',
        firstText__HTML__:
          'The first {{quota}} response of {{response}}, will receive a reply of {{reply}}',
        firstText__HTML___plural:
          'The first {{quota}} responses of {{response}}, will receive a reply of {{reply}}',
        restText__HTML__:
          'Everyone else who responds with {{response}}, will receive a reply of {{reply}}',
      },
      dynamicReplyActivity: {
        anyoneText: {
          title: 'Anyone who responded with {{response}} and received a reply of {{reply}}',
        },
        firstText: {
          title: 'The first {{quota}} who responded {{response}} and received a reply of {{reply}}',
        },
        restText: {
          title: 'Everyone else who responded {{response}} and received a reply of {{reply}}',
        },
      },
    },
    create: 'Compose and send a new notification.',
    details:
      'View the details of the notification, its delivery results, the confirmation responses, ' +
      'the number of recipients who have read your notification, and their activity in relation to the notification.',
    timeline: {
      title: 'Notification Timeline',
      subtitle: 'View the progress of this notification through the sending process.',
      sentAt: 'Sent: {{time}}',
      cancelledAt: 'Cancelled: {{time}}',
      expiredAt: 'Expired: {{time}}',
      updatedAt: 'Updated: {{time}}',
      trigger: 'Notification Timeline',
    },
    emergencyCalling: {
      'open-dialer': 'Keypad opened to dial 911',
      'contacting-emergency-services': '911 contact initiated',
      'none-need-emergency-building': 'Scenario initiated without contacting 911',
    },
    label: {
      all: 'All',
      allRecipients: 'All Recipients',
      aoiRecipientsMap: 'Area of Interest Recipients Map',
      aoiRecipientsMapSubtitle: 'Click on an area of interest to view its recipients.',
      attempted: 'Attempted',
      sentSuccessfullyYAxis: '% Notifications Sent Successfully',
      canceledAt: 'Canceled At',
      channel: 'Channel',
      channelSelect:
        "For supported recipients, select the channels through which you'll send your notification.",
      selectAllThatApply: '(Select all that apply)',
      displaySendLabel: 'Which recipients would you like to reach with this notification?',
      comment: 'Comment',
      count: 'Count',
      customTtsText: 'Custom Text-to-Speech Content',
      confirmationOption: 'ConfirmationOption',
      confirmationResponseTimestamp: 'ConfirmationResponseTimestamp',
      details: 'Details',
      expire: 'Expire',
      emergencyCalling: 'Emergency Calling',
      failed: 'Failed',
      initiatedBy: 'Initiated By',
      messageTemplate: 'Message Template',
      scheduledNotificationHistory: 'Scheduled Notification History',
      notificationId: 'Notification ID',
      allRecipientsNoResponse: "All recipients who haven't responded",
      allRecipientsNoResponseThisNotification:
        "All recipients who haven't responded to this notification",
      noResponse: 'No Response',
      notificationSubject: 'notification subject',
      percentNotificationSent: 'Percent Notification Sent',
      recipients: 'Recipients',
      readStatus: 'Read Status',
      recipientEmail: 'Recipient Email',
      recipientName: 'Recipient Name',
      recipientSummary: 'Recipient Summary',
      refresh: 'Refresh',
      response: 'Response',
      sent: 'Sent',
      selectTemplate: 'Select a message template to send.',
      targetTotal: 'Target Total',
      total: 'Total',
      sendingProgress: '{{percent}}% ({{sent}} Sent/{{total}} Total)',
      totalSentFailed: 'Total Attempted',
      notificationContent: 'Notification Content',
      notificationSummary: 'Notification Summary',
      noContent: 'No Content',
      noneSelected: 'None Selected',
      noUsableMessageTemplatesFoundDomains:
        'No usable message templates exist in this domain. Please create a message template or assign a message template to this domain before attempting to send a notification.',
      noUsableMessageTemplatesFound:
        'No usable message templates found. Please create a message template before attempting to send a notification.',
      spaceId: 'Space Id',
      spark: {
        invite: 'Invite',
        post: 'Post',
        'create-space': 'Create Space',
        'command-received': 'Command Received',
        'space-creation-failed': 'Space Creating Failed',
        'posting-to-space-failed': 'Posting to Space Failed',
        'invite-failed': 'Invite Failed',
        'not-applicable': 'Not Applicable',
        ciscoSpark: '(Action: {{value}}, Space Id: {{spaceId}})',
        ciscoSparkContent: '(Action: {{value}}, Space Id: {{spaceId}}, Content: {{content}})',
        ciscoSparkFailed:
          '(Reason: {{value}}, Code: {{errorCode}}, Space Id: {{spaceId}}, Message: {{errorMessage}})',
      },
      affectedTemplates:
        '<0>This will affect </0><2>{{numTemplate}}</2> <0>message template(s).</0> <1>View affected templates.</1>',
      viewSummary: 'Review',
      via: ' via ',
    },
    list: 'Lists the notifications that have been sent to your recipients.',
    noReportsPermission:
      'Notification statistics unavailable because you do not have permission to view reports.',
    errorLoadingReport:
      'There was a problem loading the report. If problems continue, contact your system administrator.',
    percentSentTooltip: {
      fifty: "Total time required to send 50% of the channel's notifications",
      nintyFive: "Total time required to send 95% of the channel's notifications",
      oneHundred: "Total time required to send 100% of the channel's notifications",
    },
    sendNotification: 'Send Notification',
    sendFollowUpNotification: 'Send Follow-up Notification',
    sendStatusLastUpdated: 'Last Updated: {{lastUpdated}}',
    trackingInformationLink:
      'Click <0>here</0> to view tracking event information about this group.',
    createSuccess: 'Successfully sent notification',
    errorCodes: {
      deviceRateLimited: 'Device rate limited',
      apnsDeviceInvalid: 'Invalid APNS device identifier',
      apnsDeviceUnregistered: 'APNS device is no longer registered',
      busy: 'Busy',
      extensionFailed:
        'Problem communicating with the subsystem that sends this type of notification',
      failed: 'Failed',
      invalidMobileNumber:
        'Not a valid mobile phone number. This is most likely a landline number.',
      invalidPhoneNumber: 'Invalid phone number',
      noAnswer: 'No answer',
      retryCountExceeded: 'Retry count exceeded',
      smsFromBellSchedulesProhibited: 'SMS devices cannot be used with Bell Schedules.',
      undelivered: 'Undelivered',
      userRepliedStop: 'Message cannot be sent because the user has replied with STOP',
      noLocalNumber: 'No local outbound calling number provisioned for making calls.',
    },
    viaCodes: {
      android: 'Android FCM',
      apns: 'Apple Push Notification Service',
      apnsSandbox: 'Apple Push Notification Service (sandbox environment)',
      apnsQa: 'Apple Push Notification Service (QA sandbox environment)',
      msteams: 'Microsoft Teams Service',
      wns: 'Windows Push Notification Service',
    },
    msteamsCallId: 'Call ID: {id}',
    followUpWithAllTrackedUsers: 'All {{trackingEvent}} Tracking Event Users',
    // This kebab case so there doesn't have to be a very repetitive case statement for user friendly text
    cards: {
      initiatedBy: 'Initiated By',
      willBeSentBy: 'Will Be Sent By',
      notSent: 'Not Sent',
      updateWhenSent: 'Will update when notification is sent.',
      recipientsNotified: '{{num}} Recipients Notified',
      potentialRecipientsNotified: '{{num}} Potential Recipients',
      criticalAlert: 'Critical Alert',
      server: 'Server',
      updated: 'Updated {{date}} at {{time}}',
      ciscoIpPhones: 'Cisco IP Phones for Unified CM',
      oauthServerApplication: 'Server Application',
    },
    scenarioSection: {
      standard: 'Standard Scenario',
      panicButton: 'Panic Button Scenario',
      integration: 'Integration Scenario',
      header: 'Scenario Content',
      responses: 'Responses',
      noAnswerProvided: 'No Answer Provided',
      location: 'Location',
    },
    statistics: {
      title: 'Send Status',
      viewActivities: 'View Activities',
      failed: ' Failed',
      sent: 'Sent',
      issueDetected: 'Potential Issue',
      possibleIssueDetected: 'Possible Issue Detected',
      troubleshootIssue:
        'InformaCast detected a potential issue with this recipient type. The Activities tab has more details to assist you in troubleshooting this issue.',
      sending: 'Sending...',
      thousand: 'k',
      disabled: 'Disabled',
      disabledOnSend: 'Channel Disabled on Send',
      noRecipients:
        'No enabled notification recipients. Click the <0>Activities</0> tab for details.',
      noChannelSelected: 'No channel selected. Select a channel to view its details.',
      missingPermissions:
        'You are missing view permissions for some channels. Data on this page may not be complete.',
    },
    initiationModal: {
      title: 'Initiation Details',
      subtitle: 'View all information associated with the initiator for this notification.',
      initiationType: 'Initiation Type',
      initiationSourceID: 'Initiation Source ID',
      initiationSourceDescription: 'Initiation Source Description',
      initiatorID: 'Initiator ID',
      initiationBroadcast: 'Initiation Broadcast',
      initiationSourceClientID: 'Initiation Source Client ID',
    },
    content: {
      audio:
        'Use the audio included in your message template (if supplied) or add new audio (if allowed).',
      image:
        'Use the image included in your message template (if supplied) or add a new image (if allowed).',
      supportedImage: 'JPG, PNG, or GIF no larger than 5MB, max resolution 2048x2048',
      confirmation:
        'Use the confirmation request included in your message template (if supplied) or select a new one (if allowed).',
      incident:
        'Use the incident plan included in your message template (if supplied) or select a new one (if allowed).',
      subtitle:
        'Define the content to include in your notification, e.g. text, audio, image, confirmation response, incident plan, etc.',
    },
    allUsers: {
      title: 'All Users',
      subtitle: 'View all users who will potentially receive your notification.',
      subtitleReceived: 'View all users who received your notification.',
      download: 'Download Users',
    },
    advancedSettings: {
      title: 'Advanced Settings',
      subtitle: 'Your notification has the following advanced settings.',
      expireLabel: 'Notification will expire:',
      expiration: 'Expires in roughly {{expiration}} after the notification is sent.',
      absoluteExpiration: 'Notification will expire {{expiration}}.',
    },
    recipientDevices: {
      title: 'On-premises Devices',
      subtitle: 'View all on-premises devices that will potentially receive your notification.',
      subtitleReceived: 'View all on-premises devices that received your notification.',
      download: 'Download On-premises Devices',
    },
    alert: {
      staleNotificationAlertBody:
        'Your ability to send a notification based on these selections has expired. Too much time has passed, and the displayed recipients may no longer be valid.',
      staleNotificationAlertSubtitle:
        'Please return to the Send Notification page and click the Review button again, or send without reviewing.',
    },
    server: {
      title: 'On-premises Device Delivery Status by Server',
      broadcastSummary: 'Broadcast Summary',
      issue: 'Issue(s)',
      noIssue: 'No Issue',
      noDevices: 'No Devices',
      seconds: 'secs',
      avgActivation: 'Avg Activation',
      minActivation: 'Min Activation',
      maxActivation: 'Max Activation',
      downloadAll: 'Download All',
      downloadBroadCastSummary: 'Download {{serverName}} Broadcast Summary',
      failed:
        "Notification failed to reach one or more on-premises servers, and devices activated by those servers weren't notified. Click <0>here</0> for the list of on-premises servers.",
      modalTitle: 'Servers with Notification Failures',
      failedServers: 'failed servers',
    },
    summary: {
      title: 'Review Notification',
      subtitle:
        "Review your notification's potential recipients and its advanced settings (if applicable).",
      notificationLabel: 'Your notification will be sent via the following channels:',
      recipientLabel: 'To the devices belonging to the following recipients:',
      onDemandGroup: 'On-demand',
      device: '{{count}} Device Total',
      devices: '{{count}} Devices Total',
    },
    emptyTable: "You don't have any notifications.",
    emptyStatisticsTable: 'No notification statistics.',
    caption: {
      notifications: 'Notifications with links to further details and statistics.',
      scheduledNotifications: 'Scheduled notifications and the controls to manage them.',
      apiConnectors: 'All API connector activities in relation to the notification you sent.',
      statistics:
        'Activation times and delivery statuses for the on-premises devices that received your notification.',
    },
    aria: {
      downloadDevicesButton:
        'Download a CSV file summary of on-premises devices that received your notification.',
      downloadUsersButton:
        'Download a CSV file summary of user devices that received your notification.',
      downloadActivitiesButton:
        'Export a CSV file of all recipient actions in relation to the notification you sent.',
      toggleNotificationChannel: 'Toggle notification recipient channels',
      webhookActivities: 'Show {{name}} webhook activities',
      downloadAllDeliveryStatus:
        'Download a JSON file broadcast summary for delivery status of all on-premises devices.',
      issueDetected: 'Potential Issue for {{channel}}',
    },
  },
};

export const notifications_t = registerResource<typeof NotificationsLocale.en>(
  'notifications',
  NotificationsLocale
);

export default NotificationsLocale;
