// Handle state related to saving or deleting resources.

// Since forms are used throughout the application, we have created one central
// form handling service. Each individual form will wrap the commonDelete for delete
// and commonSave for create and edit.

import { AxiosError, AxiosResponse } from 'axios';
import { ReactNode } from 'react';
import { Action } from 'redux';
import { InitializeOptions } from 'redux-form';
import { createAction } from 'typesafe-actions';
import { ApiRequest } from '../../common-types';
import { MobileApiValidationError } from '../../mobile-api-types';
import { SearchPagerId } from '../search-pager/SearchPagerActions';

export interface CommonSaveOptions {
  stopReinitialize?: boolean;
  successMessage?: string;
  resourceName?: string;
  nameProp?: string;
  successActions?: (actions: AxiosResponse<any>) => Action[];
  failureActions?: (error: AxiosError | any) => Action[];
  errorOverride?: (error: any) => AxiosError;
  unmappedErrorsCustomActions?: (error: MobileApiValidationError) => Action | null;
}

export interface CommonSaveParams {
  subType: string;
  formId: string;
  formData: any;
  request: ApiRequest;
  isUpdate: boolean;
  options: CommonSaveOptions;
}

export interface CommonDeleteOptions {
  title: string;
  message: ReactNode;
  failureMessage: string;
  errorOverride?: (error: any) => string[];
  successMessage?: string;
  pagerId?: SearchPagerId;
  successActions?: (actions: AxiosResponse<any>) => Action[];
  failureActions?: (error: AxiosError | any) => Action[];
  textConfirm?: string;
  textConfirmLabel?: string;
  isDestructive?: boolean;
  deleteEntireSession?: boolean;
  triggeringElementId?: string;

  /** Label for the save action button on the modal */
  confirmTitle?: string;
}

export interface SilentDeleteOptions {
  pagerId?: SearchPagerId;
  successMessage?: string;
  failureMessage?: string;
  successActions?: (actions: AxiosResponse<any>) => Action[];
  failureActions?: (error: AxiosError | any) => Action[];
}

export interface CommonCloneOptions {
  successMessage: string;
  failureMessage: string;
  editRouteFn?: (id: string) => string;
  successActions?: (actions: AxiosResponse<any>) => Action[];
  failureActions?: (error: AxiosError | any) => Action[];
}

// Handled in: Epic
// Send an api request to delete a thing.
export const commonDelete = createAction(
  '[Form] Common Delete',
  (subType: string, request: ApiRequest, options: CommonDeleteOptions) => ({
    subType,
    request,
    options,
  })
)();

// Handled in: Epic
// Send an api request to delete a thing without confirmation.
export const silentDelete = createAction(
  '[Form] Silent Delete',
  (subType: string, request: ApiRequest, options: SilentDeleteOptions) => ({
    subType,
    request,
    options,
  })
)();

// Handled in: Epic
// Show a confirm modal to confirm before making api request, also handles returned errors
// This is the exact same action as commonDelete, just with a different name
export const confirmBeforeExecuting = createAction(
  '[Form] Confirm Before Executing',
  (subType: string, request: ApiRequest, options: CommonDeleteOptions) => ({
    subType,
    request,
    options,
  })
)();

// Handled in: Epic
// Send an api request to create or edit a thing.
export const commonSave = createAction(
  '[Form] Common Save',
  (commonSaveParams: CommonSaveParams) => ({ ...commonSaveParams })
)();

// Handled in: Epic
// Marks a given form by id as pristine
export const pristinify = createAction(
  '[Form] Pristinify',
  (formId: string, options?: Partial<InitializeOptions>) => ({ formId, options })
)();

// Handled in: Epic
// Send an api request to clone a thing.
export const commonClone = createAction(
  '[Form] Common Clone',
  (subType: string, request: ApiRequest, options: CommonCloneOptions) => ({
    subType,
    request,
    options,
  })
)();
