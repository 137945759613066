import { AxiosRequestConfig } from 'axios';
import compact from 'lodash/fp/compact';
import flatten from 'lodash/fp/flatten';
import flow from 'lodash/fp/flow';
import join from 'lodash/fp/join';
import zip from 'lodash/fp/zip';
import size from 'lodash/size';
import {
  ApiPathMissingIcopServerIdError,
  ApiRequest,
  ApiRequestParams,
  ApiResource,
} from '../../common-types';
import {
  ANDROID_EXTENSION_ID,
  ANONYMOUS_USER_REPORT_ID,
  APNS_DESKTOP_EXTENSION_ID,
  AUDIT_EVENTS_REPORT_ID,
  CISCO_SPARK_EXTENSION_ID,
  CONFIRMATION_REPORT_ID,
  DND_EXTENSION_ID,
  EMAIL_EXTENSION_ID,
  EVENT_SERVICE_EXTENSION_ID,
  ICOP_EXTENSION_ID,
  IDN_EXTENSION_ID,
  INBOUND_CAP_EXTENSION_ID,
  IOS_EXTENSION_ID,
  IPAWS_ADMIN_EXTENSION_ID,
  IPAWS_EXTENSION_ID,
  IP_CAMERAS_SERVICE_EXTENSION_ID,
  KONTAKTIO_WEARABLE_PANIC_BUTTON_EXTENSION_ID,
  NOTIFICATION_HISTORY_REPORT_ID,
  PHONE_CACHE_STATUS_REPORT_API_ID,
  PHONE_CALL_EXTENSION_ID,
  POLY_EXTENSION_ID,
  SMS_EXTENSION_ID,
  TEAMS_CHANNEL_EXTENSION_ID,
  TEAMS_CHAT_EXTENSION_ID,
  TWITTER_EXTENSION_ID,
  USERS_REPORT_ID,
  WEBEX_CALLING_EXTENSION_ID,
  WNS_EXTENSION_ID,
  ZOOM_EXTENSION_ID,
  ZOOM_SITES_EXTENSION_ID,
} from '../../constants';
import log from './log';
import { stringifyQueryParams } from './querystring';
import { typeAssert } from './typeUtils';

// ALL API RESOURCES (Please keep the keys sorted alphabetically to help find things.)
export const API_RESOURCES = {
  activationGroups: {
    path: ['/activation-groups'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  adminBroadcast: {
    path: ['/V1/Admin/Broadcast'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  adminDesktop: {
    path: ['/V1/Admin/Desktop'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  adminDesktopFormData: {
    path: ['/V1/Admin/Desktop/formData'],
    verbs: ['PUT'],
    isIcop: true,
  },
  adminDesktopLogo: {
    path: ['/V1/Admin/Desktop/logo'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  adminDialCastDialingConfigurations: {
    path: ['/V1/Admin/DialCast/dialingConfigurations'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  fusionAdminDialCastDialingConfigurations: {
    path: ['/Fusion/V1/Admin/DialCast/dialingConfigurations'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  dialcastDialingConfigurations: {
    path: ['/dialcast-dialing-configurations'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: false,
  },
  dialcastPhoneExceptions: {
    path: ['/dialcast-phone-exceptions'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: false,
  },
  adminDialCastPhoneExceptions: {
    path: ['/V1/Admin/DialCast/phoneExceptions'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  adminDialToIntercomConfiguration: {
    path: ['/V1/Admin/DialToIntercom/configuration'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: true,
  },
  adminIsFusionMode: {
    path: ['/V1/Admin/isFusionMode'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminLicensing: {
    path: ['/V1/Admin/licensing'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminLicensingFilters: {
    path: ['/V1/Admin/licensing/filters'],
    verbs: ['DELETE', 'GET', 'PUT'],
    isIcop: true,
  },
  adminLocalizationData: {
    path: ['/V1/Admin/localizationData'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminLogCallDetail: {
    path: ['/V1/Admin/logs/callDetail'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminLogCallDetailPaginated: {
    path: ['/V1/Admin/logs/callDetailPaginated'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminLogs: {
    path: ['/V1/Admin/logs'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminMessageCodes: {
    path: ['/V1/Admin/messageCodes'],
    verbs: ['GET'],
    isIcop: true,
  },
  fusionAdminNetwork: {
    path: ['/Fusion/V1/Admin/Network'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  adminNetwork: {
    path: ['/V1/Admin/Network'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  adminNetworkTimeouts: {
    path: ['/V1/Admin/Network/timeouts'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  adminOverview: {
    path: ['/V1/Admin/overview'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminSipAccess: {
    path: ['/V1/Admin/SIP/access'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminSipAccessAllow: {
    path: ['/V1/Admin/SIP/access/allow'],
    verbs: ['PUT'],
    isIcop: true,
  },
  adminSipAccessDeny: {
    path: ['/V1/Admin/SIP/access/deny'],
    verbs: ['PUT'],
    isIcop: true,
  },
  adminSipAccessDirectives: {
    path: ['/V1/Admin/SIP/access/directives'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  adminSipAccessRestore: {
    path: ['/V1/Admin/SIP/access/restore'],
    verbs: ['PUT'],
    isIcop: true,
  },
  adminSipAccounts: {
    path: ['/V1/Admin/SIP/accounts'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  adminSipAuthentication: {
    path: ['/V1/Admin/SIP/authentication'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  adminSipAuthenticationRestore: {
    path: ['/V1/Admin/SIP/authentication/restore'],
    verbs: ['PUT'],
    isIcop: true,
  },
  adminSipCertificates: {
    path: ['/V1/Admin/SIP/certificates'],
    verbs: ['DELETE', 'POST', 'GET'],
    isIcop: true,
  },
  adminSipCertificateView: {
    path: ['/V1/Admin/SIP/certificates/view'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminSipRegistrations: {
    path: ['/V1/Admin/SIP/registrations'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  adminSipRestart: {
    path: ['/V1/Admin/SIP/restart'],
    verbs: ['PUT'],
    isIcop: true,
  },
  adminSipServerGroups: {
    path: ['/V1/Admin/SIP/serverGroups'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  adminSipDnsSrvRecords: {
    path: ['/V1/Admin/SIP/dnsSrvOverview'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminSipStack: {
    path: ['/V1/Admin/SIP/stack'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  adminSipStackRestore: {
    path: ['/V1/Admin/SIP/stack/restore'],
    verbs: ['PUT'],
    isIcop: true,
  },
  adminSipUserCredentials: {
    path: ['/V1/Admin/SIP/userCredentials'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  adminSipSpeakerParameters: {
    path: ['/V1/Admin/Network/sipSpeakerParameters'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  adminSystemBackup: {
    path: ['/V1/Admin/System/backup'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  adminSystemBackupRun: {
    path: ['/V1/Admin/System/backup/run'],
    verbs: ['PUT'],
    isIcop: true,
  },
  adminSystemCallDetailConfig: {
    path: ['/V1/Admin/System/callDetailConfig'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  adminSystemClientCertificates: {
    path: ['/V1/Admin/System/clientCertificates'],
    verbs: ['DELETE', 'POST', 'GET'],
    isIcop: true,
  },
  adminSystemClientCertificateView: {
    path: ['/V1/Admin/System/clientCertificates/view'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminSystemDisableAutoImportingCertificates: {
    path: ['/V1/Admin/System/disableAutoImportingCertificates'],
    verbs: ['PUT'],
    isIcop: true,
  },
  adminSystemEnableAutoImportingCertificates: {
    path: ['/V1/Admin/System/enableAutoImportingCertificates'],
    verbs: ['PUT'],
    isIcop: true,
  },
  adminSystemHeapDump: {
    path: ['/V1/Admin/System/HeapDump'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminSystemIsAutoImportingCertificatesEnabled: {
    path: ['/V1/Admin/System/isAutoImportingCertificatesEnabled'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminSystemLdap: {
    path: ['/V1/Admin/System/ldap'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  adminSystemLdapIsLdapAuthenticationAllowed: {
    path: ['/V1/Admin/System/ldap/isLdapAuthenticationAllowed'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminSystemRecipientgroupAllClearPhones: {
    path: ['/V1/Admin/System/recipientgroups/all/clearPhones'],
    verbs: ['PUT'],
    isIcop: true,
  },
  adminSystemRecipientgroupClearPhones: {
    path: ['/V1/Admin/System/recipientgroups', '/clearPhones'],
    verbs: ['PUT'],
    isIcop: true,
  },
  fusionAdminSystemRecipientgroupClearPhones: {
    path: ['/Fusion/V1/Admin/System/recipientgroups', '/clearPhones'],
    verbs: ['PUT'],
    isIcop: true,
  },
  adminSystemReports: {
    path: ['/V1/Admin/System/reports'],
    verbs: ['POST', 'GET'],
    isIcop: true,
  },
  adminSystemScripting: {
    path: ['/V1/Admin/System/scripting'],
    verbs: ['DELETE', 'POST', 'GET'],
    isIcop: true,
  },
  adminSystemScriptingSamples: {
    path: ['/V1/Admin/System/scripting/samples'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminSystemScriptingView: {
    path: ['/V1/Admin/System/scripting/view'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminSystemServerCertificateDownload: {
    path: ['/V1/Admin/System/serverCertificate/download'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminSystemServerCertificateView: {
    path: ['/V1/Admin/System/serverCertificate/view'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminSystemThreadDump: {
    path: ['/V1/Admin/System/ThreadDump'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminTelephonyCucmClusterAxlRequest: {
    path: ['/V1/Admin/Telephony/cucmClusters', '/axlRequest'],
    verbs: ['POST'],
    isIcop: true,
  },
  adminTelephonyCucmClusterPrimary: {
    path: ['/V1/Admin/Telephony/cucmClusters/primary'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  adminTelephonyCucmClusters: {
    path: ['/V1/Admin/Telephony/cucmClusters'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  cucmClusters: {
    path: ['/cucm-clusters'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: false,
  },
  cucmFusionServers: {
    path: ['/cucm-clusters', '/fusion-servers'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: false,
  },
  setCucmFusionServerPhoneGatherer: {
    path: ['/cucm-clusters', '/fusion-servers', '/phone-gatherer'],
    verbs: ['POST'],
    isIcop: false,
  },
  cucmFusionServersTrustInfo: {
    path: [
      `/extensions/${ICOP_EXTENSION_ID}/endpoints`,
      `/proxy/proxy/V1/Admin/Telephony/cucmClusters`,
      `/trustInfo`,
    ],
    verbs: ['GET', 'PUT'],
    usesSelectedServer: false,
    isProxy: true,
  },
  fusionCucmFusionServersTrustInfo: {
    path: [
      `/extensions/${ICOP_EXTENSION_ID}/endpoints`,
      `/proxy/proxy/Fusion/V1/Admin/Telephony/cucmClusters`,
      `/trustInfo`,
    ],
    verbs: ['GET', 'PUT'],
    usesSelectedServer: false,
    isProxy: true,
  },
  cucmFusionServersIsAutoImportingCertificatesEnabled: {
    path: [
      `/extensions/${ICOP_EXTENSION_ID}/endpoints`,
      `/proxy/proxy/V1/Admin/System/isAutoImportingCertificatesEnabled`,
    ],
    verbs: ['GET'],
    usesSelectedServer: false,
    isProxy: true,
  },
  adminTelephonyCucmClusterTrustInfo: {
    path: ['/V1/Admin/Telephony/cucmClusters', '/trustInfo'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  adminTelephonyCucmExternalCallControlKeepalive: {
    path: ['/V1/Admin/Telephony/cucmExternalCallControl/keepalive'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminTelephonyCucmExternalCallControlSecureWebServiceUrLChooseAnotherToken: {
    path: ['/V1/Admin/Telephony/cucmExternalCallControl/secureWebServiceURLs/chooseAnotherToken'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminTelephonyCucmExternalCallControlSecureWebServiceUrLs: {
    path: ['/V1/Admin/Telephony/cucmExternalCallControl/secureWebServiceURLs'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminTelephonyCucmExternalCallControlWebServiceUrLChooseAnotherToken: {
    path: ['/V1/Admin/Telephony/cucmExternalCallControl/webServiceURLs/chooseAnotherToken'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminTelephonyCucmExternalCallControlWebServiceUrLs: {
    path: ['/V1/Admin/Telephony/cucmExternalCallControl/webServiceURLs'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminTelephonyCucmPhoneUpdates: {
    path: ['/V1/Admin/Telephony/cucmPhoneUpdates'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  adminTelephonyRefreshReport: {
    path: [`/reports/${PHONE_CACHE_STATUS_REPORT_API_ID}`],
    verbs: ['GET'],
    isIcop: false,
  },
  adminTelephonyGatherPhones: {
    path: ['/V1/Admin/Telephony/gatherPhones'],
    verbs: ['POST'],
    isIcop: true,
  },
  adminTelephonyLdapPhoneUpdates: {
    path: ['/V1/Admin/Telephony/ldapPhoneUpdates'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  adminTelephonyPhoneCache: {
    path: ['/V1/Admin/Telephony/phoneCache'],
    verbs: ['PATCH', 'PUT'],
    isIcop: true,
  },
  adminUserGroupFilterAllClear: {
    path: ['/V1/Admin/User/groups', '/filters/all/clear'],
    verbs: ['PUT'],
    isIcop: true,
  },
  adminUserGroupFilterClear: {
    path: ['/V1/Admin/User/groups', '/filters', '/clear'],
    verbs: ['PUT'],
    isIcop: true,
  },
  adminUserGroupFilters: {
    path: ['/V1/Admin/User/groups', '/filters'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  adminUserGroupLdapMemberUserGroups: {
    path: ['/V1/Admin/User/groups/ldap', '/memberUserGroups'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminUserGroupLdapParentUserGroups: {
    path: ['/V1/Admin/User/groups/ldap', '/parentUserGroups'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminUserGroupRoles: {
    path: ['/V1/Admin/User/groups', '/roles'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminUserGroups: {
    path: ['/V1/Admin/User/groups'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  adminUserGroupUsers: {
    path: ['/V1/Admin/User/groups', '/users'],
    verbs: ['DELETE', 'POST', 'GET'],
    isIcop: true,
  },
  adminUserRoleGroupAll: {
    path: ['/V1/Admin/User/roles', '/groups/all'],
    verbs: ['DELETE', 'POST'],
    isIcop: true,
  },
  adminUserRoleGroups: {
    path: ['/V1/Admin/User/roles', '/groups'],
    verbs: ['DELETE', 'POST', 'GET'],
    isIcop: true,
  },
  adminUserRoles: {
    path: ['/V1/Admin/User/roles'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminUserRoleUserAll: {
    path: ['/V1/Admin/User/roles', '/users/all'],
    verbs: ['DELETE', 'POST'],
    isIcop: true,
  },
  adminUserRoleUsers: {
    path: ['/V1/Admin/User/roles', '/users'],
    verbs: ['DELETE', 'POST', 'GET'],
    isIcop: true,
  },
  adminUserUserChangePassword: {
    path: ['/V1/Admin/User/users/changePassword'],
    verbs: ['PUT'],
    isIcop: true,
  },
  adminUserUserFilterAll: {
    path: ['/V1/Admin/User/users', '/filters/all'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminUserUserFilterAllClear: {
    path: ['/V1/Admin/User/users', '/filters/all/clear'],
    verbs: ['PUT'],
    isIcop: true,
  },
  adminUserUserFilterClear: {
    path: ['/V1/Admin/User/users', '/filters', '/clear'],
    verbs: ['PUT'],
    isIcop: true,
  },
  adminUserUserFilters: {
    path: ['/V1/Admin/User/users', '/filters'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  adminUserUserGroups: {
    path: ['/V1/Admin/User/users', '/groups'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminUserUserRoles: {
    path: ['/V1/Admin/User/users', '/roles'],
    verbs: ['GET'],
    isIcop: true,
  },
  adminUserUsers: {
    path: ['/V1/Admin/User/users'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  adminVoiceBroadcast: {
    path: ['/V1/Admin/Voice/Broadcast'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  adminVoiceBroadcastAudio: {
    path: ['/V1/Admin/Voice/Broadcast/Audio'],
    verbs: ['POST', 'GET', 'PUT'],
    isIcop: true,
  },
  adminVoiceBroadcastRestore: {
    path: ['/V1/Admin/Voice/Broadcast/restore'],
    verbs: ['PUT'],
    isIcop: true,
  },
  adminVoiceGeneral: {
    path: ['/V1/Admin/Voice/General'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  adminVoiceGeneralAudio: {
    path: ['/V1/Admin/Voice/General/Audio'],
    verbs: ['POST', 'GET', 'PUT'],
    isIcop: true,
  },
  adminVoiceGeneralRestore: {
    path: ['/V1/Admin/Voice/General/restore'],
    verbs: ['PUT'],
    isIcop: true,
  },
  adminVoiceInbound: {
    path: ['/V1/Admin/Voice/Inbound'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  adminVoiceInboundAudio: {
    path: ['/V1/Admin/Voice/Inbound/Audio'],
    verbs: ['POST', 'GET', 'PUT'],
    isIcop: true,
  },
  adminVoiceInboundRestore: {
    path: ['/V1/Admin/Voice/Inbound/restore'],
    verbs: ['PUT'],
    isIcop: true,
  },
  anonymousUsersReport: {
    path: [`/reports/${ANONYMOUS_USER_REPORT_ID}`],
    verbs: ['GET'],
    isIcop: false,
  },
  areasOfInterest: {
    path: ['/areas-of-interest'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  areasOfInterestBoundaryTriggerActivities: {
    path: ['/areas-of-interest', '/boundary-triggers', '/activities'],
    verbs: ['GET'],
    isIcop: false,
  },
  areasOfInterestBoundaryTriggers: {
    path: ['/areas-of-interest', '/boundary-triggers'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  auditEventsReport: {
    path: [`/reports/${AUDIT_EVENTS_REPORT_ID}`],
    verbs: ['GET'],
    isIcop: false,
  },
  basicAdminBroadcast: {
    path: ['/V1/BasicAdmin/Broadcast'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  basicAdminDialCastDialingConfigurations: {
    path: ['/V1/BasicAdmin/DialCast/dialingConfigurations'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  basicAdminLicensing: {
    path: ['/V1/BasicAdmin/licensing'],
    verbs: ['GET'],
    isIcop: true,
  },
  basicAdminLogCallDetail: {
    path: ['/V1/BasicAdmin/logs/callDetail'],
    verbs: ['GET'],
    isIcop: true,
  },
  basicAdminLogs: {
    path: ['/V1/BasicAdmin/logs'],
    verbs: ['GET'],
    isIcop: true,
  },
  basicAdminMessageCodes: {
    path: ['/V1/BasicAdmin/messageCodes'],
    verbs: ['GET'],
    isIcop: true,
  },
  basicAdminNetwork: {
    path: ['/V1/BasicAdmin/Network'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  basicAdminNetworkTimeouts: {
    path: ['/V1/BasicAdmin/Network/timeouts'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  basicAdminOverview: {
    path: ['/V1/BasicAdmin/overview'],
    verbs: ['GET'],
    isIcop: true,
  },
  basicAdminSipAccess: {
    path: ['/V1/BasicAdmin/SIP/access'],
    verbs: ['GET'],
    isIcop: true,
  },
  basicAdminSipAccessAllow: {
    path: ['/V1/BasicAdmin/SIP/access/allow'],
    verbs: ['PUT'],
    isIcop: true,
  },
  basicAdminSipAccessDeny: {
    path: ['/V1/BasicAdmin/SIP/access/deny'],
    verbs: ['PUT'],
    isIcop: true,
  },
  basicAdminSipAccessDirectives: {
    path: ['/V1/BasicAdmin/SIP/access/directives'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  basicAdminSipAccessRestore: {
    path: ['/V1/BasicAdmin/SIP/access/restore'],
    verbs: ['PUT'],
    isIcop: true,
  },
  basicAdminSipAuthentication: {
    path: ['/V1/BasicAdmin/SIP/authentication'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  basicAdminSipAuthenticationRestore: {
    path: ['/V1/BasicAdmin/SIP/authentication/restore'],
    verbs: ['PUT'],
    isIcop: true,
  },
  basicAdminSipCertificates: {
    path: ['/V1/BasicAdmin/SIP/certificates'],
    verbs: ['DELETE', 'POST', 'GET'],
    isIcop: true,
  },
  basicAdminSipCertificateView: {
    path: ['/V1/BasicAdmin/SIP/certificates/view'],
    verbs: ['GET'],
    isIcop: true,
  },
  basicAdminSipRestart: {
    path: ['/V1/BasicAdmin/SIP/restart'],
    verbs: ['PUT'],
    isIcop: true,
  },
  basicAdminSipStack: {
    path: ['/V1/BasicAdmin/SIP/stack'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  basicAdminSipStackRestore: {
    path: ['/V1/BasicAdmin/SIP/stack/restore'],
    verbs: ['PUT'],
    isIcop: true,
  },
  basicAdminSipUserCredentials: {
    path: ['/V1/BasicAdmin/SIP/userCredentials'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  basicAdminSystemBackup: {
    path: ['/V1/BasicAdmin/System/backup'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  basicAdminSystemBackupRun: {
    path: ['/V1/BasicAdmin/System/backup/run'],
    verbs: ['PUT'],
    isIcop: true,
  },
  basicAdminSystemCallDetailConfig: {
    path: ['/V1/BasicAdmin/System/callDetailConfig'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  basicAdminTelephonyCucmCluster: {
    path: ['/V1/BasicAdmin/Telephony/cucmCluster'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  basicAdminTelephonyCucmPhoneUpdates: {
    path: ['/V1/BasicAdmin/Telephony/cucmPhoneUpdates'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  basicAdminUserUserChangePassword: {
    path: ['/V1/BasicAdmin/User/users/changePassword'],
    verbs: ['PUT'],
    isIcop: true,
  },
  bellSchedulesBulkExceptions: {
    path: ['/bell-schedules-bulk-exceptions'],
    verbs: ['POST'],
    isIcop: false,
  },
  bellSchedules: {
    path: ['/bell-schedules'],
    verbs: ['GET', 'POST', 'PUT'],
    isIcop: false,
  },
  bellSchedulesClone: {
    path: ['/bell-schedules', '/clone'],
    verbs: ['POST'],
    isIcop: false,
  },
  bellSchedulesDomains: {
    path: ['/bell-schedules', '/domains'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  brandings: {
    path: ['/brandings'],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  brandingsLogo: {
    path: ['/brandings', '/logo'],
    verbs: ['GET'],
    isIcop: false,
  },
  ciscoWebexTeams: {
    path: ['/cisco-webex-teams'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  clearDevicesSchedules: {
    path: ['/clear-devices-schedules'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  collaborationGroups: {
    path: ['/collaboration-groups'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  collaborationGroupsDomains: {
    path: ['/collaboration-groups', '/domains'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  confirmation: {
    path: ['/V1/Confirmation'],
    verbs: ['GET'],
    isIcop: true,
  },
  confirmationReport: {
    path: [`/reports/${CONFIRMATION_REPORT_ID}`],
    verbs: ['GET'],
    isIcop: false,
  },
  confirmationRequestsDomains: {
    path: ['/confirmation-requests', '/domains'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  confirmationRequestEscalationRules: {
    path: ['/confirmation-requests', '/escalation-rules'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  confirmationRequestDynamicReplies: {
    path: ['/confirmation-requests', '/dynamic-replies'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  confirmationRequests: {
    path: ['/confirmation-requests'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  confirmationRequestsClone: {
    path: ['/confirmation-requests', '/clone'],
    verbs: ['POST'],
    isIcop: false,
  },
  CTICertificates: {
    path: ['/V1/Admin/Telephony/ctiCertificates'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: true,
  },
  ListCTICertificates: {
    path: ['/V2/Admin/Telephony/ctiCertificates/list'],
    verbs: ['GET'],
    isIcop: true,
  },
  CreateCTICertificate: {
    path: ['/V1/Admin/Telephony/ctiCertificates/install'],
    verbs: ['POST'],
    isIcop: true,
  },
  DeleteCTICertificate: {
    path: ['/V1/Admin/Telephony/ctiCertificates/delete'],
    verbs: ['DELETE'],
    isIcop: true,
  },
  deviceGroups: {
    path: ['/device-groups'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  deviceGroupsClone: {
    path: ['/device-groups', '/clone'],
    verbs: ['POST'],
    isIcop: false,
  },
  deviceGroupsDomains: {
    path: ['/device-groups', '/domains'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  devicePhoneDefunct: {
    path: ['/V1/Devices/phones/defunct'],
    verbs: ['DELETE', 'GET'],
    isIcop: true,
  },
  devicePhoneRefresh: {
    path: ['/V1/Devices/phones/refresh'],
    verbs: ['PUT'],
    isIcop: true,
  },
  devicePhones: {
    path: ['/V1/Devices/phones'],
    verbs: ['GET'],
    isIcop: true,
  },
  devices: {
    path: ['/devices'],
    verbs: ['GET', 'POST'],
    isIcop: false,
  },
  apiDevices: {
    path: ['/api-devices'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  distributionListPermissions: {
    path: ['/distribution-lists', '/permissions'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  distributionLists: {
    path: ['/distribution-lists'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  distributionListsDomains: {
    path: ['/distribution-lists', '/domains'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  distributionListsUserLoaderGroups: {
    path: ['/distribution-lists', '/scim-groups'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  distributionListUserSubscriptions: {
    path: ['/distribution-lists', '/user-subscriptions'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  distributionListsClone: {
    path: ['/distribution-lists', '/clone'],
    verbs: ['POST'],
    isIcop: false,
  },
  domains: {
    path: ['/domains'],
    verbs: ['GET', 'PUT', 'POST', 'DELETE'],
    isIcop: false,
  },
  domainsBellSchedules: {
    path: ['/domains', '/bell-schedules'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  domainsCollaborationGroups: {
    path: ['/domains', '/collaboration-groups'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  domainsConfirmationRequests: {
    path: ['/domains', '/confirmation-requests'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  domainsDeviceGroups: {
    path: ['/domains', '/device-groups'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  domainsDistributionLists: {
    path: ['/domains', '/distribution-lists'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  domainsIncidentPlans: {
    path: ['/domains', '/incident-plans'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  domainsIncidents: {
    path: ['/domains', '/incidents'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  domainsLoadDefinitions: {
    path: ['/domains', '/load-definitions'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  domainsMessageTemplates: {
    path: ['/domains', '/message-templates'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  domainsNotificationProfiles: {
    path: ['/domains', '/notification-profiles'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  domainsNotifications: {
    path: ['/domains', '/notifications'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  domainsRingLists: {
    path: ['/domains', '/ring-lists'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  domainsScenarios: {
    path: ['/domains', '/scenarios'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  domainsSecurityGroups: {
    path: ['/domains', '/security-groups'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  domainsSiteRoles: {
    path: ['/domains', '/site-roles'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  domainsSites: {
    path: ['/domains', '/sites'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  domainsUsers: {
    path: ['/domains', '/users'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  facilities: {
    path: ['/facilities'],
    verbs: ['GET', 'PUT', 'POST', 'DELETE'],
    isIcop: false,
  },
  facilitiesUsers: {
    path: ['/facilities', '/users'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  usersFacilities: {
    path: ['/users', '/facilities'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  extensionAttachments: {
    path: ['/extensions', '/attachments'],
    verbs: ['GET', 'DELETE', 'POST'],
    isIcop: false,
  },
  extensionEndpointActivities: {
    path: ['/extensions', '/endpoints', '/activities'],
    verbs: ['GET', 'POST'],
    isIcop: false,
  },
  extensionEndpointDevices: {
    path: ['/extensions', '/endpoints', '/devices'],
    verbs: ['GET', 'PATCH', 'DELETE', 'POST', 'PUT'],
    isIcop: false,
  },
  extensionEndpointMappings: {
    path: ['/extensions', '/endpoints', '/mappings'],
    verbs: ['GET'],
    isIcop: false,
  },
  extensionEndpointProxy: {
    path: ['/extensions', '/endpoints', '/proxy'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  extensionEndpoints: {
    path: ['/extensions', '/endpoints'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  extensionEndpointSpeaker: {
    path: ['/extensions', '/endpoints', '/speaker'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  extensionEndpointUploads: {
    path: ['/extensions', '/endpoints', '/uploads'],
    verbs: ['GET', 'DELETE', 'POST'],
    isIcop: false,
  },
  extensionProxy: {
    path: ['/extensions', '/proxy'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  extensions: {
    path: ['/extensions'],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  extensionsAndroid: {
    path: [`/extensions/${ANDROID_EXTENSION_ID}`],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  applications: {
    path: ['/applications'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  applicationSecrets: {
    path: ['/applications', '/secrets'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  applicationScreenshots: {
    path: ['/applications', '/screenshots'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  applicationDraft: {
    path: ['/applications', '/draft'],
    verbs: ['POST'],
    isIcop: false,
  },
  applicationPublish: {
    path: ['/applications', '/publish'],
    verbs: ['POST'],
    isIcop: false,
  },
  installedApplications: {
    path: ['/installed-applications'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  publicApplications: {
    path: ['/public-applications'],
    verbs: ['GET'],
    isIcop: false,
  },
  publicApplicationScreenshots: {
    path: ['/public-applications', '/screenshots'],
    verbs: ['GET'],
    isIcop: false,
  },
  extensionsKontaktioWearablePanicButtonStatus: {
    path: [
      `/extensions/${KONTAKTIO_WEARABLE_PANIC_BUTTON_EXTENSION_ID}/proxy/api/v1/integration-status`,
    ],
    verbs: ['GET'],
    isIcop: false,
  },
  extensionsKontaktioWearablePanicButtonActions: {
    path: [
      `/extensions/${KONTAKTIO_WEARABLE_PANIC_BUTTON_EXTENSION_ID}/proxy/api/v1/integration-actions`,
    ],
    verbs: ['POST'],
    isIcop: false,
  },
  extensionsKontaktioWearablePanicButtonSites: {
    path: [`/extensions/${KONTAKTIO_WEARABLE_PANIC_BUTTON_EXTENSION_ID}/proxy/api/v1/sites`],
    verbs: ['GET'],
    isIcop: false,
  },
  notificationExtensionsKontaktioWearablePanicButtonFetchFloorDetails: {
    path: [
      `/notifications`,
      `/extensions/${KONTAKTIO_WEARABLE_PANIC_BUTTON_EXTENSION_ID}/proxy/floor-details`,
    ],
    verbs: ['GET'],
    isIcop: false,
  },
  notificationExtensionsKontaktioWearablePanicButtonFetchFloorImage: {
    path: [
      `/notifications`,
      `/extensions/${KONTAKTIO_WEARABLE_PANIC_BUTTON_EXTENSION_ID}/proxy/floor-image`,
    ],
    verbs: ['GET'],
    isIcop: false,
  },
  extensionsDND: {
    path: [`/extensions/${DND_EXTENSION_ID}`],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  extensionsEmail: {
    path: [`/extensions/${EMAIL_EXTENSION_ID}`],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  extensionsTeams: {
    path: [`/extensions/${TEAMS_CHAT_EXTENSION_ID}`],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  extensionsWebexCalling: {
    path: [`/extensions/${WEBEX_CALLING_EXTENSION_ID}`],
    verbs: ['GET', 'PUT', 'POST'],
    isIcop: false,
  },
  extensionsWebexCallingStatus: {
    path: [`/extensions/${WEBEX_CALLING_EXTENSION_ID}/proxy/api/v1/integration-status`],
    verbs: ['GET'],
    isIcop: false,
  },
  extensionsWebexCallingConfiguration: {
    path: [`/extensions/${WEBEX_CALLING_EXTENSION_ID}/proxy/api/v1/account`],
    verbs: ['POST', 'DELETE'],
    isIcop: false,
  },
  extensionsWebexCallingLocations: {
    path: [`/extensions/${WEBEX_CALLING_EXTENSION_ID}/proxy/api/v1/locations`],
    verbs: ['GET'],
    isIcop: false,
  },
  webexCallingDevices: {
    path: ['/webex-calling-devices'],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  extensionsWebexCallingLocationsImport: {
    path: [`/extensions/${WEBEX_CALLING_EXTENSION_ID}/proxy/api/v1/import-locations`],
    verbs: ['POST'],
    isIcop: false,
  },
  extensionsWebexCallingResync: {
    path: [`/extensions/${WEBEX_CALLING_EXTENSION_ID}/proxy/api/v1/resync`],
    verbs: ['POST'],
    isIcop: false,
  },
  extensionsChannels: {
    path: [`/extensions/${TEAMS_CHANNEL_EXTENSION_ID}`],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  extensionsChannelsAllTeams: {
    path: [`/extensions/${TEAMS_CHANNEL_EXTENSION_ID}/proxy/api/v1/all-teams`],
    verbs: ['GET'],
    isIcop: false,
  },
  extensionsChannelsAllChannels: {
    path: [`/extensions/${TEAMS_CHANNEL_EXTENSION_ID}/proxy/api/v1/all-channels`],
    verbs: ['GET'],
    isIcop: false,
  },
  extensionsTeamsStatus: {
    path: [`/extensions/${TEAMS_CHAT_EXTENSION_ID}/proxy/api/v1/status`],
    verbs: ['GET'],
    isIcop: false,
  },
  extensionsTeamsActions: {
    path: [`/extensions/${TEAMS_CHAT_EXTENSION_ID}/proxy/api/v1/actions`],
    verbs: ['POST'],
    isIcop: false,
  },
  extensionsPDMSSyncDevices: {
    path: [`/extensions/${POLY_EXTENSION_ID}/proxy/api/v1/sync-devices`],
    verbs: ['POST'],
    isIcop: false,
  },
  extensionsICOP: {
    path: [`/extensions/${ICOP_EXTENSION_ID}`],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  extensionsPoly: {
    path: [`/extensions/${POLY_EXTENSION_ID}`],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  extensionsICOPEndpoints: {
    path: [`/extensions/${ICOP_EXTENSION_ID}/endpoints`],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  extensionsICOPEndpointsActivities: {
    path: [`/extensions/${ICOP_EXTENSION_ID}/endpoints`, '/activities'],
    verbs: ['GET', 'POST'],
    isIcop: false,
  },
  extensionsIdn: {
    path: [`/extensions/${IDN_EXTENSION_ID}`],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  extensionsIOS: {
    path: [`/extensions/${IOS_EXTENSION_ID}`],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  extensionsMacDesktop: {
    path: [`/extensions/${APNS_DESKTOP_EXTENSION_ID}`],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  extensionsWindowsDesktop: {
    path: [`/extensions/${WNS_EXTENSION_ID}`],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  extensionsPhoneCall: {
    path: [`/extensions/${PHONE_CALL_EXTENSION_ID}`],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  extensionsPhoneCallVerify: {
    path: [`/extensions/${PHONE_CALL_EXTENSION_ID}/proxy/api/v1/outgoing-numbers`],
    verbs: ['POST'],
    isIcop: false,
  },
  extensionsSMS: {
    path: [`/extensions/${SMS_EXTENSION_ID}`],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  extensionsSMSAlphanumericSenderIds: {
    path: [`/extensions/${SMS_EXTENSION_ID}/proxy/api/v1/alphanumeric-sender-ids`],
    verbs: ['GET'],
    isIcop: false,
  },
  extensionsZoomAccount: {
    path: [`/extensions/${ZOOM_EXTENSION_ID}/proxy/api/v1/account`],
    verbs: ['DELETE'],
    isIcop: false,
  },
  extensionsZoomSites: {
    path: [`/extensions/${ZOOM_SITES_EXTENSION_ID}/proxy/api/v1/sites`],
    verbs: ['GET'],
    isIcop: false,
  },
  extensionsZoomStatus: {
    path: [`/extensions/${ZOOM_EXTENSION_ID}/proxy/api/v1/status`],
    verbs: ['GET'],
    isIcop: false,
  },
  extensionsDisabledWebhooks: {
    path: [`/extensions/${EVENT_SERVICE_EXTENSION_ID}/proxy/disabled-webhooks`],
    verbs: ['GET'],
    isIcop: false,
  },
  extensionsReenableWebhook: {
    path: [`/extensions/${EVENT_SERVICE_EXTENSION_ID}/proxy/webhooks`, '/enable'],
    verbs: ['POST'],
    isIcop: false,
  },
  extensionsWebhook: {
    path: [`/extensions/${EVENT_SERVICE_EXTENSION_ID}/proxy/webhooks`],
    verbs: ['GET'],
    isIcop: false,
  },
  failoverPairs: {
    path: ['/failover-pairs'],
    verbs: ['GET', 'PUT', 'POST', 'DELETE'],
    isIcop: false,
  },
  fusionExtensionEndpoints: {
    path: [`/extensions/${ICOP_EXTENSION_ID}/endpoints`],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  icopDevices: {
    path: ['/V1/Devices'],
    verbs: ['GET'],
    isIcop: true,
  },
  idps: {
    path: ['/idps'],
    verbs: ['GET', 'PUT', 'POST', 'DELETE'],
    isIcop: false,
  },
  inboundCapRules: {
    path: ['/inbound-cap-rules'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  inboundCapServiceCountyPolygonList: {
    path: [`/extensions/${INBOUND_CAP_EXTENSION_ID}/proxy/us-county-polygons-list`],
    verbs: ['GET'],
    isIcop: false,
  },
  inboundCapServiceLanguageCodeList: {
    path: [`/extensions/${INBOUND_CAP_EXTENSION_ID}/proxy/language-code-list`],
    verbs: ['GET'],
    isIcop: false,
  },
  inboundCapServiceLatLonLookup: {
    path: [`/extensions/${INBOUND_CAP_EXTENSION_ID}/proxy/lat-lon-lookup`],
    verbs: ['GET'],
    isIcop: false,
  },
  inboundCapServiceTestCapMessage: {
    path: [`/extensions/${INBOUND_CAP_EXTENSION_ID}/proxy/test-cap-message`],
    verbs: ['POST'],
    isIcop: false,
  },
  inboundCapServiceTestRule: {
    path: [`/extensions/${INBOUND_CAP_EXTENSION_ID}/proxy/test-rule`],
    verbs: ['POST'],
    isIcop: false,
  },
  inboundCapServiceUSCountyListByState: {
    path: [`/extensions/${INBOUND_CAP_EXTENSION_ID}/proxy/us-county-list-by-state`],
    verbs: ['GET'],
    isIcop: false,
  },
  inboundCapServiceUSStateList: {
    path: [`/extensions/${INBOUND_CAP_EXTENSION_ID}/proxy/us-state-list`],
    verbs: ['GET'],
    isIcop: false,
  },
  inboundCapServiceUSStatePolygons: {
    path: [`/extensions/${INBOUND_CAP_EXTENSION_ID}/proxy/us-state-polygons`],
    verbs: ['GET'],
    isIcop: false,
  },
  inboundCapServiceCapEvent: {
    path: [`/extensions/${INBOUND_CAP_EXTENSION_ID}/proxy/cap/event`],
    verbs: ['POST'],
    isIcop: false,
  },
  inboundEmail: {
    path: ['/inbound-email'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  inboundEmailOutboundRules: {
    path: ['/inbound-email', '/outbound-rules'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  inboundRssFeeds: {
    path: ['/inbound-rss-feeds'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  incidents: {
    path: ['/incidents'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  setIncidentSite: {
    path: ['/incidents', '/site'],
    verbs: ['POST'],
    isIcop: false,
  },
  incidentsDomains: {
    path: ['/incidents', '/domains'],
    verbs: ['GET'],
    isIcop: false,
  },
  incidentResources: {
    path: ['/incidents', '/resources'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  incidentResourceFile: {
    path: ['/incidents', '/resources', '/file'],
    verbs: ['GET'],
    isIcop: false,
  },
  incidentActivities: {
    path: ['/incidents', '/activities'],
    verbs: ['GET', 'POST'],
    isIcop: false,
  },
  incidentPlans: {
    path: ['/incident-plans'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  incidentPlansDomains: {
    path: ['/incident-plans', '/domains'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  incidentPlanResources: {
    path: ['/incident-plans', '/resources'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  incidentPlanResourceFile: {
    path: ['/incident-plans', '/resources', '/file'],
    verbs: ['GET'],
    isIcop: false,
  },
  ipSpeakerSipParameters: {
    path: ['/ip-speaker-sip-parameters'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  ipSpeakerAdjustvolume: {
    path: ['/V1/IPSpeakers/adjustvolume'],
    verbs: ['PUT'],
    isIcop: true,
  },
  ipSpeakerAdjustvolumeAll: {
    path: ['/V1/IPSpeakers/adjustvolume', '/all'],
    verbs: ['PUT'],
    isIcop: true,
  },
  ipSpeakerDeleteNonregistered: {
    path: ['/V1/IPSpeakers/delete/nonregistered'],
    verbs: ['DELETE', 'PUT'],
    isIcop: true,
  },
  ipSpeakerExternalSpeakerConfigUrl: {
    path: ['/V1/IPSpeakers/externalSpeakerConfigURL'],
    verbs: ['DELETE', 'POST', 'GET'],
    isIcop: true,
  },
  ipSpeakerFilterAttributes: {
    path: ['/V1/IPSpeakers/filters/attributes'],
    verbs: ['GET'],
    isIcop: true,
  },
  ipSpeakerImport: {
    path: ['/V1/IPSpeakers/import'],
    verbs: ['POST'],
    isIcop: true,
  },
  ipSpeakerImportSpreadsheet: {
    path: ['/V1/IPSpeakers/import/spreadsheet'],
    verbs: ['GET'],
    isIcop: true,
  },
  ipSpeakerNew: {
    path: ['/V1/IPSpeakers/new'],
    verbs: ['GET'],
    isIcop: true,
  },
  ipSpeakerNewReboot: {
    path: ['/V1/IPSpeakers/new/reboot'],
    verbs: ['PUT'],
    isIcop: true,
  },
  ipSpeakerNewTest: {
    path: ['/V1/IPSpeakers/new', '/test'],
    verbs: ['PUT'],
    isIcop: true,
  },
  ipSpeakerReboot: {
    path: ['/V1/IPSpeakers/reboot'],
    verbs: ['PUT'],
    isIcop: true,
  },
  ipSpeakerRebootAll: {
    path: ['/V1/IPSpeakers/reboot/all'],
    verbs: ['PUT'],
    isIcop: true,
  },
  ipSpeakerRecipientgroups: {
    path: ['/V1/IPSpeakers', '/recipientgroups'],
    verbs: ['GET'],
    isIcop: true,
  },
  ipSpeakers: {
    path: ['/V1/IPSpeakers'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  ipSpeakerStatistics: {
    path: ['/V1/IPSpeakers/statistics'],
    verbs: ['GET'],
    isIcop: true,
  },
  ipSpeakerTest: {
    path: ['/V1/IPSpeakers', '/test'],
    verbs: ['PUT'],
    isIcop: true,
  },
  fusionIpSpeakerReboot: {
    path: ['/Fusion/V1/IPSpeakers', '/reboot'],
    verbs: ['PUT'],
    isIcop: true,
  },
  fusionIpSpeakerTest: {
    path: ['/Fusion/V1/IPSpeakers', '/test'],
    verbs: ['PUT'],
    isIcop: true,
  },
  loadDefinitionUserTypeMappings: {
    path: ['/load-definitions', '/user-type-mappings'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  loadDefinitionDistributionListMappings: {
    path: ['/load-definitions', '/distribution-list-mappings'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  loadDefinitionLoadRequestsLog: {
    path: ['/load-definitions', '/load-requests', '/log'],
    verbs: ['GET'],
    isIcop: false,
  },
  loadDefinitionLoadRequests: {
    path: ['/load-definitions', '/load-requests'],
    verbs: ['GET', 'POST'],
    isIcop: false,
  },
  loadDefinitions: {
    path: ['/load-definitions'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  loadDefinitionsDomains: {
    path: ['/load-definitions', '/domains'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  loadDefinitionDomainMappings: {
    path: ['/load-definitions', '/domain-mappings'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  loadDefinitionFacilityMappings: {
    path: ['/load-definitions', '/facility-mappings'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  loadDefinitionSecurityGroupMappings: {
    path: ['/load-definitions', '/security-group-mappings'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  loadDefinitionTemplate: {
    path: ['/load-definitions', '/template'],
    verbs: ['GET'],
    isIcop: false,
  },
  scimUsers: {
    path: ['/scim-users'],
    verbs: ['GET'],
    isIcop: false,
  },
  scimUserGroups: {
    path: ['/scim-users', '/groups'],
    verbs: ['GET'],
    isIcop: false,
  },
  scimGroups: {
    path: ['/scim-groups'],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  scimGroupDomains: {
    path: ['/scim-groups', '/domains'],
    verbs: ['GET', 'POST'],
    isIcop: false,
  },
  scimGroupFacilities: {
    path: ['/scim-groups', '/facilities'],
    verbs: ['GET', 'POST'],
    isIcop: false,
  },
  scimGroupMembers: {
    path: ['/scim-groups', '/members'],
    verbs: ['GET'],
    isIcop: false,
  },
  messageActive: {
    path: ['/V1/Messages/active'],
    verbs: ['DELETE', 'GET'],
    isIcop: true,
  },
  messageDependencies: {
    path: ['/V1/Messages', '/dependencies'],
    verbs: ['GET'],
    isIcop: true,
  },
  messageDownloadAudio: {
    path: ['/V1/Messages', '/downloadAudio'],
    verbs: ['GET'],
    isIcop: true,
  },
  messageDownloadScript: {
    path: ['/V1/Messages', '/downloadScript'],
    verbs: ['GET'],
    isIcop: true,
  },
  messageDynamicSender: {
    path: ['/V1/Messages/DynamicSender'],
    verbs: ['POST'],
    isIcop: true,
  },
  messageFormData: {
    path: ['/V1/Messages/formData'],
    verbs: ['POST', 'PUT'],
    isIcop: true,
  },
  messageFormDatumDynamicSender: {
    path: ['/V1/Messages/formData/DynamicSender'],
    verbs: ['POST'],
    isIcop: true,
  },
  messageIcon: {
    path: ['/V1/Messages', '/icon'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  messageImage: {
    path: ['/V1/Messages', '/image'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  messageQueued: {
    path: ['/V1/Messages/queued'],
    verbs: ['DELETE', 'GET'],
    isIcop: true,
  },
  messageRecordAudio: {
    path: ['/V1/Messages', '/recordAudio'],
    verbs: ['PUT'],
    isIcop: true,
  },
  messages: {
    path: ['/V1/Messages'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  messageTemplateAudio: {
    path: ['/message-templates', '/audio'],
    verbs: ['GET'],
    isIcop: false,
  },
  providerKeyRotation: {
    path: [`/extensions/${ICOP_EXTENSION_ID}/provider-public-key`],
    verbs: ['DELETE'],
    usesSelectedServer: false,
    isProxy: false,
  },
  TTS: {
    path: ['/tts'],
    verbs: ['POST', 'GET'],
    isIcop: false,
  },
  ttsLexicons: {
    path: ['/tts-lexicons'],
    verbs: ['POST', 'GET', 'PUT', 'DELETE'],
    isIcop: false,
  },
  ttsVoices: {
    path: ['/tts-voices'],
    verbs: ['GET'],
    isIcop: false,
  },
  ttsDefaults: {
    path: ['/tts-defaults'],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  messageTemplateImage: {
    path: ['/message-templates', '/image'],
    verbs: ['GET'],
    isIcop: false,
  },
  messageTemplates: {
    path: ['/message-templates'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  messageTemplatesDomains: {
    path: ['/message-templates', '/domains'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  messageTemplatesClone: {
    path: ['/message-templates', '/clone'],
    verbs: ['POST'],
    isIcop: false,
  },
  messageUpdateAllAudioFiles: {
    path: ['/V1/Messages', '/updateAllAudioFiles'],
    verbs: ['PUT'],
    isIcop: true,
  },
  messageUpdateAudio: {
    path: ['/V1/Messages', '/updateAudio'],
    verbs: ['PUT'],
    isIcop: true,
  },
  messageUpdateScript: {
    path: ['/V1/Messages', '/updateScript'],
    verbs: ['PUT'],
    isIcop: true,
  },
  notificationActivities: {
    path: ['/notifications', '/activities'],
    verbs: ['GET', 'POST'],
    isIcop: false,
  },
  notificationActionResults: {
    path: [`/extensions/${EVENT_SERVICE_EXTENSION_ID}/proxy/event-action-results`],
    verbs: ['GET'],
    isIcop: false,
  },
  notificationAudio: {
    path: ['/notifications', '/audio'],
    verbs: ['GET'],
    isIcop: false,
  },
  notificationDevices: {
    path: ['/notifications', '/devices'],
    verbs: ['GET'],
    isIcop: false,
  },
  notificationsDomains: {
    path: ['/notifications', '/domains'],
    verbs: ['GET'],
    isIcop: false,
  },
  notificationHistoryReport: {
    path: [`/reports/${NOTIFICATION_HISTORY_REPORT_ID}`],
    verbs: ['GET'],
    isIcop: false,
  },
  notificationImage: {
    path: ['/notifications', '/image'],
    verbs: ['GET'],
    isIcop: false,
  },
  notificationProfiles: {
    path: ['/notification-profiles'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  notificationProfilesClone: {
    path: ['/notification-profiles', '/clone'],
    verbs: ['POST'],
    isIcop: false,
  },
  notificationProfilesDomains: {
    path: ['/notification-profiles', '/domains'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  notificationProfileSettingAttachments: {
    path: ['/notification-profiles', '/settings', '/attachments'],
    verbs: ['GET'],
    isIcop: false,
  },
  notificationProfileSettings: {
    path: ['/notification-profiles', '/settings'],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  notificationRecipients: {
    path: ['/notifications', '/recipients'],
    verbs: ['GET'],
    isIcop: false,
  },
  notifications: {
    path: ['/notifications'],
    verbs: ['GET', 'DELETE', 'POST'],
    isIcop: false,
  },
  settings: {
    path: ['/settings'],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  outboundIpawsAlerts: {
    path: [`/extensions/${IPAWS_EXTENSION_ID}/proxy`, '/alert'],
    verbs: ['GET'],
    isIcop: false,
  },
  outboundIpawsAlertResponse: {
    path: [`/extensions/${IPAWS_EXTENSION_ID}/proxy`, '/alert', '/response'],
    verbs: ['GET'],
    isIcop: false,
  },
  outboundIpawsAreas: {
    path: [`/extensions/${IPAWS_EXTENSION_ID}/proxy/geo/areas`],
    verbs: ['GET'],
    isIcop: false,
  },
  outboundIpawsAvailability: {
    path: [`/extensions/${IPAWS_EXTENSION_ID}/proxy`, '/ipaws/get-ack'],
    verbs: ['GET'],
    isIcop: false,
  },
  outboundIpawsChannelPerms: {
    path: [`/extensions/${IPAWS_ADMIN_EXTENSION_ID}/proxy/cog/channel-perms`],
    verbs: ['GET'],
    isIcop: false,
  },
  outboundIpawsCircles: {
    path: [`/extensions/${IPAWS_EXTENSION_ID}/proxy/geo/circles`],
    verbs: ['DELETE', 'PUT', 'POST'],
    isIcop: false,
  },
  outboundIpawsConfig: {
    path: [`/extensions/${IPAWS_ADMIN_EXTENSION_ID}/proxy/cog`],
    verbs: ['GET', 'POST'],
    isIcop: false,
  },
  outboundIpawsPolygons: {
    path: [`/extensions/${IPAWS_EXTENSION_ID}/proxy/geo/polygons`],
    verbs: ['DELETE', 'PUT', 'POST'],
    isIcop: false,
  },
  outboundIpawsSend: {
    path: [`/extensions/${IPAWS_EXTENSION_ID}/proxy`, '/alert'],
    verbs: ['GET', 'POST'],
    isIcop: false,
  },
  outboundIpawsMessageStatus: {
    path: [`/extensions/${IPAWS_EXTENSION_ID}/proxy`, '/ipaws/alert', '/status'],
    verbs: ['GET', 'POST'],
    isIcop: false,
  },
  outboundTwitterSignIn: {
    path: [`/extensions/${TWITTER_EXTENSION_ID}/proxy/api/v1/request-token`],
    verbs: ['POST'],
    isIcop: false,
  },
  pagingGateways: {
    path: ['/V1/PagingGateways/gateways'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  pagingGatewaysRecipientFiltersList: {
    path: ['/V1/PagingGateways/gateways/recipientFilters/list'],
    verbs: ['POST'],
    isIcop: true,
  },
  pagingGatewaysActivated: {
    path: ['/V1/PagingGateways/PG2Activated'],
    verbs: ['GET'],
    isIcop: true,
  },
  pagingGatewaysEndCapture: {
    path: ['/V1/PagingGateways/gateways', '/endCapture'],
    verbs: ['POST'],
    isIcop: true,
  },
  pagingGatewaysGetErrorLogs: {
    path: ['/V1/PagingGateways/gateways', '/requestLogs'],
    verbs: ['POST'],
    isIcop: true,
  },
  pagingGatewaysGetMulticastAddresses: {
    path: ['/V1/PagingGateways/gateways', '/gmsSubscriptions'],
    verbs: ['GET'],
    isIcop: true,
  },
  pagingGatewaysGlobalSettings: {
    path: ['/V1/PagingGateways/Config'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  pagingGatewaysGracefulReset: {
    path: ['/V1/PagingGateways/gateways', '/gracefulReset'],
    verbs: ['POST'],
    isIcop: true,
  },
  pagingGatewaysGracefulResetAll: {
    path: ['/V1/PagingGateways/GracefulResetAll'],
    verbs: ['POST'],
    isIcop: true,
  },
  pagingGatewaysImmediateReset: {
    path: ['/V1/PagingGateways/gateways', '/forcefulReset'],
    verbs: ['POST'],
    isIcop: true,
  },
  pagingGatewaysImmediateResetAll: {
    path: ['/V1/PagingGateways/ForcefulResetAll'],
    verbs: ['POST'],
    isIcop: true,
  },
  pagingGatewaysPassword: {
    path: ['/V1/PagingGateways/gateways', '/password'],
    verbs: ['GET'],
    isIcop: true,
  },
  pagingGatewaysStartCapture: {
    path: ['/V1/PagingGateways/gateways', '/startCapture'],
    verbs: ['POST'],
    isIcop: true,
  },
  pagingGatewaysEventsList: {
    path: ['/V1/PagingGateways/Events/list'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginCallAwareCallHistory: {
    path: ['/V1/Plugins/CallAware/callHistory'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginCallAwareCallHistoryAudio: {
    path: ['/V1/Plugins/CallAware/callHistory/audio'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginCallAwareCallHistoryAudioStream: {
    path: ['/V1/Plugins/CallAware/callHistory/audioStream'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginCallAwareCallHistoryList: {
    path: ['/V1/Plugins/CallAware/callHistory/list'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginCallAwareActiveCalls: {
    path: ['/active-callaware-calls'],
    verbs: ['GET'],
    isIcop: false,
  },
  pluginCallAwareCallRedirects: {
    path: ['/V1/Plugins/CallAware/callRedirects'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginFusionCallAwareCallRedirects: {
    path: ['/Fusion/V1/Plugins/CallAware/callRedirects'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginCallAwareConfiguration: {
    path: ['/V1/Plugins/CallAware/configuration'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  pluginCallAwareConfigurationCucmRestore: {
    path: ['/V1/Plugins/CallAware/configuration/cucms/restore'],
    verbs: ['PUT'],
    isIcop: true,
  },
  pluginCallAwareConfigurationCucms: {
    path: ['/V1/Plugins/CallAware/configuration/cucms'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  pluginCallAwareDiagnostics: {
    path: ['/V1/Plugins/CallAware/diagnostics'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginCallAwareLicense: {
    path: ['/V1/Plugins/CallAware/license'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginCallAwareRoutingRequests: {
    path: ['/V1/Plugins/CallAware/routingRequests'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginFusionCallAwareRoutingRequests: {
    path: ['/Fusion/V1/Plugins/CallAware/routingRequests'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginCloudPhones: {
    path: ['/V1/Plugins/GenericMulticast'],
    verbs: ['DELETE', 'GET'],
    isIcop: true,
  },
  pluginCloudPhonesGeneric: {
    path: ['/V1/Plugins/GenericMulticast/generic'],
    verbs: ['POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginCloudPhonesPolycom: {
    path: ['/V1/Plugins/GenericMulticast/polycom'],
    verbs: ['POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginConferenceCallAudio: {
    path: ['/V1/Plugins/ConferenceCall/Audio'],
    verbs: ['DELETE', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginConferenceCallConfiguration: {
    path: ['/V1/Plugins/ConferenceCall/configuration'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  pluginConferenceCallContact: {
    path: ['/V1/Plugins/ConferenceCall/contact'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginConferenceCallContacts: {
    path: ['/V1/Plugins/ConferenceCall/contacts'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginConferenceCallCucmConfig: {
    path: ['/V1/Plugins/ConferenceCall/cucmConfig'],
    verbs: ['DELETE', 'PUT'],
    isIcop: true,
  },
  pluginConferenceCallGroup: {
    path: ['/V1/Plugins/ConferenceCall/group'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginConferenceCallGroups: {
    path: ['/V1/Plugins/ConferenceCall/groups'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginConferenceCallLicense: {
    path: ['/V1/Plugins/ConferenceCall/license'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginConferenceCallStatus: {
    path: ['/V1/Plugins/ConferenceCall/status'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginIcMobileActivity: {
    path: ['/V1/Plugins/ICMobile/activity'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginIcMobileActivityClear: {
    path: ['/V1/Plugins/ICMobile/activity/clear'],
    verbs: ['PUT'],
    isIcop: true,
  },
  pluginIcMobileConfiguration: {
    path: ['/V1/Plugins/ICMobile/configuration'],
    verbs: ['PUT'],
    isIcop: true,
  },
  pluginIcMobileConfigurationClear: {
    path: ['/V1/Plugins/ICMobile/configuration/clear'],
    verbs: ['PUT'],
    isIcop: true,
  },
  pluginIcMobileInboundDistributions: {
    path: ['/V1/Plugins/ICMobile/inbound/distributions'],
    verbs: ['DELETE', 'POST', 'GET'],
    isIcop: true,
  },
  pluginIcMobileInboundMessageCustom: {
    path: ['/V1/Plugins/ICMobile/inbound/messages', '/custom'],
    verbs: ['DELETE', 'PUT'],
    isIcop: true,
  },
  pluginIcMobileInboundMessageDefaultAudio: {
    path: ['/V1/Plugins/ICMobile/inbound/messages/default/audio'],
    verbs: ['DELETE', 'PUT'],
    isIcop: true,
  },
  pluginIcMobileInboundMessageDefaultNonaudio: {
    path: ['/V1/Plugins/ICMobile/inbound/messages/default/nonaudio'],
    verbs: ['DELETE', 'PUT'],
    isIcop: true,
  },
  pluginIcMobileInboundMessages: {
    path: ['/V1/Plugins/ICMobile/inbound/messages'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginIcMobileLicense: {
    path: ['/V1/Plugins/ICMobile/license'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginIcMobileOutboundDistributionRefresh: {
    path: ['/V1/Plugins/ICMobile/outbound/distributions/refresh'],
    verbs: ['PUT'],
    isIcop: true,
  },
  pluginIcMobileOutboundDistributions: {
    path: ['/V1/Plugins/ICMobile/outbound/distributions'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginIcMobileOutboundMessageTemplateCustom: {
    path: ['/V1/Plugins/ICMobile/outbound/messageTemplates', '/custom'],
    verbs: ['DELETE', 'PUT'],
    isIcop: true,
  },
  pluginIcMobileOutboundMessageTemplateDefault: {
    path: ['/V1/Plugins/ICMobile/outbound/messageTemplates/default'],
    verbs: ['DELETE', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginIcMobileOutboundMessageTemplates: {
    path: ['/V1/Plugins/ICMobile/outbound/messageTemplates'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginInboundCapConfiguration: {
    path: ['/V1/Plugins/InboundCAP/configuration'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginInboundCapConfigurationDefaultLanguage: {
    path: ['/V1/Plugins/InboundCAP/configuration/defaultLanguage'],
    verbs: ['DELETE', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginInboundCapConfigurationDefaultLanguageOptions: {
    path: ['/V1/Plugins/InboundCAP/configuration/defaultLanguage/options'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginInboundCapConfigurationGeoDef: {
    path: ['/V1/Plugins/InboundCAP/configuration/geoDef'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginInboundCapConfigurationInboundService: {
    path: ['/V1/Plugins/InboundCAP/configuration/inboundService'],
    verbs: ['DELETE', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginInboundCapConfigurationInboundServiceRestart: {
    path: ['/V1/Plugins/InboundCAP/configuration/inboundService/restart'],
    verbs: ['PUT'],
    isIcop: true,
  },
  pluginInboundCapConfigurationInboundServiceStart: {
    path: ['/V1/Plugins/InboundCAP/configuration/inboundService/start'],
    verbs: ['PUT'],
    isIcop: true,
  },
  pluginInboundCapConfigurationInboundServiceStop: {
    path: ['/V1/Plugins/InboundCAP/configuration/inboundService/stop'],
    verbs: ['PUT'],
    isIcop: true,
  },
  pluginInboundCapConfigurationProxy: {
    path: ['/V1/Plugins/InboundCAP/configuration/proxy'],
    verbs: ['DELETE', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginInboundCapDataSources: {
    path: ['/V1/Plugins/InboundCAP/dataSources'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginInboundCapDataSourceStart: {
    path: ['/V1/Plugins/InboundCAP/dataSources', '/start'],
    verbs: ['PUT'],
    isIcop: true,
  },
  pluginInboundCapDataSourceStop: {
    path: ['/V1/Plugins/InboundCAP/dataSources', '/stop'],
    verbs: ['PUT'],
    isIcop: true,
  },
  pluginInboundCapFips6StateFips6Counties: {
    path: ['/V1/Plugins/InboundCAP/fips6States/fips6Counties'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginInboundCapFips6States: {
    path: ['/V1/Plugins/InboundCAP/fips6States'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginInboundCapLicense: {
    path: ['/V1/Plugins/InboundCAP/license'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginInboundCapMessageRuleFilterAttributes: {
    path: ['/V1/Plugins/InboundCAP/messageRules/filterAttributes'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginInboundCapMessageRules: {
    path: ['/V1/Plugins/InboundCAP/messageRules'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginInboundCapUgcStates: {
    path: ['/V1/Plugins/InboundCAP/ugcStates'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginInboundCapUgcStateUgcZones: {
    path: ['/V1/Plugins/InboundCAP/ugcStates/ugcZones'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginInboundEmailAccounts: {
    path: ['/V1/Plugins/InboundEmail/accounts'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginInboundEmailBroadcasts: {
    path: ['/V1/Plugins/InboundEmail/broadcasts'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginInboundEmailBroadcastsV2: {
    path: ['/V2/Plugins/InboundEmail/broadcasts'],
    verbs: ['POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginInboundEmailLicense: {
    path: ['/V1/Plugins/InboundEmail/license'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginInboundEmailSettings: {
    path: ['/V1/Plugins/InboundEmail/settings'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  pluginInboundRssFeeds: {
    path: ['/V1/Plugins/InboundRSS/feeds'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginInboundRssLicense: {
    path: ['/V1/Plugins/InboundRSS/license'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginInboundRssSettings: {
    path: ['/V1/Plugins/InboundRSS/settings'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  pluginLpiConfiguration: {
    path: ['/V1/Plugins/LPI/configuration'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  pluginLpiConfigurationTestTone: {
    path: ['/V1/Plugins/LPI/configuration/testTone'],
    verbs: ['POST'],
    isIcop: true,
  },
  pluginLpiGenerateMacAddress: {
    path: ['/V1/Plugins/LPI/GenerateMACAddress'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginLpiLicense: {
    path: ['/V1/Plugins/LPI/license'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginLpiPagingDeviceCurrentActivity: {
    path: ['/V1/Plugins/LPI/pagingDevices/currentActivity'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginLpiPagingDeviceCurrentActivityBroadcastStop: {
    path: ['/V1/Plugins/LPI/pagingDevices/currentActivity/broadcast/stop'],
    verbs: ['PUT'],
    isIcop: true,
  },
  pluginLpiPagingDeviceCurrentActivityTestStop: {
    path: ['/V1/Plugins/LPI/pagingDevices/currentActivity/test/stop'],
    verbs: ['PUT'],
    isIcop: true,
  },
  pluginLpiPagingDevices: {
    path: ['/V1/Plugins/LPI/pagingDevices'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginLpiPagingDeviceZones: {
    path: ['/V1/Plugins/LPI/pagingDevices', '/zones'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginLpiServerGroups: {
    path: ['/V1/Plugins/LPI/serverGroups'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginLpiServerGroupServers: {
    path: ['/V1/Plugins/LPI/serverGroups', '/servers'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginM2MContactClosureInputPorts: {
    path: ['/V1/Plugins/M2M/contactClosures', '/inputPorts'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginM2MContactClosureOutputPorts: {
    path: ['/V1/Plugins/M2M/contactClosures', '/outputPorts'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginM2MContactClosures: {
    path: ['/V1/Plugins/M2M/contactClosures'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginFusionM2MContactClosureInputPorts: {
    path: ['/Fusion/V1/Plugins/M2M/contactClosures', '/inputPorts'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginFusionM2MContactClosureOutputPorts: {
    path: ['/Fusion/V1/Plugins/M2M/contactClosures', '/outputPorts'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginFusionM2MContactClosures: {
    path: ['/Fusion/V1/Plugins/M2M/contactClosures'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginM2MLicense: {
    path: ['/V1/Plugins/M2M/license'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginM2MSchedules: {
    path: ['/V1/Plugins/M2M/schedules'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginNightBellConfiguration: {
    path: ['/V1/Plugins/NightBell/configuration'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  pluginNightBellConfigurationCucmRestore: {
    path: ['/V1/Plugins/NightBell/configuration/cucms/restore'],
    verbs: ['PUT'],
    isIcop: true,
  },
  pluginNightBellConfigurationCucms: {
    path: ['/V1/Plugins/NightBell/configuration/cucms'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  pluginNightBellDiagnostics: {
    path: ['/V1/Plugins/NightBell/diagnostics'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginNightBellLicense: {
    path: ['/V1/Plugins/NightBell/license'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginNightBellTriggers: {
    path: ['/V1/Plugins/NightBell/triggers'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginFusionNightBellTriggers: {
    path: ['/Fusion/V1/Plugins/NightBell/triggers'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginOutboundEmailConfiguration: {
    path: ['/V1/Plugins/OutboundEmail/configuration'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  pluginOutboundEmailEmailReferences: {
    path: ['/V1/Plugins/OutboundEmail/emailReferences'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginOutboundEmailLicense: {
    path: ['/V1/Plugins/OutboundEmail/license'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginParkAndPageConfiguration: {
    path: ['/V1/Plugins/ParkAndPage/configuration'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  pluginParkAndPageConfigurationCucmRestore: {
    path: ['/V1/Plugins/ParkAndPage/configuration/cucms/restore'],
    verbs: ['PUT'],
    isIcop: true,
  },
  pluginParkAndPageConfigurationCucms: {
    path: ['/V1/Plugins/ParkAndPage/configuration/cucms'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  pluginParkAndPageCtiPorts: {
    path: ['/V1/Plugins/ParkAndPage/ctiPorts'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginParkAndPageDestinations: {
    path: ['/V1/Plugins/ParkAndPage/destinations'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginParkAndPageDiagnostics: {
    path: ['/V1/Plugins/ParkAndPage/diagnostics'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginParkAndPageLicense: {
    path: ['/V1/Plugins/ParkAndPage/license'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginParkAndPageTriggers: {
    path: ['/V1/Plugins/ParkAndPage/triggers'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginParkAndPageTriggerStatistics: {
    path: ['/V1/Plugins/ParkAndPage/triggers/statistics'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginPluginManagerDisable: {
    path: ['/V1/Plugins/PluginManager/disable'],
    verbs: ['PUT'],
    isIcop: true,
  },
  pluginPluginManagerEnable: {
    path: ['/V1/Plugins/PluginManager/enable'],
    verbs: ['PUT'],
    isIcop: true,
  },
  pluginPluginManagerInstalledPlugins: {
    path: ['/V1/Plugins/PluginManager/installedPlugins'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginPluginManagerOverview: {
    path: ['/V1/Plugins/PluginManager/overview'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginPrayerTimeLicense: {
    path: ['/V1/Plugins/PrayerTimes/license'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginPrayerTimePrayerTimes: {
    path: ['/V1/Plugins/PrayerTimes/prayerTimes'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginPrayerTimePrayerTimeShowDates: {
    path: ['/V1/Plugins/PrayerTimes/prayerTimes', '/showDates'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginQuickUrlDevices: {
    path: ['/V1/Plugins/QuickURL/devices'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginQuickUrlLicense: {
    path: ['/V1/Plugins/QuickURL/license'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginScriptLicense: {
    path: ['/V1/Plugins/Script/license'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginScriptScriptFile: {
    path: ['/V1/Plugins/Script/scripts', '/file'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  pluginScriptScripts: {
    path: ['/V1/Plugins/Script/scripts'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  pluginScriptScriptSamples: {
    path: ['/V1/Plugins/Script/scripts/samples'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginTwitterConfiguration: {
    path: ['/V1/Plugins/Twitter/configuration'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  pluginTwitterLicense: {
    path: ['/V1/Plugins/Twitter/license'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginTwitterReferenceGenAuthToken: {
    path: ['/V1/Plugins/Twitter/references/genAuthToken'],
    verbs: ['POST'],
    isIcop: true,
  },
  pluginTwitterReferences: {
    path: ['/V1/Plugins/Twitter/references'],
    verbs: ['DELETE', 'POST', 'GET'],
    isIcop: true,
  },
  pluginWordPressConfiguration: {
    path: ['/V1/Plugins/WordPress/configuration'],
    verbs: ['GET', 'PUT'],
    isIcop: true,
  },
  pluginWordPressLicense: {
    path: ['/V1/Plugins/WordPress/license'],
    verbs: ['GET'],
    isIcop: true,
  },
  pluginWordPressReferences: {
    path: ['/V1/Plugins/WordPress/references'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  quickPage: {
    path: ['/V1/QuickPage'],
    verbs: ['POST'],
    isIcop: true,
  },
  recipientGroupDependencies: {
    path: ['/V1/RecipientGroups', '/dependencies'],
    verbs: ['GET'],
    isIcop: true,
  },
  recipientGroupDevices: {
    path: ['/V1/RecipientGroups', '/devices'],
    verbs: ['GET'],
    isIcop: true,
  },
  recipientGroupFilterAttributes: {
    path: ['/V1/RecipientGroups/filters/attributes'],
    verbs: ['GET'],
    isIcop: true,
  },
  recipientGroupFilters: {
    path: ['/V1/RecipientGroups/filters'],
    verbs: ['GET'],
    isIcop: true,
  },
  recipientGroupPhones: {
    path: ['/V1/RecipientGroups', '/phones'],
    verbs: ['GET'],
    isIcop: true,
  },
  recipientGroups: {
    path: ['/V1/RecipientGroups'],
    verbs: ['DELETE', 'PATCH', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  recipientGroupTags: {
    path: ['/V1/RecipientGroups/tags'],
    verbs: ['DELETE', 'POST', 'GET', 'PUT'],
    isIcop: true,
  },
  recipientGroupTestFilters: {
    path: ['/V1/RecipientGroups/testFilters'],
    verbs: ['PUT'],
    isIcop: true,
  },
  reports: {
    path: ['/reports'],
    verbs: ['GET'],
    isIcop: false,
  },
  ringLists: {
    path: ['/ring-lists'],
    verbs: ['GET', 'POST', 'PUT'],
    isIcop: false,
  },
  ringListsClone: {
    path: ['/ring-lists', '/clone'],
    verbs: ['POST'],
    isIcop: false,
  },
  ringListsDomains: {
    path: ['/ring-lists', '/domains'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  scenarios: {
    path: ['/scenarios'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  scenarioRuleActions: {
    path: ['/scenarios', '/rule-actions'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  testRuleAction: {
    path: ['/scenarios', '/rule-actions', '/test'],
    verbs: ['POST'],
    isIcop: false,
  },
  ruleActions: {
    path: ['/rule-actions'],
    verbs: ['GET', 'POST', 'DELETE', 'PUT'],
    isIcop: false,
  },
  scenariosClone: {
    path: ['/scenarios', '/clone'],
    verbs: ['POST'],
    isIcop: false,
  },
  scenariosDomains: {
    path: ['/scenarios', '/domains'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  scenarioNotifications: {
    path: ['/scenario-notifications'],
    verbs: ['GET', 'POST'],
    isIcop: false,
  },
  scheduledNotifications: {
    path: ['/scheduled-notifications'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  scheduledNotificationsClone: {
    path: ['/scheduled-notifications', '/clone'],
    verbs: ['POST'],
    isIcop: false,
  },
  securityGroupMembers: {
    path: ['/security-groups', '/members'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  securityGroupAllowedScenarios: {
    path: ['/security-groups', '/allowed-scenarios'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  securityGroupAllowedMessageTemplates: {
    path: ['/security-groups', '/allowed-message-templates'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  securityGroupUserLoaderGroups: {
    path: ['/security-groups', '/scim-groups'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  securityGroupPermissions: {
    path: ['/security-groups', '/permissions'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  policies: {
    path: ['/policies'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  securityGroups: {
    path: ['/security-groups'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  securityGroupsClone: {
    path: ['/security-groups', '/clone'],
    verbs: ['POST'],
    isIcop: false,
  },
  securityGroupsCloneToFacilities: {
    path: ['/security-groups', '/clone-to-facilities'],
    verbs: ['POST'],
    isIcop: false,
  },
  securityGroupsDomains: {
    path: ['/security-groups', '/domains'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  session: {
    path: ['/session'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  sites: {
    path: ['/sites'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  siteExternalSources: {
    path: ['/sites', '/external-sources'],
    verbs: ['GET', 'DELETE'],
    isIcop: false,
  },
  siteBuildings: {
    path: ['/sites', '/buildings'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  siteBuildingFloors: {
    path: ['/sites', '/buildings', '/floors'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  reorderSiteBuildingFloors: {
    path: ['/sites', '/buildings', '/reorder-floors'],
    verbs: ['PUT'],
    isIcop: false,
  },
  siteBuildingFloorZones: {
    path: ['/sites', '/buildings', '/floors', '/zones'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  siteRecipients: {
    path: ['/sites', '/recipients'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  sitesDomains: {
    path: ['/sites', '/domains'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  sitesManagement: {
    path: ['/sites-management'],
    verbs: ['GET', 'POST'],
    isIcop: false,
  },
  siteMappedDevices: {
    path: ['/site-mapped-devices'],
    verbs: ['GET'],
    isIcop: false,
  },
  siteRoles: {
    path: ['/site-roles'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  siteRoleAssociatedSites: {
    path: ['/site-roles', '/sites'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  siteRolesDomains: {
    path: ['/site-roles', '/domains'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  sparkExtensionSpaces: {
    verbs: ['GET', 'POST', 'DELETE'],
    path: [`/extensions/${CISCO_SPARK_EXTENSION_ID}/proxy/api/v1/spaces`],
    isIcop: false,
  },
  sparkExtension: {
    verbs: ['GET', 'PUT'],
    path: [`/extensions/${CISCO_SPARK_EXTENSION_ID}`],
    isIcop: false,
  },
  sparkExtensionMySpaces: {
    verbs: ['GET', 'PUT', 'POST', 'DELETE'],
    path: [`/extensions/${CISCO_SPARK_EXTENSION_ID}/proxy/api/v1/my-spaces`],
    isIcop: false,
  },
  sparkExtensionSession: {
    verbs: ['GET', 'DELETE'],
    path: [`/extensions/${CISCO_SPARK_EXTENSION_ID}/proxy/api/v1/session`],
    isIcop: false,
  },
  speakers: {
    path: ['/ip-speakers'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  speakerSipParameters: {
    path: ['/ip-speakers', '/sip-configs'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  speakerSettings: {
    path: ['/ip-speaker-settings'],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  speakerJobs: {
    path: ['/ip-speaker-jobs'],
    verbs: ['GET', 'POST'],
    isIcop: false,
  },
  systemHealth: {
    path: ['/alarms'],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  systemHealthEvents: {
    path: ['/alarms', '/events'],
    verbs: ['GET'],
    isIcop: false,
  },
  allSystemHealthEvents: {
    path: ['/system-health-events'],
    verbs: ['GET'],
    isIcop: false,
  },
  alarmActions: {
    path: ['/alarms', '/actions'],
    verbs: ['GET', 'PUT', 'POST', 'DELETE'],
    isIcop: false,
  },
  trackingEventActivities: {
    path: ['/tracking-events', '/activities'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  trackingEventNotifications: {
    path: ['/tracking-event-notifications'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  trackingEvents: {
    path: ['/tracking-events'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  triggers: {
    path: ['/triggers'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  userDevices: {
    path: ['/users', '/devices'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  userDevicesBulk: {
    path: ['/user-devices'],
    verbs: ['GET'],
    isIcop: false,
  },
  usersDomains: {
    path: ['/users', '/domains'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  userSubscribableDistributionLists: {
    path: ['/users', '/subscribable-distribution-lists'],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  userExtensions: {
    path: ['/users', '/extensions'],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  userLocations: {
    path: ['/users', '/locations'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  userMessageTemplates: {
    path: ['/users', '/message-templates'],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  userNotificationActivities: {
    path: ['/users', '/notifications', '/activities'],
    verbs: ['GET', 'POST'],
    isIcop: false,
  },
  userNotificationAudio: {
    path: ['/users', '/notifications', '/audio'],
    verbs: ['GET'],
    isIcop: false,
  },
  userNotificationImage: {
    path: ['/users', '/notifications', '/image'],
    verbs: ['GET'],
    isIcop: false,
  },
  userNotifications: {
    path: ['/users', '/notifications'],
    verbs: ['GET', 'DELETE'],
    isIcop: false,
  },
  userSentNotifications: {
    path: ['/users', '/sent-notifications'],
    verbs: ['GET'],
    isIcop: false,
  },
  userSentNotificationAudio: {
    path: ['/users', '/sent-notifications', '/audio'],
    verbs: ['GET'],
    isIcop: false,
  },
  userSentNotificationImage: {
    path: ['/users', '/sent-notifications', '/image'],
    verbs: ['GET'],
    isIcop: false,
  },
  userAllPermissions: {
    path: ['/users', '/all-permissions'],
    verbs: ['GET'],
    isIcop: false,
  },
  usersReport: {
    path: [`/reports/${USERS_REPORT_ID}`],
    verbs: ['GET'],
    isIcop: false,
  },
  users: {
    path: ['/users'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  userSecurityGroups: {
    path: ['/users', '/security-groups'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  userScimSecurityGroups: {
    path: ['/users', '/scim-security-groups'],
    verbs: ['GET'],
    isIcop: false,
  },
  userSubscriptions: {
    path: ['/users', '/subscriptions'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  userScimSubscriptions: {
    path: ['/users', '/scim-subscriptions'],
    verbs: ['GET'],
    isIcop: false,
  },
  userProfile: {
    path: ['/users', '/profile'],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  userTokens: {
    path: ['/users', '/tokens'],
    verbs: ['GET', 'DELETE', 'POST'],
    isIcop: false,
  },
  userTrackingEventActivities: {
    path: ['/users', '/tracking-events', '/activities'],
    verbs: ['GET'],
    isIcop: false,
  },
  userTrackingEvents: {
    path: ['/users', '/tracking-events'],
    verbs: ['GET'],
    isIcop: false,
  },
  userUserPermissions: {
    path: ['/users', '/user-permissions'],
    verbs: ['GET', 'POST', 'PUT', 'DELETE'],
    isIcop: false,
  },
  bulkUpload: {
    path: ['/bulk-uploads'],
    verbs: ['GET', 'POST', 'DELETE'],
    isIcop: false,
  },
  bulkUploadLogs: {
    path: ['/bulk-uploads', '/logs'],
    verbs: ['GET'],
    isIcop: false,
  },
  bulkUploadCommit: {
    path: ['/bulk-uploads', '/commit'],
    verbs: ['POST'],
    isIcop: false,
  },

  rosteringAllList: {
    path: ['/incidents', '/rostering-members'],
    verbs: ['GET'],
    isIcop: false,
  },
  rosteringMyList: {
    path: ['/incidents', '/rostering-my-list'],
    verbs: ['GET'],
    isIcop: false,
  },
  rosteringReport: {
    path: ['/incidents', '/rostering-report'],
    verbs: ['GET'],
    isIcop: false,
  },
  visitorAwareMappings: {
    path: ['/incident-plans', '/visitor-aware-mapping-status'],
    verbs: ['GET'],
    isIcop: false,
  },
  ipCameras: {
    path: ['/ip-cameras'],
    verbs: ['GET', 'PUT'],
    isIcop: false,
  },
  ipCameraVendorConfigs: {
    path: [`/extensions/${IP_CAMERAS_SERVICE_EXTENSION_ID}/proxy/ip-cameras-vendor-config`],
    verbs: ['GET', 'PUT', 'POST', 'DELETE'],
    isIcop: false,
  },
  ipCameraFeedUrl: {
    path: [`/extensions/${IP_CAMERAS_SERVICE_EXTENSION_ID}/proxy/ip-cameras`, '/feed'],
    verbs: ['GET'],
  },
  ipCameraJobs: {
    path: [`/extensions/${IP_CAMERAS_SERVICE_EXTENSION_ID}/proxy/ip-camera-jobs`],
    verbs: ['GET', 'POST'],
    isIcop: false,
  },
} as const;

typeAssert<Record<string, ApiResource>>(API_RESOURCES);

/**
 * Builds the path prefix to access the ICOP resource for the selected ICOP server
 * @param {string} selectedIcopServerId the selected icop server
 * @returns {string} the path prefix
 */
export function buildIcopAccessPrefix(selectedIcopServerId: string): string {
  return `/extensions/${ICOP_EXTENSION_ID}/endpoints/${selectedIcopServerId}/proxy/proxy`;
}

export function assocIcopParams(options: AxiosRequestConfig): AxiosRequestConfig {
  return {
    ...options,
    params: {
      ...(options.params || {}),
      sync: 'true',
    },
  };
}

/**
 * Given an Api resource and some path params, build the api path to use
 * @param {ApiResource} resource the api resource
 * @param {ApiRequestParams} pathParams the path params to interpolate
 * @param {string} selectedIcopServerId the current selected icop server id
 * @returns {string} the constructed api path
 */
export function buildApiPathFromResource(
  resource: ApiResource,
  pathParams: ApiRequestParams,
  selectedIcopServerId: string | null
): string {
  const builtPath = flow(
    (path: string[]) =>
      zip(
        path,
        (pathParams || []).map((param: string) => `/${param}`)
      ),
    flatten,
    compact,
    join('')
  )(Array.from(resource.path));
  if (resource.isIcop || resource.usesSelectedServer) {
    if (!selectedIcopServerId) {
      throw new ApiPathMissingIcopServerIdError();
    }
    return `${buildIcopAccessPrefix(selectedIcopServerId)}${builtPath}`;
  } else {
    return builtPath;
  }
}

/**
 * Returns the mobile api url
 * @returns {string} the mobile api url
 */
export function getApiUrl(): string {
  return window.apiRoot || '';
}

// Ensure that the ApiRequest is well-formed and valid, returns the full ApiResource.
export function getResourceAndValidateRequest(request: ApiRequest): ApiResource {
  const [resourceName, pathParams] = request;
  const resource = API_RESOURCES[resourceName] as ApiResource;

  // Validate the request
  if (!resource) {
    log.error('Failed to find resource for ApiRequest', request);
    throw new Error('Failed to find resource for ApiRequest');
  } else if (
    // Make sure that we have supplied the correct number of path params
    size(pathParams) !== size(resource.path) - 1 &&
    size(pathParams) !== size(resource.path)
  ) {
    log.error('Invalid number of path params to build API path.', request, resource);
    throw new Error('Invalid number of path params to build API path.');
  } else {
    return resource;
  }
}

/**
 * Given an ApiRequest, stringify the uri
 * @param request the request
 */
export function stringifyReq(request: ApiRequest): string {
  const resource = getResourceAndValidateRequest(request);
  const apiPath = buildApiPathFromResource(resource, request[1], null);
  const query = stringifyQueryParams((request[3] && request[3].params) || {});
  return `${getApiUrl()}${apiPath}${query ? `?${query}` : ''}`;
}

/**
 * Given a url, append a ts timestamp to make the url unique
 * @param url the url to append the timestamp to
 */
export const appendTimestampToUrl = (url: string | undefined) =>
  url && `${url}${url.includes('?') ? '&' : '?'}ts=${Date.now()}`;
