import get from 'lodash/get';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { ActionMeta, Props as SelectProps } from 'react-select';
import { Dispatch, bindActionCreators, compose } from 'redux';
import { change, getFormValues } from 'redux-form';
import { WrappedFieldProps } from 'redux-form/lib/Field';
import { GridItemSizing } from 'singlewire-components';
import { ApiRequest } from '../../../../common-types';
import { RootState } from '../../../../core/RootReducer';
import { growl } from '../../../../core/layout/LayoutActions';
import {
  SearchAndPaginationOptions,
  searchPagerChangeQuery,
  searchPagerCreate,
  searchPagerDestroy,
  searchPagerNextPage,
  searchPagerUpdated,
} from '../../../../core/search-pager/SearchPagerActions';
import { getPager } from '../../../../core/search-pager/SearchPagerReducer';
import { isFeatureEnabled } from '../../../../core/utils/session';
import { RemoteSelect, getPagerId } from './RemoteSelect';
import { RefObject } from 'react';

interface ExternalProps extends Partial<WrappedFieldProps>, Partial<SelectProps<any, boolean>> {
  label: string;
  helperText?: string[];
  // If you provide one of the preconfigured types, you don't need to specify apiRequest, placeholder, or labelFn
  paginationType?:
    | 'distributionLists'
    | 'messageTemplates'
    | 'users'
    | 'deviceGroups'
    | 'collaborationGroups'
    | 'areasOfInterest'
    | 'confirmationRequests'
    | 'incidentPlans'
    | 'policies';
  isDisabled?: boolean;
  // The key for the value in the object returned by the server (defaults to 'id')
  valueKey?: string;
  // Given an object returned by the server, return the option label string
  labelFn?: (item: any) => string;
  placeholder?: string;
  // The ApiRequest to paginate
  apiRequest?: ApiRequest;
  // Any additional pagination params such as query params
  requestOptions?: SearchAndPaginationOptions;
  // Perform an operation (such as post-request filtering) on response data
  responseDataFn?: (data: any[]) => any[];
  // The default on change typically emits an id or id array. We don't typically want to display uuids to users, so
  // instead we can "prime" the list with initial objects so it can display the real names
  initialItems?: any[];
  // The default on change typically emits an id or id array. Sometimes you want the real object chosen such as to
  // do additional validation
  secondaryModelChanged?: (resource: any | any[] | null, action?: ActionMeta<any>) => void;
  // The default on change typically emits an id or id array. Sometimes you want the real object chosen and to be
  // bounded into the form at this path
  secondaryModel?: string;
  // Some features have static options in selects that aren't returned by the api. These additional items can be used to
  // populate the dropdown with static options.
  additionalItems?: any[];
  // Used to show extra action buttons to end of the field
  actions?: React.ReactNode;
  theme: any;
  globalOnly?: boolean;
  formRef?: RefObject<HTMLElement>;
  disableRadixTooltip?: boolean;
}

export type RemoteSelectProps = ExternalProps &
  GridItemSizing &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const getSecondaryModelValue = (state: RootState, props: ExternalProps): any | null => {
  if (props.secondaryModel) {
    const formValues = getFormValues(props.meta!.form)(state);
    return get(formValues, props.secondaryModel);
  } else {
    return null;
  }
};

export const mapStateToProps = (
  state: RootState,
  ownProps: ExternalProps & RouteComponentProps
) => ({
  pager: getPager<any>(state, getPagerId(ownProps.input!.name)),
  secondaryModelValue: getSecondaryModelValue(state, ownProps),
  pathname: ownProps.location.pathname,
  facilitiesEnabled:
    isFeatureEnabled('facilitiesEnabled', state.session) &&
    !isFeatureEnabled('domains', state.session),
  actingFacilityId: state.facilities.actingFacilityId,
  userFacilities: state.facilities.facilities,
});

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      searchPagerCreate,
      searchPagerDestroy,
      searchPagerUpdated,
      searchPagerNextPage,
      searchPagerChangeQuery,
      changeSecondaryModel: change,
      growl,
    },
    dispatch
  );

export const RemoteSelectContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RemoteSelect);
