import { Query, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { firstValueFrom } from 'rxjs';
import { ApiRequest } from '../../../../common-types';
import store from '../../../../core/store';
import { req, BackgroundRef } from '../../../../core/utils/api';
import { MobileApiPageEnvelope } from '../../../../mobile-api-types';
import { FilterOption, reduceFilters } from './utils';
import { IgnoreSessionExtendTransform } from '../../../../core/utils/http-transformers';

export async function tableDataQuery<T>(
  [route, params, verb, options]: ApiRequest,
  pageOptions: { pageIndex: number; pageSize: number },
  search: string | undefined,
  filters: Record<string, Array<FilterOption> | FilterOption | undefined>,
  responseDecorator?: (
    response: AxiosResponse<MobileApiPageEnvelope<T>, any>
  ) => Promise<AxiosResponse<MobileApiPageEnvelope<T>, any>>
) {
  const reducedFilters = reduceFilters(filters);

  const baseQuery = options?.params?.q;

  const qValue = baseQuery ? { and: [baseQuery, search] } : !!search ? search : undefined;

  const response = await firstValueFrom(
    req<MobileApiPageEnvelope<T>>(
      [
        route,
        params,
        verb,
        {
          ...options,
          params: {
            ...options?.params,
            q: qValue,
            limit: pageOptions.pageSize,
            start: pageOptions.pageIndex * pageOptions.pageSize,
            ...reducedFilters,
          },
          transforms: [IgnoreSessionExtendTransform],
        },
      ],
      store,
      BackgroundRef
    )
  )
    .then(response => {
      if (responseDecorator && response) {
        return responseDecorator(response);
      } else {
        return response;
      }
    })
    .then(response => {
      return response?.data;
    });

  return {
    rows: response?.data,
    pageCount: Math.ceil(response!.total / pageOptions.pageSize),
    total: response?.total,
  };
}

export interface TablePagerQueryOptions<T> {
  enabled?: boolean;
  /**
   * If set to a number, the query will continuously refetch at this frequency in milliseconds.
   * If set to a function, the function will be executed with the latest data and query to compute a frequency
   * Defaults to `false`.
   */
  refetchInterval?:
    | number
    | false
    | ((
        query: Query<{ rows: T[]; pageCount: number; total: number }>
      ) => number | false | undefined);
}

export const useTableDataQuery = function <T>(
  pagerId: string,
  request: ApiRequest,
  pagination: {
    pageIndex: number;
    pageSize: number;
  },
  debouncedSearch: any,
  debouncedFilters: any,
  queryOptions?: TablePagerQueryOptions<T>,
  responseDecorator?: (
    response: AxiosResponse<MobileApiPageEnvelope<T>, any>
  ) => Promise<AxiosResponse<MobileApiPageEnvelope<T>, any>>
) {
  return useQuery({
    queryKey: [pagerId, request, pagination, debouncedSearch, debouncedFilters],
    queryFn: () =>
      tableDataQuery<T>(request, pagination, debouncedSearch, debouncedFilters, responseDecorator),
    enabled: queryOptions?.enabled,
    refetchInterval: queryOptions?.refetchInterval,
  });
};
